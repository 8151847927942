@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.review-order {
    position: relative;
    @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));

    &__title {
        padding: $spacing-header 0;
    }

    &__content {
        padding: 0 $spacing-page-gutter;

        .prompt {
            margin-bottom: $spacing-component-xs;
        }

        &__messages {
            background-color: $color-grey-bg;
            border-radius: $border-radius-normal;
            padding: $spacing-component;

            &:not(:last-child) {
                margin-bottom: $double-spacing-component;
            }

            &__title {
                color: $color-grey-7;
                @include svg-color($color-grey-7);
            }

            &__description {
                color: $color-grey-5;
                padding-top: $spacing-component-xs;
            }

            svg {
                margin-right: $spacing-component-xs;
                width: 16px;
                height: 16px;
            }

            .checkbox-element {
                margin-top: $spacing-component;

                &.invalid {
                    label {
                        color: $color-error;

                        &::before {
                            border-color: $color-error;
                        }
                    }
                }
            }
        }

        &__details {
            &__row {
                color: $color-grey-7;
                display: flex;
                justify-content: space-between;
                padding-bottom: $spacing-component-small;

                &:first-of-type {
                    padding-top: $spacing-component-small;
                }

                &__placeholder {
                    color: $color-grey-3;
                }
            }
            .payment-detail {
                padding-top: $spacing-component-small;
                padding-bottom: $spacing-component-small;
            }
            &.no-payment-method {
                padding-bottom: $spacing-component-small;
            }
        }

        .legal-text__terms {
            color: $color-grey-5;
        }
    }

    footer {
        padding: $spacing-page-gutter;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: $button-height + (2 * $spacing-page-gutter);
        background-color: $color-white;
        @include safe-area-padding-bottom(0px);
    }
}
