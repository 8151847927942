@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.offer-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: $color-primary;
    height: 100%;

    .link {
        background-color: $color-white;
        color: $color-primary;
        text-decoration: none;
        display: contents;
    }

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        padding: 0;
        @include hide-scroll-bar;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        flex: 1;
        width: 100%;
        position: relative;
    }

    .animated-child {
        flex: 1;
    }

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 100ms;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 100ms ease-out;
            }
        }
    }

    &__fallback-image {
        position: fixed;
        top: $simple-nav-header-height;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        width: 100%;
        object-fit: cover;
        max-width: 800px;
        max-height: calc(var(--device-height) * 0.45);
        height: calc(var(--device-height) * 0.45);
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }

    &__content-container {
        padding-top: calc(var(--device-height) * 0.45 - #{$simple-nav-header-height} - #{$border-radius-xxl});
        z-index: 0;
        flex: 1;
        width: 100%;
    }

    &__top-section {
        padding: $double-spacing-component $spacing-page-gutter;
        border-bottom: 1px solid $color-grey-0;
    }

    &__middle-section {
        padding: $double-spacing-component $spacing-page-gutter 0;
    }

    &__text-container {
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: 1;
        padding-bottom: calc(#{$button-height} + #{$spacing-component * 4} + #{$big-banner-height * 2});
        border-radius: $border-radius-xxl;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__subtitle {
        color: $color-grey-5;
    }

    &__section {
        margin-bottom: $double-spacing-component;

        &-text {
            color: $color-grey-5;

            &--multi-line {
                white-space: break-spaces;
            }
        }
    }

    &__bottom-section {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $spacing-component;
        height: min-content;
        box-shadow: 4px 4px 20px $color-shadow;
        background-color: $color-white;
        @include safe-area-padding-bottom($spacing-component);
    }

    .terms-and-conditions {
        display: inline-flex;
    }

    &__newsletter-container {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-component;

        .checkbox-element {
            display: flex;
            align-items: center;
        }
    }

    &__newsletter-text {
        display: flex;
        flex-direction: column;
    }

    &__tappable-text-container {
        display: flex;
        align-items: center;
    }

    &__no-claim-text {
        text-align: center;
        margin: $spacing-component 0;
    }
}
