@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.payment-methods {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;

    .scroll-element {
        background-color: $color-white;
    }

    .payment-method {
        border-bottom: 1px solid $color-grey-0;
        min-height: 57px;
        padding: 0 $spacing-page-gutter;
    }

    .payment-method__add-card.show + .payment-method {
        border-top: 1px solid $color-grey-0;
        min-height: 58px;
    }

    &__title {
        padding: $spacing-header $spacing-page-gutter;
    }

    &__subtitle {
        padding: 0 $spacing-page-gutter $spacing-component-small;

        .payment-method + & {
            padding-top: $spacing-header;
        }
    }

    &__submit {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        @include when-keyboard-open {
            position: relative;
        }

        &__container {
            border-radius: $border-radius-normal;
            overflow: hidden;

            &.with-custom-google-pay-button {
                position: relative;

                button {
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    transform: translateZ(1px);
                }
            }
        }
    }

    &__prompt {
        margin: 0 $spacing-page-gutter $spacing-component;
    }

    .divider {
        margin-top: 0;
    }
}
