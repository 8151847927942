@import "src/sharedComponents/common/variables";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.OpenTableOrderStateWizard-modal__Overlay {
            z-index: 1011; // 1 higher than service change modal
            transform: translateZ(1011px);
        }
    }
}
