@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.open-table-ordering-disabled-page {
    position: absolute;
    background-color: $color-white;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    @include safe-area-padding-bottom($bottom-nav-bar);

    &__content {
        display: flex;
        align-items: center;
        height: 100%;
        padding: $spacing-component;
    }
}