@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.promo-code {
    &__action {
        @include svg-color($color-primary);
        display: flex;
        align-items: center;
        min-height: 20px;
        position: relative;
        padding: $spacing-component-xs 0;
        padding-left: $spacing-page-gutter;
        color: $color-primary;

        > svg {
            width: 20px;
            height: 20px;
            margin-right: $spacing-component-small;
        }
    }

    &__input-wrapper {
        padding: 0 $spacing-page-gutter;
    }

    &__input {
        margin: $spacing-component 0 $spacing-component-small;

        .input-element__input-container {
            margin-right: $spacing-component-xs;
        }

        &.in-error {
            .promo-code__input__title {
                color: $color-error;
            }
        }

        &--loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            opacity: 0;
            transition: opacity 100ms ease-in-out;
            border-radius: 50%;
            background-color: $color-white;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
            padding: $spacing-component-xxs;

            > div {
                width: 42px;
                height: 42px;
            }

            &.visible {
                opacity: 1;
            }
        }

        &--mask {
            opacity: 1;
            transition: opacity 100ms ease-in-out;

            &.hidden {
                opacity: 0.2;
            }
        }
    }
}
