@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.stamp-card-location-theme {
    height: var(--device-height);
}

.stamp-card-claim-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: $color-white;
    height: 100%;

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        padding: 0;
        @include hide-scroll-bar;
    }

    .animated-child {
        flex: 1;
    }

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 100ms;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 100ms ease-out;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
    }

    &__middle-section {
        padding: $double-spacing-component $spacing-page-gutter 0;
        padding-bottom: calc(#{$button-height} + #{$spacing-component * 2} + #{$big-banner-height * 2});
        text-align: center;
        width: 100%;
    }

    &__image-header {
        text-align: center;
        margin-top: $spacing-component-xs;
        max-height: calc(var(--device-height) * 0.25);
        @include svg-color($color-grey-9);

        svg {
            max-width: 100%;
            max-height: 100px;
            min-width: 180px;
            min-height: 40px;
        }
    }

    &__text-section {
        padding: $spacing-header 0;
    }

    &__subtitle {
        color: $color-grey-5;
    }
}
