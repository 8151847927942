@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__ModalMessage {
            z-index: 200;
            transform: translateZ(200px);
            @include hide-scroll-bar;
            opacity: 1;
            display: flex;
            flex-direction: column-reverse;

            &.ModalMessage-progress {
                background-color: transparent;
            }
            &.ModalMessage-important {
                z-index: 1200;
                transform: translateZ(1200px);
            }
        }

        &.ModalMessage-info,
        &.ModalMessage-progress {
            &.ReactModal__Overlay {
                animation: none;
                @include animate-modal(fade-in, 150ms, ".modal-message__modal");
            }
        }

        &.ModalMessage-action {
            &.ReactModal__Overlay {
                animation: none;
                @include animate-modal(slide-up, 250ms, ".modal-message__inner");
            }
        }
    }
}

.modal-message {
    &__modal {
        &--expanding {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__inner {
        background-color: $color-white;
        height: var(--modal-height, auto);
        padding-top: 0;
        display: flex;
        flex-direction: column;

        .location-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .modal-message__modal--expanding & {
            height: 100%;
            @include safe-area-padding-top(0px);
            transition: height 250ms ease-in 100ms, padding-top 250ms ease-in 100ms;
        }
    }

    &__curved-borders {
        border-radius: $border-radius-large $border-radius-large 0 0;
    }

    &__info,
    &__progress {
        text-align: center;
        border-radius: 4px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__info {
        width: calc(100% - 60px);
        max-width: 312px;
        margin: 0 auto;

        .modal-message__content {
            border-bottom: 1px solid $color-grey-0;
            padding: 24px 16px;
        }

        .modal-message__text {
            &--left-align {
                text-align: left;
            }
        }

        button {
            --button-color: #{$color-black};
        }
    }

    &__action {
        text-align: left;
        width: 100%;
        @include safe-area-padding-bottom(28px);

        .modal-message__content {
            padding: 28px 20px 32px;
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            &.with-nav-header {
                padding-top: 0;
            }

            &.with-inline-close {
                padding-top: $spacing-component-xs;
            }

            &--regular {
                opacity: 1;

                .modal-message__modal--expanding & {
                    opacity: 0;
                    transition: opacity 100ms ease-in-out;
                }
            }

            &--expanded {
                flex: 1;
                overflow-y: auto;
                @include hide-scroll-bar;
                opacity: 0;
                animation: fade-in-expanded-content 100ms ease-in 100ms forwards;

                .modal-message__text {
                    margin-bottom: $spacing-header;
                }
            }

            &--center-align {
                text-align: center;
                .modal-message__title {
                    align-self: center;
                }
            }
        }

        .modal-message__text {
            color: $color-grey-8;
        }
    }

    &__progress {
        width: 180px;

        .animated-svg {
            height: 128px;
            width: 128px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 7px !important;
        }

        .modal-message__title {
            margin-bottom: 24px;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            justify-content: center;
        }

        .modal-message__content {
            position: relative;
            height: 0;
            padding-top: 100%;
        }
    }

    &__icon {
        margin-bottom: 20px;
    }

    &__close {
        position: relative;
        height: $simple-nav-header-height;
        display: flex;
        align-items: center;
        margin-left: -$spacing-page-gutter;
        margin-bottom: $spacing-component-small;

        &__wrapper {
            position: absolute;
            padding: $spacing-page-gutter;

            svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__title {
        word-break: break-word;
        margin-bottom: $spacing-component-xs;
    }

    &__text {
        display: flex;
        flex-direction: column;

        &.modal-message__text-string {
            word-break: break-word;
            display: block;
        }

        &-link {
            color: $color-primary;
            cursor: pointer;
        }
    }

    &__important-text {
        margin-top: 8px;
    }

    &__actions {
        padding: 0 $spacing-page-gutter;

        &__info {
            padding: 0;
        }
        &__info-action {
            height: $button-height;
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-of-type {
                border-top: 1px solid $color-grey-0;
            }
        }

        button:not(.mode-blank) + button {
            margin-top: $spacing-component;
        }

        &--with-secondary {
            padding-top: $spacing-component-xxs;
            padding-bottom: $spacing-component-xxs;
        }
    }

    &__expand {
        opacity: 1;

        .modal-message__modal--expanding & {
            opacity: 0;
            transition: opacity 100ms ease-out;
        }
    }
}

@keyframes fade-in-expanded-content {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
