@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal .AccountDetails-modal--overlay {
    min-height: 100%;
    z-index: 111;
    transform: translateZ(111px);

    &.ReactModal__Overlay {
        @include animate-modal(slide-from-right);
    }
    .AccountDetails {
        background-color: $color-white;
        height: 100%;
        overflow-y: scroll;
        @include hide-scroll-bar;

        .scroll-element {
            @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));
            @include when-keyboard-open {
                padding-bottom: 0 !important;
            }
        }
    }
}
