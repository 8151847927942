@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__InviteGroupTab {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            .invite-group-tab-modal {
                @include animate-modal(slide-up, 150ms, ".invite-group-tab");
            }
        }
    }
}

.invite-group-tab {
    display: flex;
    flex-direction: column;
    padding: $spacing-header $spacing-page-gutter $spacing-page-gutter;
    @include safe-area-padding-bottom($spacing-header);
    background-color: $color-white;
    color: $color-grey-8;
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;

    &__title {
        color: $color-black;
    }

    &__message {
        padding-top: $spacing-component-xs;
    }

    &__secondary-message {
        color: $color-grey-5;
    }

    &__code {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $double-spacing-component 0 $spacing-header;

        &__join {
            display: flex;
            align-items: center;
            margin-top: $spacing-component-small;
            margin-bottom: $spacing-component-xs;
            padding: $spacing-component-xs $spacing-component;
            background-color: $color-grey-bg;
            color: $color-grey-7;
            border: 1px solid $color-grey-1;
            border-radius: $border-radius-normal;
            letter-spacing: 0.2em;
            text-transform: uppercase;

            @include svg-color($color-grey-7);
            svg {
                margin-right: $spacing-component-xs;
            }
        }

        &__copy {
            color: $color-primary;
        }
    }

    &__with-qr {
        text-align: center;
        @include safe-area-padding-bottom($button-height * 2 + (3 * $spacing-page-gutter));

        .invite-group-tab__message {
            color: $color-grey-8;
        }
    }

    &__qr {
        margin: $spacing-component 0;
        
        svg {
            padding: $spacing-component-xs;
            border: 2px solid $color-grey-0;
            border-radius: $border-radius-normal;
        }
    }

    &__footer {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
    }
}
