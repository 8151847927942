.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    align-content: stretch;
    &--column {
        flex-direction: column;
        justify-content: flex-start;
    }

    .item {
        width: 100%;
        overflow-x: hidden;
    }
}

.flex-cols {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex-top {
    align-self: flex-start;
}

.overflow-hidden {
    overflow: hidden;
}

.flex-middle {
    align-self: center;
}

.flex-bottom {
    align-self: flex-end;
}

.row {
    &:not(:first-child) {
        margin-top: 25px;
    }
}

.col-2 {
    width: 48%;

    &:nth-of-type(n + 3) {
        margin-top: 20px;
    }
}

.col-3 {
    width: 31%;

    &:nth-of-type(n + 4) {
        margin-top: 20px;
    }
}

.width-80 {
    width: 80%;
    margin: auto;
}

.width-50 {
    width: 50%;
}

.wrapper {
    padding: 20px;

    &--side {
        padding-top: 0;
    }

    &--flex-col {
        display: flex;
        flex-direction: column;
    }
}

.wrapper-with-margin-top {
    padding: 20px;
    margin-top: 20px;
}

.margin-top-xs {
    margin-top: 20px;
}

.margin-bottom-medium {
    margin-bottom: 80px;
}

.margin-bottom-small {
    margin-bottom: 30px;
}

.margin-top-medium {
    margin-top: 80px;
}
