@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal {
    .ReactModal__Overlay {
        height: initial;
        &.marketing-modal--overlay {
            z-index: 110;
            transform: translateZ(110px);
            overflow: hidden;
            min-height: 0;
            .slide-in {
                min-height: 100%;
                display: flex;
                flex-direction: column;
            }

            &.from-history {
                @include animate-modal(slide-from-right);
            }
            .marketing {
                overflow: hidden;
                display: flex;
                @include safe-area-padding-top(0px);
                color: $color-grey-8;

                form {
                    & > *:not(:first-child) {
                        margin-top: $spacing-component;
                    }
                }

                &--header {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    @include safe-area-padding-top(0);

                    .simpleNavHeader__wrapper {
                        z-index: 2;
                    }
                }

                &--header-container {
                    height: 30vh;
                    color: $color-white;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    img {
                        max-width: 23vw;
                    }

                    .keyboard-open:not(.keyboard-closing) &,
                    .keyboard-opening & {
                        margin-bottom: 0;
                        padding-top: 0;
                    }
                }
                &__logo {
                    min-height: 47px;
                    margin-top: 40px;

                    svg,
                    path {
                        fill: white;
                        height: 64px;
                        min-width: 64px;
                    }
                }

                &--scroll {
                    @include safe-area-padding-top(30px);
                    &.show-header {
                        padding-top: 30vh;
                        padding-top: calc(30vh - constant(safe-area-inset-top)); //for iOS 11.1
                        padding-top: calc(30vh - env(safe-area-inset-top)); //for iOS 11.2
                    }
                    flex: 1 1;
                    overflow: hidden;
                    overflow-y: auto;
                    @include hide-scroll-bar;
                    z-index: 1;
                }

                &--content {
                    padding: 20px 20px;
                    flex: 1 1;
                    background: $color-white;
                    overflow-y: auto;
                    @include hide-scroll-bar;

                    &--logo {
                        padding: 4px 0 76px;

                        svg {
                            width: 75px;
                        }

                        @include svg-color($color-primary);
                    }

                    &--title {
                        padding-right: 20px;
                        margin-bottom: 10px;
                        color: $color-grey-8;
                    }

                    &--subtitle {
                        padding-right: 20px;
                        margin-bottom: 20px;
                        display: block;
                        color: $color-grey-6;
                    }
                }

                &--footer {
                    padding: 20px 20px 0;
                    margin-bottom: 20px;
                    @include safe-area-padding-bottom(0px);

                    &--privacy {
                        display: block;
                        opacity: 0.6;
                        margin-top: 12px;

                        &--link {
                            display: inline;
                            color: $color-business;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
