@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

#toast-hosted-checkout {
    padding: 0 $spacing-component-small;
    opacity: 0;
    transition: opacity 200ms ease-in-out 100ms;
    height: 0;
    overflow: hidden;

    &.show {
        opacity: 1;
        height: auto;
        overflow: auto;
    }
}
