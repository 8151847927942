@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.tab-setting-card {
    border-bottom: 1px solid $color-grey-0;
    
    &__body {
        display: flex;
        @include svg-color($color-black);
        gap: $spacing-component-small;
        padding: $spacing-component $spacing-page-gutter;
        > svg {
            min-width: 24px;
            width: 24px;
        }
    }
    &__details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        &__chip {
            margin-top: $spacing-component-xxs;
        }
    }
    

    &__button {
        .mode-outline {
            margin-top: 0;
            &:not(.disabled) {
                --button-color: #{$color-grey-2};
                color: $color-grey-9;
            }
        } 
    }
}
