@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";
@import "src/sharedComponents/styles/components/react-modal";

.action-sheet__container {
  text-align: left;
  width: 100%;
  @include safe-area-padding-bottom($spacing-component-small);

  .action-sheet__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .action-sheet__content--title {
      flex: 0;
      padding: $spacing-component-small $spacing-component 0;
      display: inline-flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    &-scrollable {
      overflow-y: auto;
      @include hide-scroll-bar;
      flex: 1;

      .action-sheet__content--message {
        padding: $spacing-component-small $spacing-component;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        &-center {
          text-align: center;
        }
      }

      .action-sheet__content--children {
      }

    }

    &-default {

      .action-sheet__content--icon {
        display: inline-flex;
        justify-content: center;
        padding: $spacing-component-small $spacing-component 0;
      }

      .action-sheet__content--message {
        padding: $spacing-component-small $spacing-component;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        &-center {
          text-align: center;
        }

      }
    }

    &-heading {

      .action-sheet__content--title {
        padding-bottom: $spacing-component-small;
        border-bottom: 1px solid $color-grey-2;
      }

      .action-sheet__content--image {
        img {
          width: 100%;
        }
      }

      .action-sheet__content--message {
        padding-top: $double-spacing-component;
      }
    }

  }

  .action-sheet__actions {
    padding: $spacing-component $spacing-component 0;
    flex: 0;
    display: flex;
    flex-direction: column;

    .button-element.secondary {
      margin-top: $spacing-component-small;
    }
  }
}


