@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.tab-orders-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: $color-grey-7;
    @include safe-area-padding-bottom($spacing-page-gutter * 2);
    overflow-y: scroll;
    @include hide-scroll-bar;

    &__title {
        color: $color-black;
        padding: $spacing-header $spacing-page-gutter;

        &--description {
            color: $color-grey-7;
        }
    }

    &__empty {
        margin-top: 3 * $double-spacing-component;

        .button-element.mode-outline {
            --button-color: #{$color-grey-2};
            color: $color-grey-9;
        }
    }

    main {
        margin-bottom: $spacing-page-gutter;
    }

    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-component);
    }

    .info-section__details.show {
        padding: 0;
    }

    .tab-order-history-details {
        &__section {
            margin-top: 0;
        }

        .order-items {
            padding: 0;
        }

        .order-history-details {
            padding: 0 $double-spacing-component;
        }

        &.tab-order-history-details__no-gutter {
            .order-items {
                padding: 0 $spacing-page-gutter;
            }
        }
    }
}
