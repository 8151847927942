@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-split-evenly {
    padding-bottom: $spacing-header;

    &__options {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-page-gutter;
        padding-bottom: $spacing-component-xxs;
        margin-top: $spacing-page-gutter;
    }

    &__option {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius-normal;
        border: 1px solid $color-grey-1;
        padding: $spacing-component;

        &:not(:first-of-type) {
            margin-left: $spacing-component-small;
        }
    }

    &__summary {
        padding: 0 $spacing-page-gutter;

        > * {
            padding-bottom: $spacing-component-small;
            margin-bottom: 0;
        }
    }
}
