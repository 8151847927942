@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.venue-details {
    padding: $spacing-component;
    border-radius: $border-radius-normal;
    background-color: $color-white;
    color: $color-grey-9;

    &__name-time {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-component-xxs;
    }

    &__name {
        margin-right: $spacing-component;
    }

    &__time {
        white-space: nowrap;
    }

    &__address {
        color: $color-grey-7;
    }

    &.lightBackground {
        background-color: $color-primary-light;
    }

    &__map-contact-container {
        display: flex;
        margin-top: $spacing-component-xs;
    }

    &__tappable {
        @include svg-color($color-primary);
        color: $color-primary;
        display: flex;
        align-items: center;
        margin-right: $double-spacing-component;
        text-decoration: none;
        svg {
            color: $color-primary;
            height: 16px;
            width: 16px;
            margin-right: $spacing-component-xxs;
        }

        &.dark-icons {
            color: $color-grey-9;
            @include svg-color($color-grey-9);
        }
    }
}