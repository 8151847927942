@import "src/sharedComponents/common/variables";

.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: check-out;
    height: 40px;
    width: 100%;

    &-container {
        height: $button-height;
        border-radius: $border-radius-normal;
        background: $color-black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}
