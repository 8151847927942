@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.error-boundary {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    @include safe-area-padding-top(20px);
    @include safe-area-padding-bottom(20px);
    position: fixed;
    right: 0;
    left: 0;

    &__content {
        padding-top: 15vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include svg-color($color-grey-5);
        svg {
            width: 64px;
            height: 64px;
            margin-bottom: $double-spacing-component;
        }
        .title-28 {
            color: $color-grey-5;
            margin-bottom: $spacing-component;
        }
        .g-14 {
            color: $color-grey-3;
            margin-bottom: $double-spacing-component;
        }
    }
}
