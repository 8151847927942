@import "../../common/mixins";
@import "../../common/variables";

.button-element {
    --button-color: var(--theme-primary-color);

    &.brand-color {
        --button-color: #{$color-business};
    }

    &.unthemed {
        --button-color: #{$color-grey-8};
    }

    &.counter-button-position {
        position: relative;
    }

    border: 0;
    height: $button-height;
    border-radius: $border-radius-normal;
    background: transparent;
    outline: transparent;
    font-weight: $font-weight-semibold;
    font-size: $font-category;
    line-height: $line-height-default;
    width: 100%;
    padding: 0 $spacing-page-gutter;
    -webkit-appearance: none;
    -moz-appearance: none;

    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: $button-height-small;

    svg {
        position: static;
        margin-left: 0;
        display: block;
    }

    &__left-icon {
        display: inline-block;
        margin-right: 10px;
    }

    &__right-icon {
        display: inline-block;
        margin-left: 10px;
    }

    color: var(--button-color);

    .text {
        align-items: center;
        position: relative;
        top: 1px;
    }

    &:not(.unthemed) {
        @include svg-color(var(--button-color));
    }

    &.secondary {
        margin-top: 16px;
    }

    &:active {
        opacity: 0.7;
    }

    transition-property: background-color, border-color;
    transition-duration: 150ms;
    transition-timing-function: ease;

    &.mode {
        &-solid {
            color: $color-white;
            background: var(--button-color);
            border: 1px solid var(--button-color);
            color: var(--theme-button-text-color);
            @include svg-color(var(--theme-button-text-color));

            &.brand-color {
                color: $color-white;
                @include svg-color($color-white);
            }

            &[disabled],
            &.disabled {
                background: $color-grey-1;
                border: 1px solid $color-grey-1;
                color: $color-grey-3;
                @include svg-color($color-grey-3);
            }
        }
        &-solidinverted {
            color: var(--button-color);
            background: $color-white;
            border: 1px solid $color-white;
        }
        &-outline {
            background: transparent;
            border: 1px solid var(--button-color);
            color: var(--button-color);
            &.unthemed {
                border-color: $color-grey-2;
            }
        }
        &-blank {
            &.brand-color {
                color: $color-text-inverse;
                @include svg-color($color-text-inverse);
            }
        }
    }

    &.preset {
        &-half-size {
            height: $button-height-small;
        }

        &-large {
            height: $button-height-large;
            min-height: $button-height-large;
        }
    }

    &[disabled],
    &.disabled {
        opacity: 1;
        cursor: auto !important;
        --button-color: #{$color-grey-1};
    }

    &.flex-row {
        flex-direction: row;
    }

    &.flex-column {
        flex-direction: column;

        .button-element__left-icon {
            margin-right: 0;
        }

        .button-element__right-icon {
            margin-left: 0;
        }
    }

    .counter {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
    }
}
