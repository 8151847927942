@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.item {
    overflow-x: inherit !important;
}

.survey {
    &__container {
        flex-direction: column;
        overflow-y: auto;

        &.flex {
            @include overlay;
        }
    }
    &__content {
        flex: 1;
        overflow: hidden;
    }
}

.survey-page {
    color: $color-grey-8;

    &__inputs-container {
        max-height: 0;
        transition: max-height 200ms ease;
        overflow: hidden;

        &.visible {
            max-height: unset;
        }

        &.expanded {
            max-height: var(--inputs-height);
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: $spacing-component-xs;
        min-height: var(--inputs-height);
        padding-top: 40px;
        color: $color-grey-8;

        & > :not(:first-child) {
            margin: $spacing-component-xs 0;
        }
    }

    &__inputs-anchor {
        margin-top: $spacing-page-gutter;
    }

    &__expand {
        padding: $spacing-component-small 0;
        margin-top: $spacing-component-xs;
        color: $color-grey-5;
        @include svg-color($color-grey-5);
    }
}
