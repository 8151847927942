@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.grecaptcha-badge {
    visibility: hidden;
    pointer-events: none;
}

.verify-page {
    padding: 0 $spacing-page-gutter;
    @include safe-area-padding-bottom($spacing-page-gutter);
    height: calc(100% - #{$simple-nav-header-height});
    height: calc(100% - (#{$simple-nav-header-height} + constant(safe-area-inset-top))); //for iOS 11.1
    height: calc(100% - (#{$simple-nav-header-height} + env(safe-area-inset-top))); //for iOS 11.2
    display: flex;
    flex-direction: column;

    &__title {
        padding-top: $spacing-header;
    }

    &__description {
        color: $color-grey-7;
        padding-bottom: $spacing-header;
        min-height: 20px;

        &__additional {
            padding: 0;
        }

        &__different-number-link {
            text-decoration: underline;
        }

        a {
            color: $color-grey-7;
        }
    }

    &__phone-number {
        letter-spacing: 0.05em;
    }

    &__content {
        margin: auto;
    }

    &__container {
        position: relative;
        width: 100%;

        .spinner {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            transform: translateY(-50%);
            right: -60px;
        }

        .code-input__char {
            margin: 0 $spacing-component-small 0 0;
        }
    }

    &__resend {
        text-decoration: underline;
        color: $color-grey-7;
        margin-top: $spacing-page-gutter;

        &[disabled] {
            opacity: 1;
            color: $color-grey-3;
        }

        @include when-keyboard-open {
            position: relative;
        }
    }

    &__submit {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        @include when-keyboard-open {
            position: relative;
            padding: $spacing-page-gutter 0 0;
        }
    }
}

@keyframes current-digit {
    0% {
        color: transparent;
    }
    1% {
        color: $color-grey-5;
    }
    50% {
        color: $color-grey-5;
    }
    52% {
        color: transparent;
    }
    100% {
        color: transparent;
    }
}
