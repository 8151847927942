@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.create-tab-page {
    position: relative;
    @include safe-area-padding-bottom($spacing-page-gutter);
    height: calc(100% - #{$simple-nav-header-height});
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        padding: $spacing-header $spacing-page-gutter $double-spacing-component;

        &__subheading {
            color: $color-grey-8;
        }
    }
    
    &__payment-title {
        padding: $spacing-header $spacing-page-gutter 0;
    }

    &__disclaimer {
        padding: 0 $spacing-page-gutter;
    }
}