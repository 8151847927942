@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.modifier-list-item-details {
    flex-wrap: wrap;

    &__unavailable {
        display: flex;
        align-items: center;
        margin-top: $spacing-component-xxxs;
        color: $color-grey-4;

        @include svg-color($color-grey-4);

        svg {
            width: 16px;
            height: 16px;
            margin-right: $spacing-component-xxs;
        }
    }

    &__name {
        color: $color-grey-9;

        &.disabled {
            color: $color-grey-4;
        }
    }

    &__value {
        color: $color-grey-5;
    }
}
