#credential_picker_iframe {
    transform: translateZ(9999px);
}

.sso-button {
    position: relative;
}

.sso-provider-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}
