@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.secured-payment-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .scroll-element {
        @include safe-area-padding-bottom($top-content-spacing + $button-height-medium);
        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }
    }

    .animated-child {
        flex: 1;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__payment-title,
    &__header,
    &__disclaimer {
        padding: $spacing-page-gutter;
    }

    &__disclaimer {
        color: $color-grey-5;
    }
}
