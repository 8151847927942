@import "../../common/mixins";
@import "../../common/variables";

.animated-svg {
    &.with-brand-color {
        svg {
            *[stroke]:not([stroke="none"]) {
                stroke: $color-business !important;
            }
        }

        &.foodSpinner,
        &.wineglass {
            svg:not(.multi-color) {
                g:not([mask="none"]) rect {
                    fill: $color-business !important;
                }
            }
        }
    }

    &.twoDotSpinner,
    &.twoDotSpinnerWhite {
        transform: scale(2);
    }

    svg {
        display: block;
    }
}
