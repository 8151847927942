@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.privacy-settings {
    .scroll-element {
        padding-left: $spacing-page-gutter;
        padding-right: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);

        .incorrect-document-height & {
            @include safe-area-padding-bottom(calc(var(--document-height-diff) + #{$spacing-page-gutter}));
        }

        .party-wrapper & {
            @include safe-area-padding-bottom($spacing-page-gutter + $bottom-nav-bar);

            .incorrect-document-height & {
                @include safe-area-padding-bottom(
                    calc(var(--document-height-diff) + #{$spacing-page-gutter + $bottom-nav-bar})
                );
            }
        }
    }

    &__text {
        display: block;
        margin-bottom: $spacing-header;

        a {
            color: $color-business;
            text-decoration: none;
        }
    }

    .switch-element {
        &__switch {
            input {
                &:checked + label {
                    background-color: $color-business;
                }
            }
        }
    }
}
