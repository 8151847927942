@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.foodcard {
    &-link {
        text-decoration: none;
        display: block;
    }

    &__attributes {
        .pricelist {
            align-items: center;
        }

        .dietaryinfo {
            margin-left: 8px;
        }

        .price__value--base {
            color: $color-grey-3;
            margin-left: 4px;
        }
    }

    &__actions {
        &__add {
            margin-top: 15px;
        }
    }

    &__unavailable {
        display: none;
    }

    &__descriptors {
        &__name {
            @include overflow-ellipsis-multiline(2);

            .hide-product-images & {
                display: flex;
                align-items: center;

                .menu-card__fav {
                    position: static;
                    margin-right: $spacing-component-xs;
                    flex-shrink: 0;
                }
            }

            &-wrapper {
                .hide-product-images & {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .wait-time-badge {
                        position: static;
                        margin-left: $spacing-component-xs;
                    }
                }
            }
        }

        &__description {
            @include overflow-ellipsis-multiline(2);
            color: $color-grey-5;

            .hide-product-images & {
                margin-top: $spacing-component-xs;
            }
        }

        &__tags {
            display: flex;
            align-items: center;
            margin-top: $spacing-component-xxs;

            .hide-product-images & {
                margin-top: $spacing-component-xs;
            }
        }
    }

    &__attributes {
        .hide-product-images & {
            margin-top: $spacing-component-small;

            .price__wrapper {
                display: flex;
            }
        }
    }

    &.unavailable {
        .foodcard__thumb {
            opacity: 0.5;
        }

        .foodcard__descriptors {
            opacity: 0.3;
        }

        .pricelist,
        .price__value,
        .tag,
        .dietaryinfo,
        .foodcard__attributes,
        .foodcard__descriptors__tags {
            display: none;
        }

        .foodcard__unavailable {
            color: $color-grey-5;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }

            @include svg-color($color-grey-4);

            .hide-product-images & {
                margin-top: $spacing-component-small;
            }
        }
    }

    &__add-item-button {
        position: relative;
        bottom: $button-height-small;
        height: $button-height-small;
        
        &.mode-solid {
            background-color: $color-business;
            border: $color-business;
        }

        svg {
            margin-right: $spacing-component-xs;
        }

        .hide-product-images & {
            position: static;
            margin-top: $spacing-component;
        }
    }

    &__item-counter {
        .hide-product-images & {
            position: relative;
            margin-right: $spacing-component-xs;
        }
    }
}
