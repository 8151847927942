@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.account-page {
    padding: $spacing-header $spacing-page-gutter;

    &__description {
        padding-bottom: $spacing-header;
        color: $color-grey-8;
    }

    &__link {
        color: $color-business;
    }

    a {
        height: 100%;
        width: 100%;
        @include flex-center;
    }
}
