@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal .ScheduleTakeaway-modal--overlay {
    @include use-100vh;

    &.ReactModal__Overlay {
        @include animate-modal(slide-from-right);
    }
    .ScheduleTakeaway {
        background-color: $color-white;
        height: 100%;

        &__page {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}
