@import "../../common/mixins";
@import "../../common/variables";

@mixin icon-style($circleColour, $iconColour, $borderColour) {
    svg {
        circle {
            fill: $circleColour;
            stroke: $borderColour;
        }

        path {
            stroke: $iconColour;
        }
    }
}

@mixin selector-size($element-size) {
    height: var($element-size);
    max-width: calc((var(#{$element-size}) * 3) - #{$spacing-component-xs});
    width: calc((var(#{$element-size}) * 3) - #{$spacing-component-xs});

    .quantity-selector {
        &__button {
            @include icon-style($color-grey-bg, var(--theme-primary-color), $color-grey-bg);

            &.disabled {
                @include icon-style(transparent, $color-grey-2, transparent);
            }

            svg {
                padding: $spacing-component-xxs;
                height: var($element-size);
                width: var($element-size);
            }

            &--add {
                top: 0;
                right: 0;
            }
        }

        &__value {
            width: calc(var(#{$element-size}) - #{$spacing-component-xs});
            line-height: var($element-size);
        }
    }

    &.show-selector {
        .quantity-selector__button {
            @include icon-style($color-grey-bg, var(--theme-primary-color), $color-grey-bg);

            &.disabled {
                @include icon-style(transparent, $color-grey-2, transparent);
            }
        }
    }
}

.quantity-selector {
    position: relative;
    height: 20px;
    max-width: 72px;
    width: 100%;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;

    --extra-large-element-size: 54px;
    --large-element-size: 40px;
    --element-size: 32px;

    &__overlay {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: inherit;
    }

    &__button {
        display: flex;
        opacity: 1;
        transition: opacity 100ms ease-in-out;

        @include icon-style(transparent, $color-grey-7, $color-grey-2);

        &.disabled {
            @include icon-style($color-grey-bg, $color-grey-3, $color-grey-0);

            svg:active {
                transform: none;
            }
        }

        .show-selector.with-theme &:not(.disabled) {
            @include icon-style(var(--theme-primary-color), $color-grey-bg, var(--theme-primary-color));
        }

        .show-selector &:not(.disabled) {
            @include icon-style($color-grey-bg, var(--theme-primary-color), $color-grey-bg);
        }

        svg {
            height: var(--element-size);
            width: var(--element-size);
            overflow: visible;
            box-sizing: border-box;
            padding: 6px;

            circle,
            path {
                transition-property: fill, stroke;
                transition-timing-function: ease-in-out;
                transition-duration: 150ms;
            }

            circle {
                stroke-width: 2;
            }

            &:active {
                transform: scale(1.1);
            }
        }

        &--add {
            position: absolute;
            display: flex;
            align-items: center;
            right: -6px;
            top: -6px;
            transition: inherit;

            .show-selector & {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__value {
        width: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $color-grey-8;
        user-select: none;

        &.disabled {
            color: $color-grey-2;
        }
    }

    &__picker {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: inherit;

        span {
            display: flex;
            transition: inherit;

            &:first-child {
                transform: translate3d(200%, 0, 0);
            }

            &:nth-child(2) {
                transform: translate3d(100%, 0, 0);
            }
        }

        .show-selector & {
            opacity: 1;

            span {
                &:first-child {
                    transform: translate3d(0, 0, 0);
                }

                &:nth-child(2) {
                    transform: translate3d(0, 0, 0);
                }

                &:last-child {
                    transform: translateZ(0);
                }
            }
        }
    }

    &.large {
        @include selector-size(--large-element-size);
    }

    &.extra-large {
        @include selector-size(--extra-large-element-size);
    }
}
