@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.stamp-earned {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-success;
    margin-bottom: $spacing-component;
    svg {
        circle {
            fill: $color-success;
        }
        width: 18px;
        height: 18px;
        margin-right: $spacing-component-xs;
    }
    .loading-text {
        width: 75%;
    }
}
