@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

div.ReactModalPortal {
    .offer-items-modal {
        @include use-100vh;
        &--overlay {
            &.ReactModal__Overlay {
                background-color: $color-white;
            }
        }

        &__page {
            @include use-100vh;
            display: flex;
            flex-direction: column;
        }

        &__scroll-element {
            flex: 1;
            overflow-y: auto;
            padding-bottom: $double-spacing-component;
            @include hide-scroll-bar;
        }

        &__top-section {
            padding: $spacing-header $spacing-page-gutter;
        }

        &__item {
            position: relative;
            padding: 0 $spacing-page-gutter
        }
        
        &__item-counter {
            position: absolute;
            top: $spacing-component-xxs;
            left: $spacing-component-xxs;
        }

        &__footer {
            padding: $spacing-page-gutter;
            box-shadow: 4px 4px 20px $color-shadow;
            background-color: $color-white;
            @include safe-area-padding-bottom($spacing-page-gutter);
        }
    }
}

