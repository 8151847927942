@import "src/sharedComponents/common/variables";

.featured {
    padding: $spacing-header $spacing-page-gutter $double-spacing-component;
    background-color: $color-grey-bg;

    .with-menu-background & {
        background-color: transparent;
    }

    &:not(:first-child) {
        margin-top: $spacing-component;
    }

    &__title {
        padding-bottom: $spacing-header;
    }
}
