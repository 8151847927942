@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.schedule-takeaway {
    padding: $spacing-page-gutter;
    @include safe-area-padding-bottom($spacing-page-gutter);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    @include hide-scroll-bar;
    &__service-container {
        margin-top: $spacing-header;
    }

    &__service-name {
        margin-bottom: $spacing-component-xs;
    }

    &__times-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: $spacing-component-xs;
        grid-row-gap: $spacing-component-xs;
    }

    &__time-slot {
        height: $button-height-medium;
        border-radius: $border-radius-medium;
        padding: 0 $spacing-component-xs;

        &.inactive {
            color: $color-grey-8;
            border: 1px solid $color-grey-1;
        }
        
    }

    &__more-less-button {
        color: $color-primary;
        margin-top: $spacing-component;
    }

    &__choose-time {
        flex: 1 0;
        padding: $spacing-page-gutter;
        background-color: $color-white;
    }

}