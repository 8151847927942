@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.account-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;

    .scroll-element {
        background-color: $color-white;
        padding-left: 20px;
        padding-right: 20px;
    }

    .account__title {
        padding: $spacing-header 0;

        &__subtitle {
            color: $color-grey-8;
        }
    }

    .form-control {
        margin-bottom: $spacing-component-xxs;
    }

    .phone-input {
        --code-content: 0;
    }

    &__submit {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        @include when-keyboard-open {
            position: relative;
        }

        &__wrapper {
            padding: $spacing-page-gutter;
            @include safe-area-padding-bottom($spacing-page-gutter);
            background-color: $color-white;
        }
    }

    &__privacy-policy {
        margin-top: $spacing-title;

        &__link {
            color: $color-primary;
            text-decoration: none;
        }
    }
}
