.font-medium {
    font-weight: 500;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-small {
    font-size: 14px;
}

.text-center {
    text-align: center;
}

.underline {
    text-decoration: underline;
}

.medium {
    font-weight: 500;
}
