@import "src/sharedComponents/common/variables";

.radio-list {
    padding-bottom: $spacing-page-gutter;

    &__items {
        padding-top: $spacing-component-xs;
        list-style-type: none;
        margin-block-end: 0;
        padding-inline-start: 0;

        &.in-error {
            input[type="checkbox"]:enabled {
                + .fake-radio {
                    &::before {
                        border: 1px solid $color-error;
                    }
                }
            }
        }
    }

    &__error-message {
        padding: 0 $spacing-page-gutter;
        min-height: $font-caption * $line-height-default;
        color: $color-error;
    }
}
