@mixin small-mob($override: "max-width: 370px") {
    @media only screen and ($override) {
        @content;
    }
}

@mixin clear {
    margin: 0;
    padding: 0;
}

@mixin fill {
    height: 100%;
    width: 100%;
}

@mixin cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@mixin hide-scroll-bar {
    -webkit-overflow-scrolling: touch !important;
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

@mixin safe-area-padding-top($height) {
    padding-top: $height;
    padding-top: calc(#{$height} + constant(safe-area-inset-top)); //for iOS 11.1
    padding-top: calc(#{$height} + env(safe-area-inset-top)); //for iOS 11.2
}

@mixin safe-area-padding-bottom($height) {
    padding-bottom: $height;
    padding-bottom: calc(#{$height} + constant(safe-area-inset-bottom)); //for iOS 11.1
    padding-bottom: calc(#{$height} + env(safe-area-inset-bottom)); //for iOS 11.2
}

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }
    @if $media == iPhone {
        @media only screen and (max-width: 376px) {
            @content;
        }
    }
    @if $media == iPhone-plus {
        @media only screen and (max-width: 415px) {
            @content;
        }
    }
    @if $media == iPad {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
}

@mixin flex-center($orientation: landscape) {
    display: flex;
    flex-direction: map-get($orientations, $orientation);
    justify-content: center;
    align-items: center;
}

@mixin flex-center-horizontal($orientation: landscape) {
    display: flex;
    flex-direction: map-get($orientations, $orientation);
    @if $orientation == landscape {
        justify-content: center;
    } @else {
        align-items: center;
    }
}

@mixin flex-center-vertical($orientation: landscape) {
    display: flex;
    flex-direction: map-get($orientations, $orientation);
    @if $orientation == landscape {
        align-items: center;
    } @else {
        justify-content: center;
    }
}

@mixin overlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-height: none;
}

@mixin wrapper {
    padding-top: 20px;
}

@mixin overflow-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin small-link {
    color: #707070;
    text-decoration: underline;
    font-size: 13px;
}

@mixin overflow-ellipsis-multiline($lines-to-show) {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin gradient() {
    background: linear-gradient(
        135deg,
        rgba(144, 158, 186, 1) 0%,
        rgba(166, 177, 201, 1) 48%,
        rgba(183, 193, 213, 1) 100%
    ) !important;
}

@mixin gradient-directional($start-color: black, $end-color: white, $deg: 45deg) {
    background-image: linear-gradient($deg, $start-color, $end-color);
    background-repeat: repeat-x;
}

@mixin form-link($color: #707070, $size: 1em) {
    font-weight: $font-weight-medium;
    color: $color;
    text-decoration: underline;
    font-size: $size;
}

@mixin global-padding($top: 20px, $right: 20px, $bottom: 20px, $left: 20px) {
    padding: $top $right $bottom $left;
}

@mixin has-nav($nav-height: 56px) {
    padding-top: $nav-height;
}
