@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.history-item-details {
    color: $color-grey-7;

    &__value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: $spacing-component-small;

        &.outstanding {
            color: $color-error;
        }

        &--member-discount,
        &--offer-discount {
            color: $color-primary;

            @include svg-color($color-primary);

            span {
                display: inline-flex;
                align-items: center;
            }

            svg {
                display: block;
                margin-left: $spacing-component-xxs;
            }
        }

        &--offer-discount {
            svg {
                height: 16px;
                width: 16px;
            }
        }

        &--refunded {
            color: $color-success;
        }

        &--promotion {
            color: $color-primary;
            @include svg-color($color-primary);

            &--name {
                display: flex;
                align-items: center;
            }

            svg {
                margin-left: $spacing-component-xxs;
                height: 15px;
                width: 15px;
            }
        }
    }

    &__payment-method {
        display: flex;
        align-items: center;
        padding: $spacing-component-small 0 $double-spacing-component;
        color: $color-grey-8;

        svg {
            margin-right: $spacing-component;
        }
    }

    &__group-tab {
        display: flex;
        align-items: center;

        @include svg-color($color-grey-6);
        svg {
            height: 100%;
            width: 24px;
            margin-right: $spacing-component-xs;
        }
    }

    .taxes-detail__data {
        margin-left: -$spacing-component;
        margin-right: -$spacing-component;
        padding: 0 $spacing-component;
    }
}
