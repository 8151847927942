@import "../../common/mixins";
@import "../../common/variables";

$input-size-md: 54px;
$input-size-lg: 66px;

.input-control {
    display: flex;

    .label--floating {
        top: 7px;
    }

    &--invalid {
        .input-control__input {
            border: 1px solid $color-error;

            &__icons {
                @include svg-color($color-error);
            }

            &:focus-within::before {
                border: 1px solid $color-error;
            }

            &:hover {
                border-color: $color-error;
            }

            label {
                color: $color-error;
            }
        }
    }

    &--disabled {
        .input-control__input {
            border: 1px solid $color-grey-1;

            &__icons {
                @include svg-color($color-grey-1);
            }

            &:hover {
                border-color: $color-grey-1;
            }

            label {
                color: $color-grey-3;
            }

            input {
                color: $color-grey-3;
                background: $color-white;
            }
        }
    }

    &--md {
        height: $input-size-md;

        .input-control__input__wrap__prefix {
            font-size: $font-category;
        }

        input {
            font-size: $font-category;
        }

        label {
            top: calc((#{$input-size-md} - ((#{$line-height-default} * 1em) + 2px)) / 2);
        }
    }

    &--lg {
        height: $input-size-lg;

        .input-control__input__wrap__prefix {
            font-size: $font-msg-title;
        }

        input {
            font-size: $font-msg-title;
        }

        label {
            top: calc((#{$input-size-lg} - ((#{$line-height-default} * 1em) + 2px)) / 2);
        }
    }

    &__input {
        display: flex;
        flex: 1;
        position: relative;
        padding: 0 $spacing-component-small;
        border: 1px solid $color-grey-2;
        border-radius: $border-radius-normal;
        cursor: text;

        &__wrap {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;

            &--label {
                padding: 20px 0 0 0;

                input::placeholder {
                    opacity: 0;
                    transition: all 0.1s ease-in-out;
                    color: $color-grey-5;
                }

                .input-control__input__wrap__prefix {
                    opacity: 0;
                }

                input:focus + .input-control__input__wrap__prefix,
                input:not(:placeholder-shown) + .input-control__input__wrap__prefix {
                    opacity: 1;
                }

                input:focus::placeholder {
                    opacity: 1;
                }
            }

            &__prefix {
                align-self: center;
                margin-right: $spacing-component-xxs;
                font-weight: $font-weight-medium;
            }

            input {
                width: 100%;
                flex: 1 1 0%;
                font-weight: $font-weight-medium;
                font-family: $font-family-main;
                color: $color-grey-9;
                border: 0;
                margin: 0;
                padding: 0;
            }
        }

        &::before {
            box-sizing: border-box;
            content: "";
            display: block;
            position: absolute;
            pointer-events: none;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border-radius: inherit;
        }

        &:focus-within::before {
            border: 1px solid $color-black;
        }

        &:hover {
            border-color: $color-black;
        }

        label {
            position: absolute;
            transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
            line-height: $line-height-default;
            font-size: $font-category;
            color: $color-grey-7;
            cursor: text;
        }

        &__icons {
            display: inherit;
            align-items: center;

            @include svg-color($color-grey-4);

            svg {
                width: 24px;
                height: 24px;
            }

            &__clear {
                display: inherit;
                margin-left: $spacing-component-xs;

                svg {
                    cursor: pointer;
                }
            }

            &__icon {
                display: inherit;
                margin-left: $spacing-component-xs;
            }
        }
    }

    &__apply-button {
        width: auto;
        height: inherit;
        margin-left: $spacing-component-xs;
    }
}
