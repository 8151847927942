@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-split-option {
    @include pay-only-height-100-with-header;
    display: flex;
    flex-direction: column;

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        @include hide-scroll-bar;
    }

    .refresh-button {
        &__wrapper {
            padding-bottom: $spacing-component-small;
            top: auto;
        }
    }

    &__footer {
        padding: $spacing-component-small $spacing-page-gutter;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $color-grey-0;
        @include safe-area-padding-bottom($spacing-component-small);

        .no-connectivity-banner + & {
            border: 0;
        }

        &__message {
            display: flex;
            align-items: center;
            height: $double-spacing-component;
            margin-bottom: $spacing-component-xs;
            color: $color-grey-5;
        }
    }
}
