@import "../../common/mixins";
@import "../../common/variables";

.element-tag {
    background-color: $color-grey-0;
    color: $color-grey-5;
    display: inline-flex;
    align-items: center;
    margin: $spacing-component-xs $spacing-page-gutter;
    padding: $spacing-component-xxs $spacing-component-xs;
    border-radius: $spacing-component-xs;

    &:not(:first-of-type) {
        margin-left: $spacing-component-xxs;
    }

    &:not(:last-of-type) {
        margin-right: $spacing-component-xxs;
    }

    svg {
        width: 12px;
        height: 12px;
        margin-left: $spacing-component-xxs;
    }

    transition: opacity 150ms ease;

    &.hide {
        opacity: 0;
    }
}
