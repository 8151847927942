@import "src/sharedComponents/common/variables";

.tip-level-selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white;

    &__heart {
        padding: $spacing-header 0 $spacing-page-gutter;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            display: block;
            width: 38px;
            height: 34px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__tip-levels {
        display: flex;
        justify-content: center;
        margin: $spacing-header 0 $spacing-page-gutter;

        .button-element {
            border-radius: $border-radius-xxl;
            height: 53px;
            min-width: 53px;
            flex-shrink: 0;
            margin: 0 $spacing-component-xxs;

            &.percentage {
                width: 53px;
            }

            &:not(.percentage) {
                width: auto;
                padding: 0 $spacing-component;
            }

            .text {
                font-size: $font-caption;
            }

            &.mode-outline {
                border-color: $color-grey-1;

                .text {
                    color: $color-grey-4;
                }
            }
        }
    }

    &__custom {
        color: $color-primary;
        margin-bottom: $spacing-component;
    }

    &__tip-amount {
        margin-top: $spacing-header;
        margin-bottom: $double-spacing-component;
        color: $color-grey-6;
    }
}
