@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.selected-nested-modifiers {
    border: 1px solid $color-grey-1;
    border-radius: $border-radius-normal;
    padding: $spacing-component-small $spacing-component;
    margin: 0 $spacing-page-gutter $spacing-component-small;

    &:active,
    &:hover {
        background-color: $color-grey-bg;
    }

    &.required {
        .tried-to-submit & {
            border-color: $color-error;
        }
    }

    &__item {
        color: $color-grey-5;
        padding-bottom: $spacing-component-xxxs;
    }

    &__action {
        color: $color-primary;
        display: flex;
        align-items: center;

        &.with-selection {
            padding-top: $spacing-component-xxs;
        }

        @include svg-color($color-primary);
        svg {
            width: 20px;
            height: 20px;
        }

        .tried-to-submit .required & {
            color: $color-error;
            @include svg-color($color-error);
        }
    }
}
