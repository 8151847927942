@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";
@import "src/sharedComponents/styles/components/react-modal";

.ReactModalPortal {
    .ReactModal__Overlay {
      &.ReactModal__Sheet {
        z-index: 200;
        transform: translateZ(200px);
        @include hide-scroll-bar;
        opacity: 1;
        display: flex;
        flex-direction: column-reverse;

        --animation-duration-ms: 150ms;

        .sheet {
          @include animate-modal(slide-up, var(--animation-duration-ms));
        }
    }
  }
}

.sheet__container {
    background-color: $color-white;
    @include use-100vh(height);
    max-height: 100vh;
    padding-top: 0;
    display: flex;
    flex-direction: column;
}
