@import "src/sharedComponents/common/variables";

.member-initials {
    background-color: $color-grey-0;
    width: 39px;
    height: 39px;
    color: $color-grey-7;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circle;
    position: relative;

    margin-right: -$spacing-component-xxs;
    border: 1px solid $color-white;

    &__special {
        position: absolute;
        transform: translateY(-25%);
        right: 0;
        top: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}
