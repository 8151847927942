@import "../../common/mixins";
@import "../../common/variables";

$switch-container-width: 50px;
$switch-container-height: 30px;
$switch-size: 24px;

.switch-element {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__message {
        margin: $spacing-component $spacing-component $spacing-component 0;
    }

    &__title + .text {
        margin-top: $spacing-component-xs;
    }

    &__switch {
        position: relative;
        margin: $double-spacing-component 0;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: $switch-container-width;
            height: $switch-container-height;
            opacity: 0;
            margin: 0;
            padding: 0;

            &:checked + label {
                background-color: $color-primary;
                border-color: $color-primary;
                &::after {
                    background-color: $color-white;
                    left: $switch-container-width - $switch-size - (($switch-container-height - $switch-size) / 2);
                }
            }
        }

        label {
            width: $switch-container-width;
            height: $switch-container-height;
            border-radius: $switch-container-height / 2;
            background-color: $color-grey-bg;
            transition: background-color 100ms ease-in;
            border: 1px solid $color-grey-0;
            position: relative;
            display: block;

            &[disabled] {
                opacity: 0.4;
            }

            &::after {
                content: "";
                position: absolute;
                width: $switch-size;
                height: $switch-size;
                border-radius: 50%;
                background-color: $color-grey-1;
                left: ($switch-container-height - $switch-size) / 2;
                top: ($switch-container-height - $switch-size) / 2;
                transition: background-color 100ms ease-in, left 100ms ease-in;
            }
        }
    }
}
