@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-payments-feed {
    height: 100%;
    background: $color-grey-bg;
    @include safe-area-padding-bottom(75px);
    @include safe-area-padding-top(0px);

    &__no-payments {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include svg-color($color-grey-2);

        .text {
            color: $color-grey-5;
        }

        svg {
            height: 72px;
            width: 72px;
            display: block;
            margin-bottom: $double-spacing-component;

            path {
                stroke-width: 1;
            }
        }
    }

    &__payment {
        padding: $spacing-component;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: $color-white;

        + .pay-only-payments-feed__payment {
            border-top: 1px solid $color-grey-0;
        }

        &-details {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            white-space: nowrap;
            margin-left: $spacing-component;

            .price__value {
                color: $color-success;
            }

            &__tip {
                display: flex;
                align-items: center;
                color: $color-grey-5;
                margin-top: $spacing-component-xxs;
                @include svg-color($color-grey-5);

                .text {
                    white-space: pre;
                }

                .price__value {
                    color: $color-grey-5;
                }

                svg {
                    display: block;
                    height: 16px;
                    width: 16px;
                    margin-left: $spacing-component-xxs;
                }
            }

            &__refund-pill {
                display: flex;
                align-items: center;
                border-radius: $border-radius-small;
                background: $color-grey-bg;
                padding: $spacing-component-xxs $spacing-component-xs;
                margin-bottom: $spacing-component-xxs;

                .text {
                    line-height: 1;
                }

                svg {
                    display: block;
                    margin-right: $spacing-component-xxs;
                }
            }
        }

        &-items {
            display: grid;
            grid-template-columns: minmax(20px, auto) auto;

            .g-14 {
                margin-top: $spacing-component-xxs;
            }
        }

        &-no-items {
            margin-top: $spacing-component-xxs;
        }

        &-item {
            &-modifier {
                grid-column: 2;
            }
        }
    }

    &__footer {
        position: fixed;
        left: 0;
        right: 0;
        background-color: $color-white;
        min-height: 72px;
        border-top: 1px solid $color-grey-0;
        padding: $spacing-component 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include safe-area-padding-bottom(0px, bottom);
    }
}
