$device-map: (
    all: "",
    web: ".web",
    native: ".native",
    ios: ".ios",
    scrollRoot: ".scroll-root",
    ios-web: ".ios.web",
    ios-native: ".ios.native",
    android: ".android",
    android-web: ".android.web",
    android-native: ".android.native",
);

@mixin hide-scroll-bar {
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
    &::-webkit-scrollbar-track {
        -webkit-appearance: none;
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent !important;
    }
    &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
        background-color: transparent !important;
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
}

@mixin medium-size($override: "max-width: 900px") {
    @media only screen and ($override) {
        @content;
    }
}
@mixin small-size($override: "max-width: 670px") {
    @media only screen and ($override) {
        @content;
    }
}

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }
    @if $media == iPhone-small {
        @media only screen and (max-width: 365px) {
            @content;
        }
    }
    @if $media == iPhone {
        @media only screen and (max-width: 376px) {
            @content;
        }
    }
    @if $media == iPhone-plus {
        @media only screen and (max-width: 415px) {
            @content;
        }
    }
    @if $media == iPad {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
}

@mixin svg-color($color) {
    svg:not(.multi-color) {
        &:not([fill="none"]) {
            &:not([stroke]),
            &[stroke="none"] {
                fill: $color;
            }
        }

        *[fill]:not([fill="none"]):not(.ignore-svg-color) {
            fill: $color !important;
        }

        *[stroke]:not([stroke="none"]):not(.ignore-svg-color) {
            stroke: $color !important;
        }
    }
}

@mixin svg-circle-color($isInverse) {
    @if $isInverse {
        svg.multi-color {
            path {
                fill: var(--button-color);
            }
            circle {
                fill: var(--theme-button-text-color);
            }
        }
    } @else {
        svg.multi-color {
            path {
                fill: var(--theme-button-text-color);
            }
            circle {
                fill: var(--button-color);
            }
        }
    }
}

@mixin safe-area-padding-top($height, $property: padding-top) {
    #{$property}: $height;
    #{$property}: calc(#{$height} + constant(safe-area-inset-top)); //for iOS 11.1
    #{$property}: calc(#{$height} + env(safe-area-inset-top)); //for iOS 11.2
}

@mixin safe-area-padding-bottom($height, $property: padding-bottom) {
    #{$property}: $height;
    #{$property}: calc(#{$height} + constant(safe-area-inset-bottom)); //for iOS 11.1
    #{$property}: calc(#{$height} + env(safe-area-inset-bottom)); //for iOS 11.2
    .keyboard-open & {
        #{$property}: $height;
    }
}

@mixin overflow-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin overflow-ellipsis-multiline($lines-to-show) {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin overlay {
    @include use-100vh(min-height);
}

@mixin flex-center($orientation: landscape) {
    display: flex;
    flex-direction: map-get($orientations, $orientation);
    justify-content: center;
    align-items: center;
}

@mixin hide-when-keyboard-open($device: all, $extraClasses: "") {
    .keyboard-open#{map-get($device-map, $device)}#{$extraClasses} & {
        position: fixed;
        visibility: hidden;
    }
}

@mixin when-keyboard-open($device: all, $extraClasses: "") {
    .keyboard-open#{map-get($device-map, $device)}#{$extraClasses} & {
        @content;
    }
}

@mixin animate-modal($animation-name, $duration: 250ms, $child-class-name: "&", $timing-function: ease-in-out) {
    &--after-open {
        #{$child-class-name} {
            --animation-z: 200px;
            animation: $animation-name $duration $timing-function;
        }
    }
    &--before-close {
        #{$child-class-name} {
            --animation-z: 200px;
            animation: $animation-name + -reverse $duration $timing-function forwards;
        }
    }
}

@mixin use-100vh($property: height) {
    #{$property}: 100vh;
    :not(.native) & {
        #{$property}: -moz-available; /* WebKit-based browsers will ignore this. */
        #{$property}: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        #{$property}: stretch;
    }
}

@mixin fat-loader($scale: 2) {
    path[stroke-width="3"] {
        stroke-width: #{3 * $scale};
    }
    path[stroke-width="15"] {
        stroke-width: #{15 * $scale};
    }
}

@mixin pay-only-height-100-with-header {
    .pay-only-header__height + & {
        height: calc(100% - #{$simple-nav-header-height});
        height: calc(100% - (#{$simple-nav-header-height} + constant(safe-area-inset-top)));
        height: calc(100% - (#{$simple-nav-header-height} + env(safe-area-inset-top)));
    }
    .pay-only-header__height.with-banner + & {
        height: calc(100% - #{$simple-nav-header-height + $pay-only-banner-height});
        height: calc(100% - (#{$simple-nav-header-height + $pay-only-banner-height} + constant(safe-area-inset-top)));
        height: calc(100% - (#{$simple-nav-header-height + $pay-only-banner-height} + env(safe-area-inset-top)));
    }
}

@mixin floating-fixed-bottom-button {
    z-index: 1;
    background-color: $color-white;
    position: fixed;
    left: $spacing-page-gutter;
    right: $spacing-page-gutter;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
    transition-property: transform, bottom;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    border-radius: $border-radius-normal;
    transform: translateZ(1px);

    @include safe-area-padding-bottom($spacing-component-small, bottom);

    .party-wrapper & {
        @include safe-area-padding-bottom($bottom-nav-bar + $spacing-component-xs, bottom);
    }

    .with-ready-indicator .party-wrapper & {
        @include safe-area-padding-bottom($bottom-nav-bar + $spacing-component-xs + $spacing-header, bottom);
    }
}
