@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.menu-search-page {
    background-color: $color-grey-bg;
    color: $color-black;
    transform-origin: calc(100% - #{$spacing-component}) $spacing-page-gutter / 2;
    transform-origin: calc(100% - #{$spacing-component})
        calc(#{$spacing-page-gutter} / 2 + constant(safe-area-inset-top));
    transform-origin: calc(100% - #{$spacing-component}) calc(#{$spacing-page-gutter} / 2 + env(safe-area-inset-top));
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    box-shadow: 0px 0px 20px 20px #{$color-shadow};
    overflow: hidden;
    height: 100%;
    position: relative;

    @include hide-when-keyboard-open(all, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SecuredPaymentWizard-modal__Body--open");

    &--after-open {
        animation-name: show-search-reveal;
        animation-fill-mode: both;

        .with-view-cart & {
            .menu-search-page__view-cart {
                transition-delay: 250ms; // Show button when search modal animation is complete
                visibility: visible;
            }
        }

        @include when-keyboard-open(all, ".with-view-cart.PaymentWizard-modal__Body--open") {
            .menu-search-page__view-cart {
                transition-delay: initial;
                visibility: hidden;
            }
        }
    }
    &--before-close {
        animation-name: hide-search-reveal;
        animation-fill-mode: forwards;

        .with-view-cart & {
            .menu-search-page__view-cart {
                transition-delay: 0s;
                visibility: hidden; // Hide button when search modal is being closed
            }
        }
    }

    svg {
        min-width: initial;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-right: $spacing-page-gutter;
        min-height: $simple-nav-header-height;
        border-bottom: 1px solid $color-grey-0;
        background-color: $color-white;
        @include safe-area-padding-top($spacing-component-xs);
        @include safe-area-padding-top($simple-nav-header-height, max-height);

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $spacing-component-small;
            padding-right: 0;

            svg {
                width: 15px;
                height: 15px;
            }
            @include svg-color($color-grey-2);
        }

        .input-control {
            flex: 1;
            height: 40px;

            &__input {
                border: 0;
                padding-right: 0;

                &:focus-within::before {
                    border: 0;
                }
            }

            svg {
                width: 18px;
                height: 18px;
            }

            @include svg-color($color-grey-2);
        }
    }
    &__content {
        overflow-y: auto;
        max-height: calc(100% - #{$simple-nav-header-height});
        max-height: calc(100% - #{$simple-nav-header-height} - constant(safe-area-inset-bottom));
        max-height: calc(100% - #{$simple-nav-header-height} - env(safe-area-inset-bottom));

        @include safe-area-padding-bottom(0px);

        &__category {
            padding: $double-spacing-component $spacing-page-gutter $spacing-component-xs;
            background-color: $color-white;
        }

        &__line-item {
            padding: $spacing-component-small $spacing-page-gutter;
            display: flex;
            align-items: center;
            background-color: $color-white;
            color: $color-grey-7;

            &.search-item-unavailable {
                color: $color-grey-3;
            }

            &__name {
                white-space: pre;
                display: flex;
                flex-direction: column;
                width: 100%;
                overflow: hidden;

                &--inner {
                    display: block;
                    @include overflow-ellipsis;
                }
            }

            &__match {
                color: $color-grey-9;
                white-space: pre;
                .search-item-unavailable & {
                    color: $color-grey-3;
                }
            }

            &__image {
                width: 80px;
                min-width: 80px;
                margin-right: $spacing-component;

                .search-item-unavailable & {
                    opacity: 0.5;
                }

                img {
                    background-color: $color-grey-0;
                    min-height: 55px;
                    border-radius: $border-radius-small;
                }

                .drinkcard__thumb {
                    width: 45px;
                }
            }

            &__unavailable {
                display: flex;
                align-items: center;
                margin-top: $spacing-component-xxs / 2;
                svg {
                    margin-right: $spacing-component-xxs;
                    height: 13px;
                }
                @include svg-color($color-grey-3);
            }

            &__wait-time {
                display: flex;
                justify-content: flex-start;
                margin-top: $spacing-component-xxs;
                .wait-time-badge {
                    position: initial;
                }
            }
        }
        &__filters {
            background-color: $color-white;

            & ~ div {
                margin-top: -$spacing-component-small;
            }
        }

        .with-view-cart & {
            @include safe-area-padding-bottom(calc(#{$spacing-page-gutter} + #{$view-cart-button-height}));
        }
    }

    &__no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-grey-3;
        padding-top: $spacing-header + $spacing-component-small;

        &__title {
            color: $color-grey-5;
            padding-top: $double-spacing-component;
            padding-bottom: $spacing-component-xxs;
        }
    }

    &__view-cart {
        visibility: hidden;
        transition-property: visibility;
        transition-timing-function: ease-in-out;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $color-white;
        @include safe-area-padding-bottom(calc(#{$spacing-page-gutter} + #{$view-cart-button-height}), height);

        @include when-keyboard-open {
            top: calc(var(--device-height) - #{$view-cart-button-height} - #{$spacing-page-gutter});
            top: calc(
                var(--device-height) - #{$view-cart-button-height} - #{$spacing-page-gutter} -
                    constant(safe-area-inset-bottom)
            );
            top: calc(
                var(--device-height) - #{$view-cart-button-height} - #{$spacing-page-gutter} -
                    env(safe-area-inset-bottom)
            );
        }

        .view-cart-button {
            box-shadow: none;
            position: initial;
            margin: $spacing-page-gutter;
            margin-top: $spacing-component-small;

            &.no-network-connectivity {
                transform: none; // We do not show the network connectivity banner on search and therefore do not need to move the button
            }
        }
    }
}

.ReactModal__Overlay.ReactModal__MenuSearchModal {
    background-color: transparent;
    animation: none;
    transform: translateZ(10px);

    &.with-banner {
        transform: translate3d(0, $small-banner-height, 10px);
        transform: translate3d(0, calc(#{$small-banner-height} + constant(safe-area-inset-top)), 10px);
        transform: translate3d(0, calc(#{$small-banner-height} + env(safe-area-inset-top)), 10px);

        .menu-search-page {
            transform-origin: calc(100% - #{$spacing-component}) $spacing-page-gutter / 2;
        }

        .menu-search-page__header {
            padding: 0;
        }

        .menu-search-page__content {
            max-height: calc(100% - #{$simple-nav-header-height} - #{$small-banner-height});
            max-height: calc(
                100% - #{$simple-nav-header-height} - #{$small-banner-height} - constant(safe-area-inset-bottom)
            );
            max-height: calc(
                100% - #{$simple-nav-header-height} - #{$small-banner-height} - env(safe-area-inset-bottom)
            );
        }

        .menu-search-page__view-cart {
            @include safe-area-padding-top(
                calc(#{$spacing-page-gutter} + #{$view-cart-button-height} + #{$small-banner-height}),
                height
            );
            @include safe-area-padding-bottom(0px, bottom);

            @include when-keyboard-open {
                @include safe-area-padding-top(
                    calc(
                        var(--device-height) - #{$view-cart-button-height} - #{$spacing-page-gutter} - #{$small-banner-height}
                    ),
                    top
                );
            }
        }
    }
}

@keyframes show-search-reveal {
    0% {
        border-radius: $border-radius-circle;
        transform: scale(0.08, 0.04);
    }
    15% {
        transform: scale(0.28, 0.14);
    }
    25% {
        border-radius: 50px;
    }
}
@keyframes hide-search-reveal {
    75% {
        border-radius: 50px;
    }
    100% {
        border-radius: $border-radius-circle;
        transform: scale(0.08, 0.04);
        display: none;
    }
}
