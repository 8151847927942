@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-party {
    background-color: $color-white;

    #root {
        @include use-100vh(height);
    }

    &.keyboard-open.PayOnlyPaymentWizard-modal__Body--open {
        #root {
            height: auto !important;
        }
    }
}

.pay-only {
    display: flex;
    flex-direction: column;
    @include use-100vh(height);
    @include safe-area-padding-top(0px);
    align-items: center;

    &.refresh-failed {
        height: auto;
    }

    .keyboard-open.PayOnlyPaymentWizard-modal__Body--open & {
        height: auto;
    }

    .location-container {
        display: block;
        width: 100%;
    }

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
        height: calc(100% - var(--document-height-diff));
    }

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 100ms;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 100ms ease-out;
            }
        }
    }
    .connecting {
        text-align: center;
        padding: $spacing-page-gutter;
        @include use-100vh(height);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-white;

        &__container {
            .status-text {
                color: $color-black;
            }
            .animated-svg {
                position: relative;
                left: -10px;
                margin: -45px auto !important;
            }
        }

        &__footer {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @include svg-color($color-black);
            @include safe-area-padding-bottom(32px);
        }
    }

    &__failed {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-grey-5;
        text-align: center;
        padding: $spacing-page-gutter;
        padding-bottom: $spacing-page-gutter * 2;

        svg {
            width: 64px;
            height: 64px;
        }

        @include svg-color($color-grey-2);
    }
}
