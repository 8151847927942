@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.menu-page {
    height: 100%;
    width: 100%;
    padding: 0;
    flex-direction: column;
    justify-content: stretch;
    background: $color-white;

    display: flex;
    position: fixed;
    visibility: hidden;

    &.with-banner {
        .menu-page__items-header {
            @include safe-area-padding-top($simple-nav-header-height + $small-banner-height, top);
        }
        .menu-page-items-header-height {
            @include safe-area-padding-top($small-banner-height, margin-top);
        }
    }

    .menu-search-modal__Body & {
        overflow: hidden;
    }

    &.active-page {
        position: static;
        visibility: visible;
        @include hide-when-keyboard-open;
    }

    &__items-header {
        display: flex;
        overflow: hidden;
        @include hide-scroll-bar;
        overflow-x: scroll;
        padding-top: calc(
            #{$spacing-component-xs} - 1px
        ); // see Chip component clickable area padding for reason these are 1px off standard measurements
        padding-bottom: calc(#{$spacing-component-xxs} - 1px);
        position: fixed;
        @include safe-area-padding-top($simple-nav-header-height, top);
        right: 0;
        left: 0;
        z-index: 1;
        transform: translateZ(0);
        background: $color-white;

        &__height {
            min-height: $tabs-height;
        }

        > div {
            min-width: fit-content;
            padding: 0 $spacing-component-xxs;
            &:first-of-type {
                padding-left: $spacing-page-gutter;
            }
            &:last-of-type {
                padding-right: $spacing-page-gutter;
            }
        }
    }
    &__search {
        padding: 6px 0;
        padding-right: $spacing-page-gutter;

        &:not(.menu-search-button__show) {
            display: none;
        }
    }
    .animating-category-list {
        display: none;
    }
    &__items {
        flex: 1;

        &__title {
            padding: $spacing-component $spacing-page-gutter;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: $font-page-title * $line-height-default;

            &.with-filters {
                margin-right: 0;
                padding-right: 0;

                .filter {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
        &__filter-mesaage {
            border-radius: 8px;
            background: $color-grey-0;
            color: $color-grey-5;
            padding: $spacing-component-xs $spacing-component;
            margin: 0px $spacing-page-gutter $spacing-component;
        }
    }

    &__content {
        height: 100%;
    }

    .menuitemcard-link {
        padding-left: $spacing-page-gutter;
        padding-right: $spacing-page-gutter;
        height: 123px;

        &:last-child {
            margin-bottom: 0;
        }

        .hide-product-images & {
            height: auto;
        }
    }

    .fixed-notification:last-child {
        margin-bottom: 10px;
    }

    .empty-tab {
        margin-top: 40px;
    }
}

.menu-page,
.service-page {
    .foodcard,
    .drinkcard {
        border-top: 2px solid $color-grey-0;

        &:first-child {
            border-top: 0;
        }
    }

    .foodcard .pricelist,
    .drinkcard .pricelist {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        height: 25px;
        white-space: nowrap;

        &__item {
            overflow: hidden;
            flex: 0 0 auto;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 430px) {
                &:last-child {
                    flex: 0 1 auto;
                    padding-right: 0;
                }
            }

            .price {
                &__label {
                    color: $color-primary;
                    margin-right: 2px;
                }
            }
        }
    }

    .price__from {
        display: flex;
    }
}
