@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-payment-detail {
    padding: 0 $spacing-page-gutter;

    &__inner {
        padding: $spacing-component-small 0;
    }

    .payment-item + .payment-item {
        margin-top: $spacing-component-small;
    }

    .title-with-action {
        padding: $spacing-component 0 $spacing-component-small;

        &__action {
            text-decoration: underline;
        }
    }

    .payment-method__right {
        @include svg-color($color-grey-8);
    }

    .prompt {
        margin-top: $spacing-component;

        + .prompt {
            margin-top: $spacing-component-xs;
        }
    }
}
