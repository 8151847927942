/* Colours */ // Add the colors to the variable.stories
$color-grey-bg: #fafafa; /* Selected list item background / grey-25 */
$color-grey-0: #f0f0f0; /* Mono / grey-50 */
$color-grey-1: #e6e6e6; /* Mono / grey-100 */
$color-grey-2: #cccccc; /* Mono / grey-200 */
$color-grey-3: #b3b3b3; /* Mono / grey-300 */
$color-grey-4: #999999; /* Mono / grey-400 */
$color-grey-5: #808080; /* Mono / grey-500 */
$color-grey-6: #666666; /* Mono / grey-600 */
$color-grey-7: #4d4d4d; /* Mono / grey-700 */
$color-grey-8: #333333; /* Mono / grey-800 */
$color-grey-9: #1a1a1a; /* Mono / grey-900 */
$color-black: #000000; /* Brand / Black */
$color-white: #ffffff; /* Brand / White */
$color-chilli: #d94029; /* Error / Red */
$color-chilli-light: #fef4f2; /* Error / Light Red */
$color-warning: #ea5e0c; /* Warning / Orange */
$color-success: #247736; /* Success / Green */
$color-shadow: rgba(0, 0, 0, 0.1);
$color-overlay: rgba(0, 0, 0, 0.75);
$color-transparent: transparent;
$color-kale: #00362f;
$color-pine: #095d44; /* kale 800 */
$color-kale-hover: #0b9354;
$color-kale-light: #dff1e4;
$color-sloe: #5b46c3;
$color-neutral: #181819;

$color-business: $color-pine; /* me&u color - pine */
$color-business-hover: $color-kale-hover;
$color-business-light: $color-kale-light;
$color-secondary: $color-sloe; /* me&u secondary color - sloe */
$color-text: $color-black; /* text color - black */
$color-text-inverse: $color-white; /* text color inverse - white */
$color-primary: var(--theme-primary-color);
$color-primary-light: var(--theme-primary-color-light);
$color-primary-text: var(--theme-primary-text-color);
$color-error: $color-chilli;
$color-error-light: $color-chilli-light;

/* Font-sizes */
// default em size for browser is 16px
$font-initials: 0.563rem; //9px;
$font-descriptor: 0.688rem; //11px;
$font-caption: 0.813rem; //13px;
$font-category: 0.938rem; //15px;
$font-content: 1.063rem; //17px;
$font-modifier-title: 1.188rem; //19px;
$font-msg-title: 1.438rem; //23px;
$font-page-title: 1.688rem; //27px;
$font-product-title: 1.938rem; //31px;
$font-pwa-title: 2.188rem; //35px;
$font-home-title: 2.938rem; //47px;

/* Font-families */
$font-family-main: "Manrope", sans-serif;

@function add-opacity($color, $decimal) {
    @return transparentize($color, 1 - $decimal);
}

@function get-rem($font-size) {
    @return ($font-size / 16) * 1rem;
}

/* Line-heights */
$line-height-default: 1.35;

/* Font-weight */
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

/* Spacing */
$spacing-component-xxxs: 2px;
$spacing-component-xxs: 4px;
$spacing-component-xs: 8px;
$spacing-component-small: 12px;
$spacing-component: 16px;
$spacing-title: 18px;
$spacing-page-gutter: 20px;
$double-spacing-component: 24px;
$spacing-header: 32px;
$horizontal-content-spacing: 8.3vw;
$top-content-spacing: 100px;

/* Border radius */
$border-radius-xs: 2px;
$border-radius-small: 4px;
$border-radius-normal: 8px;
$border-radius-medium: 12px;
$border-radius-large: 16px;
$border-radius-xxl: 32px;
$border-radius-circle: 50%;

/* Shared component properties */
$simple-nav-header-height: 50px;
$bottom-nav-bar: 60px;
$button-height-large: 70px;
$button-height: 54px;
$button-height-medium: 44px;
$button-height-small: 40px;
$small-banner-height: 26px;
$big-banner-height: 36px;
$tabs-height: 54px;
$menu-page-footer-height: 131px;
$view-cart-button-height: 62px; // $button-height + $spacing-component-xs
$pay-only-banner-height: 42px;
$tooltop-arrow-width: 16px;
$large-icon: 64px;
$card-height: 157px;

/* Layouts */
$orientations: (
    landscape: row,
    portrait: column,
);
