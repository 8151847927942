@import "../../common/mixins";
@import "../../common/variables";

.pulse-loader {
    display: flex;

    i {
        background: $color-grey-2;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        opacity: 0.1;
        animation: pulse-loader-pulse 1s linear infinite;

        + i {
            margin-left: $spacing-component-xs;
        }
    }
}

@keyframes pulse-loader-pulse {
    0% {
        opacity: 0.1;
    }
    30% {
        opacity: 0.7;
    }
    60% {
        opacity: 0.1;
    }
}
