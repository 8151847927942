@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

$circle-icon-size: 32px;

.stamp-card {
    padding: $spacing-component;
    background-color: $color-primary;
    border-radius: $border-radius-normal;
    text-align: center;
    color: var(--theme-button-text-color);
    width: 100%;
    box-shadow: 0px 8px 36px 0px $color-shadow;
    min-height: $card-height;

    &__stamps {
        display: flex;
        row-gap: $spacing-component;
        flex-wrap: wrap;
        justify-content: center;
        margin: $spacing-component-small 0;
    }

    &__icon-container {
        position: relative;
        flex-basis: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-of-type {
            .empty-circle-icon {
                svg {
                    fill: var(--theme-button-text-color);
                }
                width: $circle-icon-size;
                height: $circle-icon-size;
            }
        }
    }

    .empty-circle-icon {
        @include svg-color(var(--theme-button-text-color));
        width: $circle-icon-size;
        height: $circle-icon-size;
    }

    .earned-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $circle-icon-size;
        height: $circle-icon-size;
        svg {
            circle {
                fill: var(--theme-button-text-color);
            }
            path {
                fill: $color-primary;
            }
        }
    }

    .reward-icon {
        @include svg-color($color-primary);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__learn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        @include svg-color(var(--theme-button-text-color));
        .text {
            margin-right: $spacing-component-small;
        }
    }

    &__claim-button {
        margin-top: $spacing-component-xs;
    }
}
