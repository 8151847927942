@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.barcode-scan-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include safe-area-padding-top(0px);
    background-color: rgba(0, 0, 0, 0.5);

    &__content {
        padding: $spacing-header $spacing-page-gutter 0;

        .text {
            color: $color-text-inverse;
        }
    }

    svg,
    &__focus-area {
        position: absolute;
        top: 50%;
        height: 240px;
    }

    svg {
        left: 50%;
        width: 240px;
        transform: translate(-50%, -50%);
    }

    &__focus-area {
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        background-color: white;
        opacity: 0.3;
    }
}
