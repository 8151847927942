@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.wait-time-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $big-banner-height;
    background-color: $color-grey-9;
    color: $color-white;

    svg {
        margin-right: $spacing-component-xs;
        width: 20px;
        height: 20px;
    }
    @include svg-color($color-white);
}
