@import "src/sharedComponents/common/variables";

.lobby-header {
    border-radius: $border-radius-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__members {
        margin: $spacing-component-small 0;
        display: flex;
        transform: scaleX(-1);

        .member-indicator {
            transform: scaleX(-1);
        }
    }
}