@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-receipt-ctas {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: $spacing-component;
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 1;
    background-color: $color-white;

    @include safe-area-padding-bottom($bottom-nav-bar + $spacing-component);

    &.hidden {
        opacity: 0;
    }
}
