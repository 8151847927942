@import "src/styles/mixins/mixins";

.license {
    h1 {
        overflow: hidden;
        overflow-wrap: break-word;
        font-size: 23px;
    }

    &.flex {
        align-items: flex-start;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        @include safe-area-padding-bottom(0px);
    }

    &--container {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
        width: 100%;

        a {
            color: black;
        }
    }

    &--header {
        width: 100%;
    }

    &--title {
        font-weight: 500;
        padding: 20px;
        padding-bottom: 0;
        text-transform: capitalize;
    }

    &--content {
        @include safe-area-padding-top(0px);
        font-size: 13px;
        padding: 10px 20px;
    }
}
