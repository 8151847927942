@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

$meandu-logo-height: 51px;

.sign-in-options-page {
    min-height: 100%;
    @include safe-area-padding-bottom(2 * $spacing-component-small + $meandu-logo-height);

    main {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        @include hide-scroll-bar;
    }

    .button-element {
        margin-bottom: $spacing-component-small;
    }

    .sso-button {
        margin-bottom: $spacing-component-small;

        .button-element {
            margin-bottom: 0;
        }
    }

    .divider {
        margin-bottom: $spacing-page-gutter;
    }

    .legal-text__terms {
        color: $color-grey-8;
        text-align: center;
        padding: 0;

        > span {
            text-decoration: underline;
            color: $color-grey-8;
            font-weight: $font-weight-semibold;
        }
    }

    .verify-page__submit {
        padding: $spacing-component-small;
        @include safe-area-padding-bottom($spacing-component-small);
        display: flex;
        justify-content: center;
        @include svg-color($color-black);
    }

    &__hidden-option {
        position: relative;
        height: $button-height;
        margin-bottom: $spacing-component-small;

        .button-element {
            margin-bottom: 0;
        }

        .fade {
            &-enter {
                opacity: 0;
                &-active {
                    opacity: 1;
                    transition: opacity 200ms ease-in 100ms;
                    position: absolute;
                    width: 100%;
                }
            }
            &-exit {
                opacity: 1;
                &-active {
                    opacity: 0;
                    transition: opacity 100ms ease-out;
                    position: absolute;
                    width: 100%;
                }
            }
        }
    }
}