@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

$state-wizard-loader-size: 50px;

.state-wizard-state {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon-wrapper {
        margin-bottom: $spacing-page-gutter;
        svg {
            display: block;
        }
    }

    .text {
        color: $color-white;
        text-align: center;
        padding: 0 $spacing-page-gutter;
    }

    .animated-svg {
        height: $state-wizard-loader-size;
        width: $state-wizard-loader-size;

        path[stroke-width="3"] {
            // large circle thickness
            stroke-width: 5;
        }

        path[stroke-width="15"] {
            // small circles thickness
            stroke-width: 20;
        }
    }

    &--progress {
        .text {
            margin-top: $spacing-page-gutter;
        }
    }

    &--success {
        svg {
            display: block;
            height: 56px;
            width: 56px;
            margin-bottom: $spacing-component-small;

            circle {
                fill: $color-white;
            }

            path {
                fill: $color-grey-9;
            }
        }
    }

    &--failed {
        .state-wizard-state__icon-wrapper {
            @include svg-color($color-error);
        }

        .text + .text{
            margin-top: $spacing-component-small;
        }

        .animated-svg {
            position: fixed;
            left: calc(50% - #{$state-wizard-loader-size / 2});
            @include safe-area-padding-bottom($button-height-small, bottom);
        }
    }
}

.StateWizard-modal {
    .wizard {
        background: $color-grey-9;

        &-transition {
            &-next {
                &-enter {
                    .animated-child {
                        background: $color-grey-9;
                    }
                }
            }
            &-prev {
                &-enter {
                    .animated-child {
                        background: $color-grey-9;
                    }
                }
            }
        }
    }
}
