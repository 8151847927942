@import "src/sharedComponents/common/variables";

.banner-notifications {
    &__container {
        position: fixed;
        visibility: hidden;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        height: $small-banner-height;
        pointer-events: none;

        &.active-page {
            visibility: visible;
            opacity: 1;
        }
    }
}
