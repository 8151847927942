@import "../../common/variables";
@import "../../common/mixins";

.thumbs {
    display: flex;
    flex-direction: column;

    &__title {
        padding-bottom: $spacing-component-xs;
        line-height: 35px;

        &.large {
            padding: $spacing-header 0;
        }
    }

    &__icons {
        display: flex;

        &__up,
        &__down {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid $color-grey-1;
            background: $color-white;
            position: relative;
            box-sizing: border-box;
            transition: transform 150ms ease-in-out;

            .selected & {
                transform: translateX(-34px); // half the width + half the space
            }

            &.active {
                @include svg-color($color-white);
                background: $color-business;
                border-color: $color-business;
                z-index: 1;

                .thumbs-location-theme & {
                    @include svg-color($color-text-inverse);
                    background: $color-primary;
                    border-color: $color-primary;
                }
            }

            &.large {
                height: 72px;
                width: 72px;

                svg {
                    width: 30px;
                    height: 30px;
                }
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 19px;
                height: 19px;
            }
        }

        &__down {
            margin-right: 20px;
            transform: rotate(180deg);

            .selected & {
                transform: rotate(180deg) translateX(-34px);
            }
        }
    }
}
