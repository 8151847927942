@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.payment-wizard {
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;
    display: flex;
    flex-direction: column;
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".ManageMemberships-modal__Body--open");

    .scroll-element {
        @include safe-area-padding-bottom($top-content-spacing + $button-height-medium);
        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }
    }

    &__signup {
        text-align: right;
        color: $color-grey-5;
        padding: $spacing-component;

        &__action {
            color: $color-primary;
        }
    }

    &-transition {
        &--push {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                transform: translateZ(10px);
                z-index: 1;

                .animated-child {
                    transform: translateX(100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
            &-exit-active {
                .sso-complete & {
                    visibility: hidden;
                }
            }
        }
        &--pop {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                transform: translateZ(10px);

                .animated-child {
                    transform: translateX(-100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
        }

        &-edit {
            &--push {
                &-enter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    z-index: 1;
                    transform: translate3d(0, 100vh, 10px);
                    background-color: $color-white;
                    &-active {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
            &--pop {
                &-exit {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    z-index: 1;
                    background-color: $color-white;

                    &-active {
                        transform: translate3d(0, 100vh, 10px);
                        transition: transform 250ms ease;
                    }
                }
            }
        }
    }
}

.payment-wizard-modal {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    .sso-complete & {
        background-color: transparent;
    }
}

.ReactModalPortal .ReactModal__PaymentWizard {
    overflow-x: hidden;
    &.ReactModal__Overlay {
        opacity: 1 !important;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        min-height: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include animate-modal(slide-from-right);

        &--after-open {
            transform: translateZ(10px);

            .sso-complete & {
                // temporarily position this above the SignInWizard while
                // we're animating after SignInWizard has completed
                z-index: 200;
                transform: translateZ(200px);
                background: transparent;
            }
        }

        .incorrect-document-height & {
            bottom: var(--document-height-diff);
        }
    }
}
