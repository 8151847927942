@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.menu-card {
    &__title {
        width: 100%;
    }

    background-color: $color-white;
    padding: 16px 20px 0 20px;

    .with-menu-background & {
        background-color: transparent;
    }

    &__list {
        padding-top: 8px;
        padding-bottom: 8px;

        display: grid;
        grid-gap: 7px;
        row-gap: 24px;
        grid-template-columns: repeat(auto-fill, minmax(157px, 0.5fr));

        .hide-product-images & {
            display: flex;
            flex-direction: column;
            row-gap: $spacing-component;
            padding-top: $spacing-component;
            padding-bottom: $spacing-component;
        }
    }

    &__item {
        margin: 0;
        list-style: none;
        padding: 0;
        a {
            text-decoration: none;
            -webkit-font-smoothing: auto;
            width: 100%;
            &:active .category-card {
                opacity: 0.7;
            }
        }
    }

    &__fav {
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: $color-primary;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 10px;
        }

        @include svg-color(var(--theme-button-text-color));
    }
}

@media screen and (max-width: 410px) {
    .menu-card__list {
        grid-template-columns: repeat(auto-fill, minmax(120px, 0.5fr));
    }
}
