@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;
    transform: translateZ(200px);
    background: $color-white;
    color: $color-grey-7;
    width: 100%;
    @include safe-area-padding-top(0px);

    .keyboard-open.PayOnlyPaymentWizard-modal__Body--open & {
        position: static;
    }

    .ReactModal__Body--open &,
    .PayOnlyStateWizard-modal__Body--open &,
    .SignInWizard-modal__Body--open:not(.sso-complete) & {
        z-index: 2;
        transform: translateZ(2px);
    }


    &__height {
        min-height: $simple-nav-header-height;

        &:not(.pay-only-receipt__header) {
            @include hide-when-keyboard-open;
        }

        &.with-banner {
            @include safe-area-padding-top($simple-nav-header-height + $pay-only-banner-height, min-height);
        }

        @include hide-when-keyboard-open(all, ".PayOnlyPaymentWizard-modal__Body--open.pay-only-party");
    }

    &__left-to-pay {
        min-height: $simple-nav-header-height;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        > div {
            display: flex;
            align-items: center;
            min-width: $simple-nav-header-height;
            height: 100%;
        }

        &__left {
            padding-left: $spacing-page-gutter;
            position: absolute;
            left: 0;
            top: 0;
            @include svg-color($color-grey-8);
        }

        &__center {
            width: 100%;
            justify-content: center;
            color: $color-grey-8;
        }

        &__right {
            position: absolute;
            right: 0;
            top: 0;
            padding-right: $spacing-page-gutter;
            @include svg-color($color-primary);
            color: $color-primary;

            svg {
                margin-right: $spacing-component-xxs;
            }

            &--alert {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    top: -2px;
                    right: $spacing-component-xxs;
                    background-color: $color-chilli;
                    border-radius: $border-radius-circle;
                }
            }
        }

        .price__wrapper {
            margin-right: $spacing-component-xxs;
        }

        .counter {
            position: absolute;
            top: 7px;
            right: 14px;
        }

        svg {
            display: block;
        }
    }

    &__paid {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: $pay-only-banner-height;
        background: $color-black;
        color: $color-white;

        .text {
            white-space: pre;
        }

        svg {
            circle {
                fill: $color-white;
            }
            path {
                fill: $color-black;
            }
            width: 16px;
            height: 16px;
            display: block;
            margin-right: 6px;
        }
    }
}
