@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-select-split-options {
    flex: 1;
    display: flex;

    .animated-child {
        flex: 1;
    }

    &__title {
        padding: $spacing-component-small $spacing-page-gutter;
    }

    &__subtitle {
        color: $color-grey-8;
    }
}
