@import "src/sharedComponents/common/variables";

#paypal-button {
    height: $button-height;
    border-radius: $border-radius-normal;
    overflow: hidden;
    background: #ffc439;
    width: 100%;
}

.paypal-checkout-sandbox {
    top: 0 !important;
    top: constant(safe-area-inset-top) !important; //for iOS 11.1
    top: env(safe-area-inset-top) !important; //for iOS 11.2
    height: 100vh !important;
    height: calc(100vh - constant(safe-area-inset-top)) !important; //for iOS 11.1
    height: calc(100vh - env(safe-area-inset-top)) !important; // for iOS 11.2
    min-height: 90% !important;
    transform: translateZ(100px);
}

.paypal-checkout-sandbox-iframe {
    border: 0;
}
