@import "../../common/mixins";
@import "../../common/variables";

.PhoneInput {
    &--disabled {
        .PhoneInputCountry {
            color: $color-grey-3;
        }
    }

    &Country {
        padding: $spacing-component-xs $spacing-component-small;
        border-radius: $border-radius-normal;
        border: 1px solid $color-grey-0;
        background: $color-grey-bg;

        &Select:focus + &Icon--border {
            box-shadow: none;
        }

        &Icon {
            height: 20px;
            width: 20px;
            margin-right: $spacing-component-xs;

            &--border {
                background-color: transparent;
                box-shadow: none;
            }

            &Img {
                border-radius: $border-radius-circle;
                object-fit: cover;
            }
        }
    }
}
