@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.ReactModalPortal div.menu-item-modal--overlay {
    // Override the fullscreen min-height of the overlay mixin so the add button appears
    // above the keyboard on Android and the user can scroll to the notes field
    min-height: 0;
    &.ReactModal__Overlay {
        position: static;
        opacity: 1;
        background: transparent;
        top: 0;
        justify-content: flex-start;
        pointer-events: all;
        height: initial;
        min-height: 100%;

        .menu-item-modal {
            @include safe-area-padding-bottom(0px);

            .menu-item__footer {
                transform: translate3d(0, 100vh, 10px);
            }
            .menu-item {
                transform: translate3d(0, 100vh, 0);
            }
        }
        &--after-open {
            .menu-page__shield {
                background-color: $color-white;
                transition: background-color 0 350ms ease-in-out;
            }
            .menu-item-modal {
                .menu-item__footer {
                    transform: translateZ(10px);
                    transition: transform 350ms ease-in-out;
                }
                .menu-item {
                    transform: initial;
                    transition: transform 350ms ease-in-out;
                }
            }
        }
        &--before-close {
            .menu-item-modal {
                .android & {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                }
                .menu-item {
                    @include use-100vh(max-height);
                }
                transform: translate3d(0, 100vh, 60px);
                transition: transform 350ms ease-out;
                transform-origin: center center 60px;
            }
        }
    }
}

.ReactModalPortal div.menu-item-modal--overlay.item-removed {
    &.ReactModal__Overlay {
        &--before-close {
            .menu-item-modal {
                .menu-item {
                    @include use-100vh(max-height);
                    overflow: hidden;
                }
                position: static;
                transform: translate3d(0, -100vh, 60px);
                transition: transform 350ms ease-in-out;
                transform-origin: top center 60px;
            }
        }
    }
}

.ReactModalPortal div.menu-item-modal--overlay.item-added {
    &.ReactModal__Overlay {
        &--before-close {
            .menu-item-modal {
                .menu-item {
                    @include use-100vh(max-height);
                }
                position: static;
                transform: scale(0.01) translate3d(0, 0, 0);
                opacity: 0;
                transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
                transform-origin: calc(50vw + 70px) calc(100% - 110px) 60px;
            }
        }
    }
}

.menu-item__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    @include when-keyboard-open {
        position: relative;
    }
    z-index: 2;
    transform: translateZ(10px);
    height: min-content;
    background-color: $color-white;

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
    }

    .tooltip.bottom {
        &.with-banner {
            bottom: calc(100% + #{$big-banner-height + $spacing-component-xs});
        }
        bottom: calc(100% + #{$spacing-component-xs});
    }
}

.menu-page {
    &.menu-item {
        position: static;
        visibility: visible;

        section {
            z-index: 1;
        }
    }

    &__header {
        @include safe-area-padding-top(0px);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        transform: translateZ(10px);
    }

    &__shield {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-transparent;
        transition: background-color 350ms ease;
    }

    .alsoordered,
    .recommended {
        border-top: solid 6px #ededed;

        .menuitemcard-link {
            border-bottom: 0;
        }

        .menuitemcard-image {
            height: 350px;
            width: 100%;
        }

        .menuitemcard-gallery {
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
            position: relative;

            > p {
                position: relative;
                padding-left: 28px;
                margin-bottom: 0;

                @media screen and (min-width: 600px) {
                    margin-bottom: 10px;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &__status-shadow {
        height: calc(0px + constant(safe-area-inset-top)); //for iOS 11.1
        height: calc(0px + env(safe-area-inset-top)); //for iOS 11.2
        background-image: linear-gradient(0, transparent, rgba(0, 0, 0, 0.5));
        width: 100%;
        top: 0;
        position: fixed;
        transform: translateZ(1px);
    }
}

.menu-page-food {
    .alsoordered {
        .menuitemcard-gallery {
            > p {
                &::before {
                    content: url(../../../assets/icons/menu-food-dinner.svg);
                }
            }
        }
    }

    .recommended {
        .menuitemcard-gallery {
            > p {
                &::before {
                    content: url(../../../assets/icons/menu-drink-corkscrew.svg);
                }
            }
        }
    }
}

.menu-page-drink {
    .alsoordered {
        .menuitemcard-gallery {
            > p {
                &::before {
                    content: url(../../../assets/icons/menu-drink-corkscrew.svg);
                }
            }
        }
    }

    .recommended {
        .menuitemcard-gallery {
            > p {
                &::before {
                    content: url(../../../assets/icons/menu-food-dinner.svg);
                }
            }
        }
    }
}
