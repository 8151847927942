@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__Spinner {
            z-index: 1000;
            transform: translateZ(1000px);
            background-color: transparent;
        }
    }
}

.spinner-modal {
    background-color: transparent;

    &.ReactModal__Content {
        height: 100%;
        transition-property: opacity;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.3);

        &--before-close {
            transform: translate(0, 0);
            opacity: 0;
        }
    }
}

.spinner {
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    height: 70px;
    width: 70px;
    opacity: 1;
    transition: opacity 200ms ease-out;

    &--hidden {
        opacity: 0;
    }
}
