@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.membership-signup-promotion {
    height: calc(100% - #{$simple-nav-header-height});
    display: flex;
    flex-direction: column;

    &-modal {
        &--overlay {
            &.ReactModal__Overlay--after-open {
                .ReactModalPortal & {
                    z-index: 110;
                    transform: translateZ(110px);
                }
            }
        }
    }

    &__header {
        background-color: $color-grey-bg;
        height: 188px;
        position: relative;
    }

    &__banner {
        display: block;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
    }

    &__logo {
        display: block;
        position: absolute;
        height: 124px;
        width: 124px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__content {
        padding: $double-spacing-component $spacing-component;
        overflow-y: scroll;
        @include safe-area-padding-bottom($button-height + $spacing-component + $double-spacing-component);
    }

    &__benefits {
        padding-top: $double-spacing-component;
    }

    &__benefit {
        display: flex;

        &__content {
            padding: $spacing-component-xxs 0;
        }

        + .membership-signup-promotion__benefit {
            padding-top: $spacing-component-small;
        }

        &__icon {
            display: block;
            height: 24px;
            width: 24px;
            margin-right: $spacing-component-small;
            padding: 1px;
        }
    }

    &__terms {
        padding-top: $double-spacing-component;
        display: flex;
        flex-direction: column;

        &__text {
            color: $color-grey-7;
        }

        a {
            color: inherit;
        }
    }

    &__footer {
        padding: $spacing-component;
        @include safe-area-padding-bottom($spacing-component);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;

        a {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }
    }
}
