@import "src/sharedComponents/common/variables";

.carousel-item {
    --carousel-item-md-width: 123px;
    --carousel-item-lg-width: 164px;
    --carousel-item-height: 123px;

    --carousel-selector-width: 120px;
    --carousel-selector-height: 48px;

    display: flex;
    flex-direction: column;
    width: var(--carousel-item-md-width);
    gap: 8px;

    &__control {
        position: relative;
        display: flex;

        .wait-time-badge {
            border-radius: $border-radius-medium;
        }

        &__selector {
            position: absolute;
            padding: 4px;
            top: 0;
            right: 0;
            transition: transform 0.3s ease-out;

            span {
                color: white;
            }
        }

        &__image {
            position: relative;
            display: flex;

            img {
                width: var(--carousel-item-md-width);
                height: var(--carousel-item-height);
                object-fit: cover;
                border-radius: 8px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                transition: background 0.3s ease-out;
                border-radius: 8px;
            }
        }
    }

    &__details {
        span:nth-child(n + 2) {
            color: $color-grey-7;
        }
    }

    &--lg {
        width: var(--carousel-item-lg-width);

        img {
            width: var(--carousel-item-lg-width);
        }
    }

    &--selected {
        .carousel-item__control__image::after {
            background: rgba(0, 0, 0, 0.5);
        }

        .carousel-item__control__selector {
            transform: translate(
                calc(-1 * (var(--carousel-item-md-width) - var(--carousel-selector-width)) / 2),
                calc((var(--carousel-item-height) - var(--carousel-selector-height)) / 2)
            );
        }

        &.carousel-item--selected.carousel-item--lg {
            .carousel-item__control__selector {
                transform: translate(
                    calc(-1 * (var(--carousel-item-lg-width) - var(--carousel-selector-width)) / 2),
                    calc((var(--carousel-item-height) - var(--carousel-selector-height)) / 2)
                );
            }
        }
    }
}
