.canvas-confetti {
    height: 100%;
    width: 100%;
    pointer-events: none;
    &--full {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
