@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.history-item {
    height: 100%;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include hide-when-keyboard-open(all, ".AccountDetails-modal__Body--open");
    @include hide-when-keyboard-open(all, ".Marketing-modal__Body--open");

    .wrapper-for-fixed-nav {
        flex: 1;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @include safe-area-padding-top($simple-nav-header-height);
    }

    &__section {
        box-shadow: 0 4px 12px rgba(79, 86, 96, 0.15);
        border-radius: $border-radius-normal;
        background: $color-white;
        margin-bottom: $spacing-component;
        color: $color-grey-8;
        z-index: 2;

        &--fade-in {
            animation: fade-in 200ms ease-in;
        }

        .group-tab-order__data-new & {
            border-radius: unset;
            box-shadow: unset;
            margin-bottom: 0;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex: 1 1;

        &__image {
            width: 100%;
            object-fit: cover;
            max-width: 800px;
            max-height: 350px;
            display: block;
        }
    }

    &__content-loader {
        width: 100px;
        padding-bottom: $double-spacing-component;
    }

    &__header-container {
        height: 40vh;
        color: $color-white;
        text-align: center;
        transform: translateZ(0);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &__header-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: opacity 100ms ease, transform 100ms ease;
        transform-origin: top;
        color: $color-white;
        text-align: center;
    }

    &__title {
        position: absolute;
        color: $color-white;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 100%;
        background: $color-white;
        opacity: 0;
        box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.1);
        transition: opacity 300ms ease;
        pointer-events: none;
        transform: translateZ(0);
    }

    &__title--show {
        opacity: 1;
    }

    &__header {
        margin-bottom: calc((40vh - 177px) / 3);
        margin-bottom: calc((40vh - 177px - constant(safe-area-inset-top)) / 3); //for iOS 11.1
        margin-bottom: calc((40vh - 177px - env(safe-area-inset-top)) / 3); //for iOS 11.2
    }

    &__logo {
        min-height: 47px;
        margin-bottom: 8px;

        svg,
        path {
            fill: $color-white;
            height: 64px;
            min-width: 64px;
            max-width: 100%;
        }
    }

    &__bill-sharing-info {
        margin-top: 4px;
        color: $color-grey-3;
        display: flex;
        align-items: center;

        @include svg-color(#a7a7a7);

        svg {
            height: 10px;
            margin-right: 3px;
        }
    }

    &__header-details {
        margin-bottom: calc((40vh - 177px) / 3);
        margin-bottom: calc((40vh - 177px - constant(safe-area-inset-top)) / 3); //for iOS 11.1
        margin-bottom: calc((40vh - 177px - env(safe-area-inset-top)) / 3); //for iOS 11.2
    }

    &__marketing-opt-in {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-component $spacing-page-gutter;
        color: $color-grey-9;

        &--icon {
            margin-left: $spacing-component-xs;
            @include svg-color($color-grey-9);

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__membership-signup-promotion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-component $spacing-page-gutter;
        color: $color-grey-9;

        .text {
            display: flex;
            align-items: center;
        }

        img {
            width: 32px;
            display: block;
            margin-right: $spacing-component-small;
        }
    }

    &__paid-info {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.outstanding {
            color: $color-error;
        }
    }

    &__list-value {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        align-items: center;
    }

    &__payment-method {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        max-width: 30vw;
    }

    &__payment-image {
        margin-right: 10px;
        width: 35px;
    }

    .advanced-scroll {
        padding: 20px;
        @include safe-area-padding-top($simple-nav-header-height);
        @include safe-area-padding-bottom(20px);
        border-radius: $border-radius-normal;
        transform: translateZ(0);
        @include hide-scroll-bar;
        width: 100%;
        min-height: 100%;
        height: 100%;
        position: relative;

        .scroll-area {
            border-radius: $border-radius-normal;
            min-height: 100%;
            .party-wrapper & {
                @include safe-area-padding-bottom($bottom-nav-bar);
            }
        }
    }

    &__order-status {
        padding: 20px;

        .ReactModal__Overlay & {
            @include safe-area-padding-bottom(20px);
        }

        background: $color-white;
        color: $color-primary;
        width: 100%;

        &__waiting {
            position: absolute;
            right: 20px;
            svg {
                height: 24px;
                width: 24px;
            }
        }

        &__header {
            margin-top: 12px;
        }

        &__description {
            color: $color-grey-6;
        }

        &__progress {
            &-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-radius: 4px;
                overflow: hidden;
                align-items: center;
                max-width: 250px;

                svg {
                    margin-right: 7px;
                    min-width: 24px;
                    max-width: 24px;
                    height: 24px;
                }
            }
            background: $color-grey-1;
            width: 100%;
            min-width: 10%;
            height: 3px;
            margin-right: 7px;
            transition: min-width 300ms ease-in-out;
            border-radius: 5px;
            border-radius: 5px;

            &.completed {
                background: $color-primary;
            }

            &:last-child {
                margin-right: 0;
            }

            &.progress-active {
                min-width: 50%;
                position: relative;
                overflow: hidden;

                .progress-bar {
                    background: $color-primary;
                    transform: translateX(0);
                    animation: progress-animation 2000ms ease-out infinite;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    .info-section__header__title {
        color: $color-black;
    }

    .request-receipt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        text-decoration: none;
        color: $color-grey-7;

        @include svg-color($color-grey-7);
    }

    &.history-item--update {
        .info-section__header__title {
            color: $color-grey-9;
        }

        .request-receipt {
            padding: $spacing-component $spacing-page-gutter;
            color: $color-grey-9;
        }
    }

    .having-issues {
        display: grid;
        grid-template: "icon title";
        grid-column-gap: $spacing-component-small;
        align-items: center;
        padding: $double-spacing-component;
        color: $color-grey-7;
    }

    &__orders {
        .info-section__header__description {
            margin: 0 -#{$spacing-page-gutter};
        }
        &.info-section--update {
            .info-section__header__description {
                margin: 0 -#{$spacing-page-gutter};
            }
        }
    }
}

@keyframes progress-animation {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }
    85% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
