@import "src/sharedComponents/common/variables";

.modifier__price {
    pointer-events: none;
    color: $color-grey-5;

    &--base {
        color: $color-grey-2;
        padding-left: $spacing-component-xxs;
    }
    &--wrapper {
        display: flex;
    }
}

.modifiers__header {
    align-items: center;
    padding: 12px 20px;
    display: flex;
    color: $color-grey-8;

    .l {
        flex: 1;
        .select-mode {
            display: block;
            color: $color-grey-4;

            &.error {
                color: $color-error;
            }
        }
    }

    &--disabled {
        .l {
            color: $color-grey-4;
        }
    }

    .r {
        color: $color-grey-4;

        &.error {
            color: $color-error;
        }
    }
}

.modifiers__notes {
    display: flex;
}

.modifiers__notes__input {
    flex: 1;
    min-height: 90px;
    padding: 10px;
}
