@import "src/sharedComponents/common/variables";

.card-flip {
    perspective: 600px;
    width: fit-content;

    &__content {
        position: relative;
        transform-style: preserve-3d;
        
        display: grid;
        grid-template-columns: 1fr;

        --flip-timing-function: cubic-bezier(.52,0,.57,.56);
        --flip-duration: 1000ms;
        animation-timing-function: var(--flip-timing-function);
        animation-duration: var(--flip-duration);
        animation-fill-mode: forwards;

        &.card-flipped {
            animation-name: cardFlip;
        }
        &.card-unflip {
            animation-name: cardUnFlip;
        }
    }

    .canvas-confetti {
        z-index: 1;
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        backface-visibility: hidden;
        
        grid-row-start: 1;
        grid-column-start: 1;
        pointer-events: none;
    }

    &__back {
        transform: rotateY(180deg);
    }
}

@keyframes cardFlip {
    0% {
      transform: rotateZ(0deg) rotateY(0deg);
    } 
    50% {
      transform: scale(1.4) rotateZ(-10deg) rotateY(90deg);
    }
    100% {
      transform: rotateZ(0deg) rotateY(180deg);
    }
    
  }
  
  @keyframes cardUnFlip {
    0% {
      transform: rotateZ(0deg) rotateY(180deg);
    } 
    50% {
      transform: scale(1.4) rotateZ(-10deg) rotateY(90deg);
    }
    100% {
      transform: rotateZ(0deg) rotateY(0deg);
    }
    
  }
  