@import "src/sharedComponents/common/variables";

.modifier-multi-select-list-item {
    position: relative;
    min-height: 60px;

    &__container {
        padding: $spacing-component-small $spacing-page-gutter;
        align-items: center;
        display: flex;

        .quantity-selector {
            flex: 1;
        }
    }

    &__details {
        flex: 2;
        margin-right: $spacing-component;
    }
}
