@keyframes jump {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(0, -6px, 0);
        transform: translate3d(0, -6px, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
    }
}

@keyframes endless-spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-in-reverse {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slide-up {
    from {
        transform: translate3d(0, 100vh, var(--animation-z));
    }
    to {
        transform: translate3d(0, 0, var(--animation-z));
    }
}
@keyframes slide-up-reverse {
    from {
        transform: translate3d(0, 0, var(--animation-z));
    }
    to {
        transform: translate3d(0, 100vh, var(--animation-z));
    }
}

@keyframes slide-down {
    from {
        transform: translate3d(0, -100vh, var(--animation-z));
    }
    to {
        transform: translate3d(0, 0, var(--animation-z));
    }
}
@keyframes slide-down-reverse {
    from {
        transform: translate3d(0, 0, var(--animation-z));
    }
    to {
        transform: translate3d(0, -100vh, var(--animation-z));
    }
}

@keyframes slide-from-right {
    from {
        transform: translate3d(100vw, 0, var(--animation-z));
    }
    to {
        transform: translate3d(0, 0, var(--animation-z));
    }
}
@keyframes slide-from-right-reverse {
    from {
        transform: translate3d(0, 0, var(--animation-z));
    }
    to {
        transform: translate3d(100vw, 0, var(--animation-z));
    }
}
@keyframes slide-from-left {
    from {
        transform: translate3d(-100vw, 0, var(--animation-z));
    }
    to {
        transform: translate3d(0, 0, var(--animation-z));
    }
}
@keyframes slide-from-left-reverse {
    from {
        transform: translate3d(0, 0, var(--animation-z));
    }
    to {
        transform: translate3d(-100vw, 0, var(--animation-z));
    }
}

@keyframes move-to-position {
    from {
        transform: translate3d(0, var(--animation-element-height), 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes move-to-position-reverse {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, var(--animation-element-height), 0);
    }
}

@keyframes slide-up-element {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fade-in-bck {
    0% {
        -webkit-transform: translateZ(80px);
        transform: translateZ(80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
