@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-bill-unavailable {
    padding: 0 $spacing-component;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include svg-color($color-grey-4);

    // It's easier to set the height of the refresh-failed component than change all of
    // the surrounding pay-only styling to enable the content to fill the gap, at least
    // so it works nicely for both local dev and on devices

    height: calc(var(--device-height) - #{$simple-nav-header-height + $bottom-nav-bar});
    height: calc(var(--device-height) - #{$simple-nav-header-height + $bottom-nav-bar} - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    height: calc(var(--device-height) - #{$simple-nav-header-height + $bottom-nav-bar} - env(safe-area-inset-top) - env(safe-area-inset-bottom));

    &.with-banner {
        height: calc(var(--device-height) - #{$simple-nav-header-height + $pay-only-banner-height + $bottom-nav-bar});
        height: calc(var(--device-height) - #{$simple-nav-header-height + $pay-only-banner-height + $bottom-nav-bar} - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
        height: calc(var(--device-height) - #{$simple-nav-header-height + $pay-only-banner-height + $bottom-nav-bar} - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }

    > * + * {
        margin-top: $spacing-page-gutter;
    }

    .text {
        text-align: center;
        padding: 0 $spacing-component;
    }

    svg {
        height: 56px;
        width: 56px;
    }
}