@import "src/sharedComponents/common/variables";

.radio-list-item {
    padding: 0 $spacing-page-gutter;
    border-bottom: 1px solid $color-grey-0;

    &__option {
        &__titles {
            padding: $spacing-title 0;
            position: relative;
            color: $color-grey-8;

            &__description {
                color: $color-grey-6;
                padding-top: $spacing-component-xxs;
            }
        }
    }
}
