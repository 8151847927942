@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.group-tab-order {
    color: $color-grey-8;
    padding: $spacing-component $spacing-page-gutter;
    display: flex;
    justify-content: space-between;

    &__wrapper {
        animation: fade-in 200ms ease;

        .info-section & {
            overflow: hidden;
        }
    }

    > {
        animation: fade-in 1000ms ease;
    }

    svg {
        margin-right: $spacing-component-xs;
        transition: transform 200ms ease;
    }

    &__data {
        overflow: hidden;
        max-height: 0;
        display: flex;
        flex-direction: column;
        background-color: $color-grey-bg;
        width: 100%;
        transition: max-height 200ms ease;

        .order-history-update & {
            background-color: unset;
        }

        &--loading {
            svg {
                margin-top: $spacing-component-xs;
                max-height: 40px;
                max-width: 40px;
            }
        }

        &__items {
            padding: $spacing-component $spacing-page-gutter;
            width: 100%;
            display: grid;
            grid-template-columns: fit-content(90%) minmax(0, 1fr) fit-content(90%);
            grid-template-areas: "left middle right";
            color: $color-grey-7;
            grid-column-gap: $spacing-component;
            grid-row-gap: $spacing-component-xs / 2;
            align-items: center;
            min-height: var(--order-height);
        }

        & ~ .divider {
            will-change: margin;
            transition: transform 200ms ease;
            overflow: hidden;
        }

        &__full-row {
            grid-column: 1 / -1;
        }

        &__title {
            color: $color-grey-6;
        }

        &__value {
            color: $color-grey-6;
            display: flex;
            justify-content: flex-end;
        }
    }

    &.show-order {
        svg {
            transform: rotate(-180deg);
        }

        & ~ .group-tab-order__data {
            max-height: var(--order-height);
        }

        & ~ .group-tab-order__data-loading {
            max-height: 54px;
        }

        & ~ .divider {
            transform: translate3d(0, -$spacing-component-small, 0);
        }
    }
}
