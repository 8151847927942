@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";

.fixed-notification-header {
    color: #9099a4;
    font-size: get-vw($font-size-sm);
    line-height: get-vw(17px);
    font-weight: $font-weight-medium;
    margin-bottom: 20px;
    padding-left: 20px;
}

.fixed-notification {
    padding: 18px;
    margin: 0 20px 10px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid $color-grey-1;
    border-radius: 10px;

    &--promotion {
        background-color: #fcfcfc;
        border: 1px solid #f2f2f2;
    }

    &__wrapper {
        &:first-child {
            margin-top: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        svg {
            display: block;
            margin-right: 18px;
            width: 25px;
            height: 25px;
        }
    }

    &__content {
        color: $color-black;
    }

    &__arrow {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        justify-content: flex-end;
    }
}
