.collapsible-section {
    overflow: hidden;
    will-change: height;
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.open {
        position: relative;
        visibility: visible;
    }

    &.animating {
        > * {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}
