.page-transition-wrapper {
    min-height: 100%;
    height: inherit;
    --animation-time: 250ms;
    --animation-z: 1px;
    [class$="-active"] {
        pointer-events: none;
        animation-timing-function: ease-in-out;
        animation-duration: var(--animation-time);
        transition-timing-function: ease-in-out;
        transition-duration: var(--animation-time);
        transition-property: none;
        animation-name: none;
    }

    .fade-enter {
        opacity: 0.01;
        &.fade-enter-active {
            opacity: 1;
            transition-property: opacity;
        }
    }

    .fade-exit {
        opacity: 1;
        .fade-exit-active {
            opacity: 0.01;
            transition-property: opacity;
        }
    }

    &.PUSH {
        > .slide-left {
            &-enter {
                z-index: 1;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &-active {
                    animation-name: slide-from-right;
                }
            }
        }

        > .move-left {
            &-enter {
                z-index: 2;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &-active {
                    animation-name: slide-from-right;
                }
            }
            &-exit {
                z-index: 0;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;

                &-active {
                    opacity: 1;
                    animation-name: slide-from-left-reverse;
                }
                &-done {
                    opacity: 0;
                }
            }
        }
    }

    &.POP {
        > .slide-left {
            &-exit {
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &-active {
                    animation-name: slide-from-right-reverse;
                }
            }
        }
        > .move-left {
            &-enter {
                z-index: 2;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &-active {
                    animation-name: slide-from-left;
                }
            }
            &-exit {
                z-index: 0;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                opacity: 0;
                &-active {
                    opacity: 1;
                    animation-name: slide-from-right-reverse;
                }
                &-done {
                    opacity: 0;
                }
            }
        }
    }
}
