@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.group-tab-information-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include safe-area-padding-bottom($bottom-nav-bar, bottom);

    &__header {
        padding: $spacing-header $double-spacing-component;
        @include safe-area-padding-top($spacing-header);
    }

    &__body {
        overflow-y: auto;

        .call-to-action-header{
            &__subtitle{
                margin-top: $spacing-component-xs;
            }
        }
    }

    &__row {
        padding: 0 $spacing-page-gutter $spacing-header;
        display: grid;
        grid-template-areas:
            "icon title"
            "icon description";
        grid-template-columns: 48px auto;
        align-items: center;

        &:first-of-type {
            padding-top: $spacing-component;
        }

        &__icon {
            grid-area: icon;
        }

        &__title {
            grid-area: title;
            color: $color-grey-8;
        }

        &__description {
            grid-area: description;
            color: $color-grey-4;
        }
    }

    footer {
        padding: $spacing-page-gutter;
        text-align: center;
    }

    &__personal-details {
        color: $color-grey-4;
    }
}
