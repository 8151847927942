@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.header-tabs {
    display: flex;
    align-items: center;
    margin-left: $spacing-component-xs;
    --active-tab-color: #{$color-primary};

    &--brand-color {
        --active-tab-color: #{$color-business};
    }

    &__header {
        display: flex;
        align-items: center;
        margin-right: $spacing-component;
        height: 100%;
        position: relative;

        @include svg-color($color-black);
        svg {
            margin-right: $spacing-component-xs;
            width: 16px;
            height: 16px;
        }

        .header-tabs__indicator {
            transition: transform 250ms ease-in-out;

            &--0 {
                transform: translateX(100%);
            }

            &--1 {
                transform: translateX(-100%);
            }
        }

        &--active {
            color: var(--active-tab-color);
            @include svg-color(var(--active-tab-color));

            .header-tabs__indicator {
                transform: unset;
                height: 2px;
                width: 100%;
                background-color: var(--active-tab-color);
                position: absolute;
                bottom: -1px;
            }
        }
    }
}
