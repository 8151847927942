@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.history-lists {
    overflow-x: hidden;
    width: 200vw;
    background-color: $color-white;

    &.flex {
        min-height: initial;
    }

    .wrapper-for-fixed-nav {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        @include safe-area-padding-top($simple-nav-header-height);

        @include hide-scroll-bar;
    }

    &__animated-tab {
        transition: transform 300ms ease-in-out;
    }

    &__container {
        display: flex;
        transition: transform 250ms ease-in-out;

        > div:not(resize-triggers),
        .history-list {
            width: 100vw;
        }

        &--second {
            transform: translateX(-100vw);
        }
    }

    &__group-tab-icon {
        display: flex;
        align-items: center;

        svg {
            width: 24px;
            height: 22px;
        }
    }

    &__login {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $spacing-header $spacing-page-gutter;
        background: linear-gradient(0deg, $color-white 54.69%, rgba(255, 255, 255, 0) 100%);

        .party-wrapper & {
            @include safe-area-padding-bottom($bottom-nav-bar, bottom);
        }

        &__content {
            background-color: $color-business;
            border-radius: $border-radius-large;
            box-shadow: 0px 8px 36px $color-shadow;
            padding: $spacing-component;
        }

        svg {
            width: 60px;
            height: 100%;
            margin-bottom: $spacing-component-small;
        }
        @include svg-color($color-white);

        &__title {
            color: $color-white;
            padding-bottom: $spacing-component-xs;
        }

        &__description {
            color: $color-grey-0;
            padding-bottom: $spacing-header;
        }
    }
}
