@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.foodcard {
    display: flex;
    flex-direction: row;
    color: $color-black;
    max-height: 100%;

    &:first-child {
        border-top: 0;
    }

    > .l {
        padding-right: 8px;
        flex: 1;
        position: relative;

        @include respond-to(mobile) {
            padding-right: $sizePaddingMobile;
        }
    }

    > .r {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        color: $color-grey-8;
    }

    &__thumb {
        display: block;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;

        @include respond-to(mobile) {
            width: 100%;
        }
    }

    &__thumb-wrapper {
        height: 100%;
        position: relative;
    }

    &__item-counter {
        position: absolute;
        top: $spacing-component-xxs;
        right: $spacing-component-xxs;
    }

    &__name {
        font-size: get-vw(18px);
        line-height: 135%;

        @include respond-to(mobile) {
            font-size: 19px;
        }
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__descriptors {
        flex: 1;
    }

    &__description {
        @include overflow-ellipsis-multiline(2);
        font-size: get-vw(13px);
        line-height: 1.4;
        color: $color-gray;
        margin-top: 10px;
        height: 2.65em;

        @include respond-to(mobile) {
            font-size: $font-size-sm;
            margin-top: $sizePadding / 2;
        }
    }

    &__dishes {
        font-size: get-vw(13px);
        line-height: 1.4;
        margin-bottom: 5px;
    }

    &__attributes {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    .price,
    .dietaryinfo {
        @include respond-to(mobile) {
            order: 2;
        }
    }

    .dietaryinfo {
        align-self: flex-start;
    }

    .tag {
        @include respond-to(mobile) {
            order: 1;
            flex: 0 0 100%;
        }
    }
}

.foodcardmodaloverlay {
    @include fill;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.foodcardmodal {
    @include clear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    border-radius: 0px;
    background-color: $color-light;
    overflow: auto;
    outline: none;

    &.ReactModal {
        &__Content {
            transform: translateX(50%);
            opacity: 0;

            &--after-open {
                transition: all 0.15s ease-in-out;
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}

.foodcarddetail {
    &__back,
    &__content,
    &__footer {
        padding: $sizePadding ($sizePadding * 2);

        @include respond-to(mobile) {
            padding: $sizePadding;
        }
    }

    &__photo {
        @include cover;
        width: 100%;
        height: 0;
        padding-bottom: 50%;
    }

    &__name {
        font-size: get-vw(26px);
        font-weight: 500;
        line-height: 1.3;
        padding-bottom: $sizePadding;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include respond-to(mobile) {
            font-size: get-vw(18px);
            padding-bottom: $sizePadding / 2;
        }
    }

    &__description {
        font-size: get-vw(18px);
        line-height: 1.4;
        color: $color-gray;

        @include respond-to(mobile) {
            font-size: get-vw($font-size-sm);
        }
    }

    &__attributes {
        padding-top: $sizePadding;
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    .price,
    .tag,
    .dietaryinfo {
        padding-top: $sizePadding / 2;
    }

    .price,
    .dietaryinfo {
        @include respond-to(mobile) {
            order: 2;
        }
    }

    .tag {
        @include respond-to(mobile) {
            order: 1;
            flex: 0 0 100%;
        }
    }
}
