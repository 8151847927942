@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-receipt-pay {
    @include floating-fixed-bottom-button;
    @include hide-when-keyboard-open(all, ".PayOnlyPaymentWizard-modal__Body--open.pay-only-party");
    opacity: 1;
    transition-property: transform, bottom, opacity;

    &.hidden {
        opacity: 0;
    }
}
