@import "src/styles/variables/variables";

.promotion-page {
    height: 100vh;
    &__content {
        padding: 0 20px;
        margin-top: 40px;
        font-size: get-vw($font-size-sm);
        strong {
            font-weight: $font-weight-semibold;
            color: #4f4f4f;
        }
        p {
            margin-top: 20px;
            font-size: get-vw($font-size-xs);
            font-weight: $font-weight-normal;
        }
    }
}

.promotion-modal {
    padding: 0;
    position: relative;
    width: 100vw;
    min-height: 100%;
    background-color: white;
}

.ReactModalPortal .ReactModal__Promotion {
    &.ReactModal__Overlay {
        z-index: 10;
        opacity: 1 !important;
        transform: translate3d(100vw, 0, 0);
        transition: transform 400ms ease;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        &--after-open {
            transform: translate3d(0, 0, 0);
        }
        &--before-close {
            transform: translate3d(100vw, 0, 0);
        }
    }
}
