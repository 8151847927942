@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.payment-item {
    display: flex;
    justify-content: space-between;
    color: $color-grey-7;
    margin-bottom: $spacing-component-small;
    transform: translate3d(0, 0, 0);

    &:last-child {
        margin-bottom: 0;
    }

    &__text,
    &__value {
        display: flex;
        align-items: center;

        svg {
            display: block;
        }
    }

    &__text {
        svg {
            margin-left: $spacing-component-xxs;
        }
    }

    &__value {
        svg {
            margin-right: $spacing-component-xxs;
        }
    }
}
