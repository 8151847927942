@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.duplicated-page {
    background: $color-neutral;
    @include use-100vh(height);

    &__content {
        padding: 0 28px 0 20px;
        color: $color-text-inverse;
        display: flex;
        flex-direction: column;
    }

    &__title {
        line-height: 100%;
        margin-bottom: 8px;
    }
}
