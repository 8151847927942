@import "../../common/mixins";

.track-scroll {
    .android &.parallax {
        perspective: 1px;
        perspective-origin: 0 0;
    }

    .android &.parallax .parallax-element {
        position: relative;
        overflow: hidden;

        transform-origin: 0 0;
        transform: translateZ(-2px) scale(3);
        z-index: -1;
    }
}
