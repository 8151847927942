@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.order-history-tile {
    padding: 16px 20px;
    width: 100%;
    height: 76px;

    &__first-line {
        display: flex;
        justify-content: space-between;
    }

    &--ready {
        background-color: $color-business-light;
    }

    &__date {
        @include overflow-ellipsis-multiline(2);
    }

    &__status {
        color: $color-grey-7;
        display: flex;
        align-items: center;
        margin-top: $spacing-component-xs;

        &:before {
            content: ".";
            color: transparent;
            background: $color-grey-5;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            margin-right: $spacing-component-xs;
            margin-bottom: 1px;
            display: inline-block;
        }

        &--0 {
            &:before {
                background: $color-grey-5;
            }
        }

        &--complete {
            color: $color-grey-5;
        }

        &--pending,
        &--2,
        &--1 {
            color: $color-grey-5;
            &:before {
                background: $color-transparent;
                border: 1px solid $color-grey-5;
            }
        }

        &--3 {
            color: $color-grey-8;
            &:before {
                background: $color-warning;
            }
        }

        &--error {
            color: $color-error;
            &:before {
                background: $color-error;
            }
        }
    }

    &__used-tab {
        color: $color-grey-4;
    }
}
