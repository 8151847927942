@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.remove-payment-method {
    &__title {
        padding: 0 $spacing-page-gutter $spacing-header;
    }

    &__subtitle {
        color: $color-grey-8;

        &--device {
            color: $color-business;
        }
    }

    &__instructions {
        padding: 0 $spacing-page-gutter;
        color: $color-grey-8;
    }

    .payment-method {
        padding: 0 $spacing-page-gutter;
    }
}
