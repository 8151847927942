@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.view-cart-button {
    @include floating-fixed-bottom-button;

    &__icons {
        margin-left: 10px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        svg {
            width: 20px;
        }

        .counter {
            position: absolute;
            top: 3px;
            left: 16px;
        }
    }
}
