@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/styles/mixins/mixins";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.home-icon {
    width: 20px;
    height: 20px;
}

.menu-background {
    &::before {
        content: "";
        background-repeat: no-repeat;
        background-size: cover;
        background-image: var(--menu-background-image);
        position: absolute;
        height: var(--device-height);
        width: 100%;
        z-index: -1;
    }
}

.service-page {
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: $color-white;
    display: flex;
    position: fixed;
    @include hide-when-keyboard-open;

    .menu-background & {
        background: transparent;
    }

    &.active-page {
        position: static;
    }

    & footer {
        margin: 0 -20px;
    }
    header {
        background-color: $color-background;
        @include safe-area-padding-top(0px);
        display: block;

        .header-content {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $color-background;
        @include safe-area-padding-top(0px);
        border-bottom: 1px solid $color-grey-1;
        opacity: 0;

        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 50px;
        pointer-events: none;

        @include safe-area-padding-top(50px, height);

        &,
        * {
            transition-property: opacity, transform;
            transition-duration: 150ms;
            transition-timing-function: ease-out;

            .party-wrapper-transition-enter & {
                transition: none;
            }
        }
        .text {
            transform: translate3d(-37vw, 10px, 10px);
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            padding: 0 20px;
        }
        svg {
            max-height: 100%;
            height: 100%;
            padding: 4px 0;
            transform: scale(1.5);
        }
        > {
            @include svg-color($color-white);
        }
        &.show-header {
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
            svg {
                transform: scale(1);
            }
            @include svg-color($color-black);
            .text {
                transform: initial;
            }
        }

        &.with-banner {
            margin-top: $small-banner-height;
        }

        // Hide when keyboard open for iOS because of overscroll
        @include hide-when-keyboard-open;
    }

    &__image-header {
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        flex-direction: column;

        svg {
            max-width: 100%;
            max-height: 40%;
            min-width: 180px;
            height: 100%;
        }
        > {
            @include svg-color($color-text-inverse);
        }

        .chip {
            position: fixed;
            @include safe-area-padding-top($spacing-component-xs, top);
            left: $spacing-component-small;

            &__button {
                background: $color-business;
                --button-color: #{$color-business};
                color: $color-white;
            }
        }
    }

    &__content {
        padding-top: 50px;
        height: 100%;

        .party-wrapper & {
            padding-top: 0;
        }

        &.with-banner {
            @include safe-area-padding-top($small-banner-height, margin-top);
        }
        .hide-banner & {
            @include safe-area-padding-top($small-banner-height, margin-top);
        }

        &__items {
            background-color: $color-white;

            .with-menu-background & {
                background-color: transparent;
            }

            .no-hero & {
                margin-top: 0;
                @include safe-area-padding-top(50px);
            }
        }

        &.preview {
            padding-top: 0;
        }
    }

    &__scheduling {
        transform: translateY(-$button-height/2);
        margin-bottom: -$button-height/2;
    }
    &__favourite {
        padding-top: 20px;

        &__title {
            padding-left: 20px;
            margin-bottom: 16px;
        }

        .swiper-container {
            width: 100%;
            z-index: initial;
        }

        .swiper-wrapper {
            margin-left: 20px;
            z-index: initial;
        }

        .swiper-slide {
            text-align: center;
            display: flex;
            justify-content: center;
            text-align: left;
            background-color: $color-white;
        }

        &__container {
            &__item {
                display: flex;
                flex-direction: column;

                &__link {
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    margin-bottom: 7px;
                }

                &__image {
                    max-width: 100%;
                    min-height: 112px;
                    height: auto;
                    max-height: 132px;
                    border-radius: 8px;
                    object-fit: cover;
                    overflow: hidden;
                }

                &__name {
                    color: $color-grey-8;
                }

                &__cost {
                    color: $color-grey-6;
                }

                &__unavailable {
                    display: flex;
                    color: #7c7c7c;

                    svg {
                        margin-right: 5px;
                    }
                }

                &.unavailable {
                    .service-page__favourite__container__item__image {
                        opacity: 0.5;
                    }
                    .service-page__favourite__container__item__name {
                        opacity: 0.3;
                    }
                }
            }
        }
    }

    &__menu-card-list {
        list-style: none;
        margin-left: 0;
        margin-top: $spacing-page-gutter;

        &.inactive {
            margin-bottom: 0;
        }

        > li {
            padding: 0;
            margin: 0;
        }
    }

    &__surcharge-message {
        color: $color-grey-5;
        padding: $spacing-header + $spacing-page-gutter $spacing-page-gutter $spacing-page-gutter;
    }

    &__cta {
        margin: $spacing-component $spacing-page-gutter;
    }

    &__top {
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: $spacing-component-small;

        @include safe-area-padding-top(0px, top);

        &--banner {
            @include safe-area-padding-top(#{$small-banner-height}, top);
        }

        &--right {
            justify-content: end;
        }

        .hide-banner & {
            @include safe-area-padding-top(#{$small-banner-height}, top);
        }

        &__allergen {
            width: auto;
            height: 36px;
            min-height: 36px;
            padding: 0 $spacing-component-small;
            font-size: $font-size-sm;

            .button-element__right-icon {
                margin-left: $spacing-component-xs;
            }

            svg {
                width: 18px;
                height: 18px;
                min-width: initial;
            }
        }

        .button-element.service-page__top__allergen {
            color: $color-grey-9;

            @include svg-color($color-grey-9);
        }

        &__search {
            &--nav {
                position: fixed;
                right: 0;
                padding: $spacing-component $spacing-page-gutter;
            }

            &__inner {
                .service-page & {
                    border-radius: 50%;
                    height: 36px;
                    width: 36px;
                    background-color: $color-white;
                    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
                }
            }

            svg {
                min-width: initial;
            }
        }
    }

    .with-view-cart & {
        padding-bottom: $view-cart-button-height;
    }

    .with-ready-indicator & {
        padding-bottom: $spacing-page-gutter;
    }

    .with-view-cart.with-ready-indicator & {
        padding-bottom: calc(#{$view-cart-button-height} + #{$spacing-page-gutter});
    }

    &__location-address {
        width: 100%;
        text-decoration: none;

        &__formatted {
            @include svg-color($color-text-inverse);
            @include overflow-ellipsis;
            align-items: center;
            justify-content: center;

            svg {
                min-width: auto;
                max-height: none;
                width: 12px;
                height: 12px;
                margin-right: $spacing-component-xxs;
            }
        }
    }
}
