@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.legal-page {
    @include safe-area-padding-top($simple-nav-header-height);
    
    .wrapper-for-fixed-nav {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    &__iframe {
        padding: 0;
        height: calc(100% + #{$simple-nav-header-height});
        width: 100%;
        border: 0;
        margin-top: $simple-nav-header-height;
        margin-top: calc(#{$simple-nav-header-height} + constant(safe-area-inset-top)); //for iOS 11.1
        margin-top: calc(#{$simple-nav-header-height} + env(safe-area-inset-top)); //for iOS 11.2
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include safe-area-padding-bottom(0px);

        &.with-nav-bar {
            height: 100%;
        }
    }
}
