@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.modifier-radio-list-item {
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;

    &__container {
        padding: $spacing-component-small $spacing-page-gutter;
        width: 100%;
    }
}
