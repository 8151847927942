@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-set-first-name {
    @include pay-only-height-100-with-header;
    display: flex;
    flex-direction: column;

    .form-control {
        padding: $spacing-page-gutter;
    }

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        @include hide-scroll-bar;
    }

    &__title {
        padding: $spacing-component-small $spacing-page-gutter;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing-component-small $spacing-page-gutter;
        border-top: 1px solid $color-grey-0;

        .no-connectivity-banner + & {
            border: 0;
        }
    }
}
