@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/styles/variables/variables";
@import "src/sharedComponents/styles/animations/animations";

div.ReactModal__Overlay.ReactModal__Overlay--after-open.OrderHolding--overlay {
    transform: translateZ(10px);
    z-index: 10;
}

.order-holding {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: $color-grey-7;
    background-color: $color-grey-9;

    main {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: safe center;
        // auto needed just in case safe is not supported, feel free to remove them as soon as the adoption increase
        // https://caniuse.com/mdn-css_properties_align-self_flex_context_safe_unsafe
        margin: auto auto calc(#{$button-height} * 3);
        padding: 0 $spacing-component;
        text-align: center;
        color: $color-white;
        overflow: auto;

        @media screen and (max-height: 575px) {
            justify-content: start;
            @include safe-area-padding-top($spacing-header * 2);
        }
    }

    &__title {
        padding-bottom: $spacing-component-small;
        margin-top: $spacing-component;
    }

    &__loader {
        display: flex;
        margin-top: $double-spacing-component;
        width: 60px;
        height: 60px;
        overflow: visible;

        @media screen and (max-height: 575px) {
            margin-top: 0;
        }

        path[stroke-width="3"] {
            // large circle thickness
            stroke-width: 5;
        }

        path[stroke-width="15"] {
            // small circles thickness
            stroke-width: 20;
        }
    }

    &__members {
        margin: $double-spacing-component $spacing-page-gutter;

        @media screen and (max-height: 575px) {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-component-small $spacing-page-gutter;
        background-color: $color-success;
        @include safe-area-padding-top($spacing-component-small);

        &__text {
            color: $color-white;
        }
    }

    &__timer {
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: $border-radius-xxl;
        padding: $spacing-component-xs $spacing-component-small;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 36px;
        min-width: 80px;

        @include svg-color($color-white);
        svg {
            stroke-width: 2px;
            animation: fade-in 200ms ease-in-out;
        }

        &__time {
            animation: fade-in 200ms ease-in-out;
            font-weight: $font-weight-semibold;
            width: 38px;
            display: flex;
        }

        .pulse-loader {
            animation: fade-in 200ms ease-in-out;

            i {
                width: 4px;
                height: 4px;

                + i {
                    margin-left: $spacing-component-xxs;
                }
            }
        }
    }

    footer {
        padding: $spacing-page-gutter;
        text-align: center;
        @include safe-area-padding-bottom($spacing-component);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-grey-9;

        button {
            &:last-child {
                margin-top: $spacing-component;
                color: $color-white;

                &.disabled,
                &[disabled] {
                    color: $color-grey-3;
                }
            }
        }
    }
}

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__OrderHoldingConfirmation {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            z-index: 10;
            transform: translateZ(10px);

            .order-holding-confirmation-modal {
                @include animate-modal(slide-up, 150ms, ".order-holding-confirmation");
            }
        }
    }
}

.order-holding-confirmation {
    width: 100%;
    padding: $spacing-header $spacing-page-gutter 0;
    @include safe-area-padding-bottom($spacing-page-gutter);
    max-height: fit-content;
    background-color: $color-white;
    border-top-left-radius: $border-radius-normal;
    border-top-right-radius: $border-radius-normal;

    &__title {
        padding: $spacing-component $spacing-page-gutter $spacing-header;
        text-align: center;
    }

    > button {
        &:first-of-type {
            margin-bottom: $spacing-component;
        }
    }
}
