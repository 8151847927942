@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.incorrect-document-height.ReactModal__Body--open.ReactModal__body__AnotherRoundModal {
    overflow: hidden;
    transform: translateZ(1px);
}
.another-round {
    &-modal {
        overflow: hidden;
        overflow-y: scroll;
        @include hide-scroll-bar;
        max-height: calc(var(--device-height));
        // the calculation is to prevent body scroll when there are few items
        height: calc(var(--device-height) - #{($button-height * 2 - $spacing-component-xs - $spacing-component)});
        background: linear-gradient(180deg, transparent 58%, $color-white 60%);

        .incorrect-document-height & {
            margin-top: calc(var(--document-height-initial) - var(--document-height-diff));
        }
    }

    &__shield {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__navbar {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transform: translate3D(0, -100%, 1px);
        transition: opacity 100ms ease, transform 100ms ease;

        &.show-navbar {
            opacity: 1;
            pointer-events: auto;
            transform: translate3D(0, 0, 1px);
            border-bottom: 1px solid $color-grey-0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: $color-white;
        width: 100vw;
        color: $color-grey-7;
        border-top-left-radius: $border-radius-large;
        border-top-right-radius: $border-radius-large;
        box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.17);

        // keep space at the bottom of the body for ----------------------- buttons --------------------- and some space
        @include safe-area-padding-bottom(
            $button-height * 2 + $spacing-component-xs + $spacing-component + $spacing-header * 2
        );
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: $double-spacing-component;

        &__round {
            border-radius: $border-radius-circle;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
            background-color: $color-grey-1;
            width: 12vh;
            height: 12vh;
            position: relative;
            transform: translateY(-50%);
            overflow: hidden;

            .drinkcard__thumb {
                width: inherit;
                height: inherit;
                border-radius: 50%;
            }

            .hide-product-images & {
                visibility: hidden;
            }
        }

        &__title {
            // To make for the space caused by moving the circle up
            margin-top: -6vh;
            padding-top: $spacing-component;
            color: $color-grey-9;

            .hide-product-images & {
                padding-top: 0;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: $spacing-component $spacing-page-gutter;
        max-width: 100%;

        &__image {
            margin-right: $spacing-component-xs;
            width: 45px;
            height: 60px;
            background-color: $color-grey-1;
            border-radius: $border-radius-small;
            overflow: hidden;

            &--disabled {
                opacity: 0.5;
            }

            .drinkcard__thumb {
                width: inherit;
                border-radius: $border-radius-small;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-right: $spacing-component-xs;

            &__name {
                @include overflow-ellipsis-multiline(2);
            }

            &__variant {
                color: $color-grey-6;
                margin-top: $spacing-component-xxs;
                @include overflow-ellipsis-multiline(1);
            }

            &__unavailable {
                color: $color-warning;
                margin-top: $spacing-component-xxs;
                @include overflow-ellipsis-multiline(1);
            }
        }

        &__quantity-wait-time {
            display: flex;
            flex-direction: column;
        }

        &__wait-time {
            display: flex;
            justify-content: flex-end;
            margin-top: $spacing-component-xxs;
            .wait-time-badge {
                position: initial;
            }
        }

        &__quantity {
            display: flex;
            align-items: center;

            &--disabled,
            :disabled {
                opacity: 0.3;
            }

            &__number {
                width: 32px;
                margin: 0 $spacing-component-xxs;
                justify-content: center;
            }

            &__button {
                display: flex;

                &:disabled {
                    opacity: 0.3;
                }

                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    &__actions {
        padding: 0 $spacing-page-gutter;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        @include safe-area-padding-bottom($spacing-component);

        // so the round corner of the first button will not have white background
        background: linear-gradient(180deg, transparent 30px, $color-white 30px);

        .incorrect-document-height & {
            bottom: var(--document-height-diff);
        }

        button:not(:last-of-type) {
            margin-bottom: $spacing-component-xs;
        }
    }
}

.ReactModalPortal .ReactModal__Overlay.ReactModal__AnotherRoundModal {
    &.ReactModal__Overlay {
        background-color: transparent;

        .another-round__body {
            transform: translateY(calc(var(--device-height) * 0.3));
            animation: round-bounce 450ms ease-out;
        }

        &--after-open {
            background-color: add-opacity($color-black, 0.7);
        }

        &--before-close:not(.item-added) {
            opacity: 1;
            transition: background-color 250ms ease-in-out;
            background-color: transparent;

            .another-round-modal {
                transform: translateY(var(--device-height));
                transform: translateY(unquote("max(100vh, 100%)"));
                transition: transform 250ms ease-in-out;
            }
        }
    }
    &.item-added {
        &.ReactModal__Overlay {
            &--before-close {
                opacity: 1;
                transition: background-color 250ms ease-in-out;
                background-color: transparent;
                animation-duration: 350ms;

                .another-round-modal {
                    animation: round-add 350ms ease-out;
                    @include use-100vh(height);
                    transform-origin: center calc(100% - 40px) 60px;
                }
            }
        }
    }
}
@keyframes round-bounce {
    50% {
        transform: translateY(calc(var(--device-height) * 0.25));
    }
}
@keyframes round-add {
    0% {
        transform: translateY(0);
    }
    20% {
        transform: scale(0.91) translateY(-15%);
    }
    100% {
        transform: scale(0.01) translateY(var(--device-height));
    }
}

@keyframes no-connectivity-slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
