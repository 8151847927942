@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.NoNetworkNotification-modal {
    &__overlay {
        &.ReactModal__Overlay {
            background: none;
            z-index: 3000;
            height: fit-content;
            transform: translate3d(0, 0, 3000px);
        }
    }
}

.no-network-notification {
    width: 100%;
    padding: 0 $spacing-component-xs;

    @include safe-area-padding-top($spacing-component-xs);

    &__prompt {
        width: 100%;
        background: $color-error;

        div:last-child {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;

            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    &__fade-in {
        animation: fade-in-bck 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    &__fade-out {
        animation: fade-in-reverse 0.2s ease-out both;
    }
}
