@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.legal-text__terms {
    padding: $spacing-page-gutter 0;
    color: $color-grey-3;
    display: block;

    span:not(.text) {
        color: $color-primary;
        cursor: pointer;
    }

    .payment-detail + & {
        padding-top: $spacing-page-gutter;
    }
}

.ReactModalPortal div.privacy-modal {
    background: $color-white;
    height: 100%;

    &--overlay {
        background: $color-white;
        z-index: 111;
        transform: translateZ(111px);
        box-shadow: 0 4px 20px -16px rgba(0, 0, 0, 0.75);

        .modal-message__Body & {
            z-index: 201;
            transform: translateZ(201px);
        }
    }
}

.ReactModalPortal div.privacy-modal--overlay {
    &.ReactModal__Overlay {
        z-index: 111;
        transform: translateZ(111px);

        .modal-message__Body & {
            z-index: 201;
            transform: translateZ(201px);
        }

        @include animate-modal(slide-from-left);
    }
}
