@import "src/styles/variables/_variables";
@import "src/styles/mixins/mixins";

.toast-order-status {
    position: absolute;
    top: 0;
    right: 0;
    @include safe-area-padding-top(0px);

    &__ready {
        background: $color-primary;
        left: 0;
        display: grid;
        grid-template-areas:
            "icon title arrow"
            "icon description arrow";
        grid-template-columns: 40px auto 20px;
        padding: 26px 23px;
        @include safe-area-padding-top(26px);
        line-height: 140%;
        color: $color-light;

        &__icon {
            grid-area: icon;
            display: flex;
            align-items: center;

            svg {
                path:not(.background) {
                    fill: $color-text-inverse;
                }

                path.background {
                    fill: $color-primary;
                }
            }
        }

        &__arrow {
            grid-area: arrow;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__title {
            font-weight: 600;
            font-size: 17px;
            text-transform: uppercase;
        }

        &__description {
            font-size: 13px;
        }
    }

    &__message {
        min-width: 75vw;
        background: $color-background;
        color: $color-clickable;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 13px;
        line-height: 140.62%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;

        &__bell {
            transform-origin: top;
            animation: bell-ring 500ms 150ms ease-in-out 3;
        }

        svg {
            height: 20px;
        }
    }

    @keyframes bell-ring {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(-20deg);
        }
        50% {
            transform: rotate(0);
        }
        75% {
            transform: rotate(20deg);
        }
        100% {
            transform: rotate(0);
        }
    }
}
