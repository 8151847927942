@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal {
    .ServiceChange-modal--overlay {
        &.ReactModal__Overlay {
            z-index: 1010;
            transform: translateZ(1010px);

            .ServiceChange-modal.slide-in {
                background: transparent;
            }

            &--before-close {
                &.ReactModal__Overlay {
                    animation-name: fade-in-reverse;
                }
            }

            .background-blur & {
                filter: none;
            }
        }
    }
}

.service-change {
    height: 100%;
    position: relative;
    background-color: $color-grey-9;
    color: $color-white;
    text-align: center;
    animation: service-change-delay-background-opacity 200ms ease-in 600ms forwards;

    &__title {
        line-height: $font-pwa-title;
        margin-bottom: $spacing-component-xs;
        position: relative;
    }

    &__spinner {
        position: absolute;
        left: 50%;
        bottom: calc(100% + #{$spacing-header});
        transform: translateX(-50%);
        opacity: 0;
        animation: service-change-delay-loader 200ms ease-in 300ms forwards;
        padding: 5px;
        height: 112px;
        width: 112px;
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);

        button {
            --button-color: #{$color-grey-9};
        }
    }

    &__status {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 0 20px;
    }

    &__button {
        white-space: pre;

        span {
            padding-left: 0;
        }
    }

    .changing {
        &-enter {
            opacity: 0;

            .text {
                transform: translate3d(0, #{$spacing-header}, 0);
            }

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 200ms;

                .text {
                    transform: translate3d(0, 0, 0);
                    transition: transform 200ms ease-in 200ms;
                }
            }
        }
        &-exit {
            opacity: 1;

            .text {
                transform: translate3d(0, 0, 0);
            }

            &-active {
                opacity: 0;
                transition: opacity 200ms ease-out;

                .text {
                    transform: translate3d(0, -#{$spacing-header}, 0);
                    transition: transform 200ms ease-out;
                }
            }
        }
    }

    .changing-button {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 200ms;
            }
        }
        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 200ms ease-out;
            }
        }
    }
}

@keyframes service-change-delay-background-opacity {
    from {
        background-color: $color-grey-9;
    }
    to {
        background-color: $color-overlay;
    }
}

@keyframes service-change-delay-loader {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
