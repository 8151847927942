@import "src/sharedComponents/common/variables";

.filter__wrapper {
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter {
    max-width: 25px;
    flex: 1;
    display: inline-block;
    cursor: pointer;
    background: transparent;
    position: relative;
    box-sizing: content-box;
    padding: 6px;
    padding-right: 20px;
    padding-left: 10px;
    min-height: auto;
    height: 30px;

    &[disabled] {
        opacity: 0.3;
    }

    svg {
        width: 18px;
        height: 18px;
        margin: 0;

        g {
            transition-property: stroke, opacity;
            transition-duration: 0.3s;
            opacity: 1;
        }
    }

    &.selected-filters-on {
        &::after {
            content: "";
            border-radius: 50%;
            height: 6px;
            width: 6px;
            background-color: $color-business;
            position: absolute;
            margin-left: 2px;
            margin-top: 7px;
        }
    }
}
