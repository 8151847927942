@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.message-screen {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$simple-nav-header-height});
    align-items: center;
    background-color: $color-white;
    flex: 1;
    width: 100%;
    position: relative;

    &__info-container {
        padding: $double-spacing-component 0;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &__text {
        padding: $double-spacing-component $spacing-component;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title,
    &__description {
        margin-bottom: $spacing-component-small;
    }
}
