@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.opt-in-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    &__footer {
        position: fixed;
        bottom: 0;
        padding: $spacing-component;
        background-color: $color-white;
    }

    &__privacy-policy-text {
        margin-top: $spacing-component-small;
    }

    .message-screen__text {
        margin-bottom: calc(
            #{$button-height} + #{$double-spacing-component} + #{$spacing-component * 4} + #{$big-banner-height}
        );
    }
}
