@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.manage-memberships {
    .profile-page__title {
        padding: $spacing-header $spacing-page-gutter;
    }

    &__no-memberships {
        padding: $spacing-component $spacing-page-gutter;
        color: $color-grey-4;
    }

    &__featured {
        margin: 0 $spacing-page-gutter $spacing-header;
        background: $color-white;
        box-shadow: 0 4px 16px $color-shadow;
        border-radius: $border-radius-normal;
        padding: $spacing-component;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo {
            margin: $spacing-component-small;
            height: 60px;
            width: 90px;
            border-radius: $spacing-component-xxs;
        }


        &__icon {
            margin: $spacing-component-small;
            height: 60px;
            width: 90px;
            border-radius: $border-radius-normal;
            background-color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
    
            @include svg-color($color-primary-text);
            svg {
                height: 48px;
                width: 48px;
            }
        }

        &__title {
            color: $color-grey-9;
            margin-bottom: $spacing-component-xs;
            width: 100%;
            text-align: center;
        }

        &__features {
            color: $color-grey-6;
            margin-bottom: $spacing-component-xxs;
            width: 100%;
            text-align: center;
        }

        &__link {
            margin-top: $double-spacing-component;
            max-height: 44px;
        }
    }

    &__section-title {
        color: $color-grey-4;
        margin: $double-spacing-component $spacing-page-gutter $spacing-component-xs;
    }

    &__membership {
        display: flex;
        align-items: center;
        padding: $spacing-component-small 0;
        min-height: 56px;

        .manage-memberships & {
            padding: $spacing-component-small $spacing-page-gutter;
        }

        &__logo {
            display: flex;
            align-items: center;
            margin-right: $spacing-title;
            border-radius: $border-radius-xs;
            overflow: hidden;

            &__image {
                width: 30px;
            }

            &__internal {
                width: 30px;
                height: 20px;
                background: $color-business;
                display: flex;
                justify-content: center;
                align-items: center;

                @include svg-color($color-white);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex: 2;
            color: $color-grey-9;
        }
        &__connected {
            display: flex;
            align-items: center;
            color: $color-grey-5;

            svg {
                circle {
                    fill: $color-business;
                }
                width: 12px;
                height: 12px;
                margin-right: $spacing-component-xxs;
            }
        }

        &__action {
            display: flex;

            @include svg-color($color-grey-2);
            svg {
                width: 15px;
            }
        }
    }
}

.ReactModal__ManageMemberships {
    transform: translateZ(10px);
    @include when-keyboard-open {
        visibility: hidden;
    }
}

.ReactModal__MembershipSwitch.ReactModal__Overlay {
    display: flex;
    align-items: flex-end;
    z-index: 10;
    transform: translateZ(10px);

    .membership-switch-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @include animate-modal(slide-up, 150ms, ".membership-switch-modal__content");
        &--before-close {
            @include animate-modal(down-up, 150ms);
        }

        &__content {
            background-color: $color-white;
            padding: $double-spacing-component $spacing-page-gutter;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            @include safe-area-padding-bottom($double-spacing-component);
            border-radius: $border-radius-normal $border-radius-normal 0 0;
        }

        &__title {
            padding-bottom: $spacing-header;
        }

        &__primary-action {
            margin-bottom: $spacing-component;
        }
    }
}

.ReactModal__MembershipSignup {
    transform: translateZ(10px);
}

.ReactModalPortal .ReactModal__MembershipSignup.ReactModal__Overlay--after-open {
    .sso-complete & {
        z-index: 200;
        transform: translateZ(200px);
        background: transparent;
        animation-name: slide-from-right;
    }
    .membership-after-signin & {
        animation-name: slide-from-right;
    }
}

