@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.pay-with-points {
    .checkbox-element {
        label {
            padding-left: 20px;
            @include svg-color($color-white);
        }
    }

    &--no-payment-method {
        margin-top: $spacing-component-small;
    }
}