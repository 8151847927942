@import "../../common/mixins";
@import "../../common/variables";

.actionable-row {
    padding: $spacing-component $spacing-page-gutter;
    // display: grid;
    // column-gap: $spacing-component;
    // grid-template-areas:
    //     "right title arrowIcon"
    //     "right description arrowIcon";
    display: flex;
    align-items: center;
    // border-bottom: 1px solid $color-grey-0;

    &__right {
        grid-area: right;
        align-items: center;
        display: flex;
        margin-right: $spacing-component;

        @include svg-color($color-grey-5);
        svg {
            height: 32px;
            width: 32px;
        }
    }

    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        grid-area: title;
        color: $color-grey-8;
    }

    &__description {
        grid-area: description;
        color: $color-grey-5;
    }

    &__arrowIcon {
        grid-area: arrowIcon;
        align-items: center;
        display: flex;
        margin-left: $spacing-component;

        @include svg-color($color-grey-2);
    }
}
