@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

@mixin tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: $tooltop-arrow-width;
    border-style: solid;
}

.tooltip-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.tooltip {
    display: flex;
    align-items: center;
    padding-right: $spacing-component;
    background-color: $color-grey-9;
    border-radius: $border-radius-medium;
    color: $color-white;
    justify-content: space-between;
    position: fixed;
    width: calc(100vw - (2 * #{$spacing-page-gutter}));
    transform: translateX(-50%);
    transform-origin: center;
    left: 50%;
    opacity: 0;

    &__content {
        padding: $spacing-component-small $spacing-component;
        padding-right: 0;

        > * {
            pointer-events: none; // .tooltip needs to handle pointer events
        }
    }

    &::after,
    &::before {
        @include tooltip-arrow();
    }

    &::after {
        border-color: $color-grey-9 transparent transparent transparent;
    }

    &::before {
        border-color: transparent transparent $color-grey-9 transparent;
    }

    &.bottom {
        bottom: calc(100% + #{$spacing-component-xxs});
        &::after {
            content: "";
            bottom: -24px;
        }
    }

    &.top {
        top: calc(100% + #{$spacing-component-xs});
        &::before {
            content: "";
            top: -24px;
        }
    }

    &.left {
        &::after,
        &::before {
            left: calc(20% - (#{$tooltop-arrow-width}));
        }
    }

    &.center {
        &::after,
        &::before {
            left: calc(50% - (#{$tooltop-arrow-width}));
        }
    }

    &.element-center {
        &::after,
        &::before {
            margin-left: calc(-#{$spacing-page-gutter} - #{$tooltop-arrow-width});
            left: var(--element-left, calc(50% - (#{$tooltop-arrow-width})));
        }
    }

    &.right {
        &::after,
        &::before {
            left: calc(80% - (#{$tooltop-arrow-width}));
        }
    }

    &__close {
        display: flex;
        @include svg-color($color-white);
        margin-left: $spacing-component-small;

        svg {
            width: 10px;
            height: 10px;
        }
    }

    &.hide-tooltip {
        animation: fade-in-reverse 0.2s ease-out both;
    }

    &.show-tooltip {
        animation: fade-in-back-centered 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
}

@keyframes fade-in-back-centered {
    from {
        transform: translateX(-50%) scale(1.05);
        opacity: 0;
        pointer-events: none;
    }

    to {
        transform: translateX(-50%) scale(1);
        opacity: 1;
        pointer-events: all;
    }
}
