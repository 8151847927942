@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.member-indicator {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    border: 2px solid $color-grey-2;
    border-radius: $border-radius-circle;
    background: $color-white;
    color: $color-grey-7;

    @include svg-color($color-grey-4);

    svg {
        width: 18px;
        height: 18px;
    }

    &.large {
        height: 56px;
        width: 56px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &:not(:first-of-type) {
        margin-left: -6px;
    }

    &.active {
        border-color: $color-success;
        @include svg-color($color-grey-7);
    }

    &.disabled {
        border-color: $color-grey-1;
        color: $color-grey-3;
        @include svg-color($color-grey-1);
    }

    &__active-icon {
        position: absolute;
        top: -5px;
        right: -5px;
        display: flex;

        .active & {
            @include svg-color($color-white);
        }

        > svg {
            z-index: 1;
        }

        &::before {
            content: "";
            background: $color-success;
            position: absolute;
            top: 1px;
            bottom: 1px;
            left: 1px;
            right: 1px;
            border-radius: 50%;
        }
    }
}
