@import "src/styles/variables/variables";

.diagnosticInformation {
    &__wrapper {
        font-family: "Manrope", sans-serif;
        font-size: get-vw($font-size-sm);
        color: $color-light;
        padding: 20px;
        width: 100%;
    }

    &__apply-update,
    &__copy-info {
        position: absolute;
        left: 0;
        bottom: 0;
        width: fit-content;
        padding: 10px 10px;
        font-size: get-vw($font-size-xs);
        margin: 20px;
        z-index: 1;
    }

    &__copy-info {
        background: transparent;
        border: 1px solid $color-light;
        right: 0;
        left: auto;
        opacity: 0.8;
    }

    &__detail {
        &:first-of-type {
            font-size: get-vw($font-size-lg);
        }
        opacity: 0.8;
        font-size: get-vw($font-size-xs);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        button {
            background: transparent;
            border: 1px solid $color-light;
            width: auto;
            padding: 4px;
            min-height: 0;
            opacity: 0.8;
            font-size: get-vw($font-size-xs);
        }
    }
}
