@import "../../common/variables";
@import "../../common/mixins";

.code-input-control {
    display: flex;

    input {
        position: fixed;
        top: -200vh;
        background-color: transparent;

        &:focus ~ * {
            &[data-char=" "] {
                border-color: $color-black;
            }
        }
    }

    &__char {
        min-width: fit-content;
        min-height: fit-content;
        // calculating the box size based on the space that should be left
        width: calc((100vw - 50px - (16px * (var(--code-length) - 1))) / var(--code-length));
        height: calc((100vw - 50px - (16px * (var(--code-length) - 1))) / var(--code-length));
        max-width: 67px;
        max-height: 67px;
        display: flex;
        justify-content: center;
        margin: $spacing-component-small 0;
        margin-right: $spacing-component;
        padding: 0;
        background-color: $color-transparent;
        border: 1px solid $color-grey-1;
        border-radius: $border-radius-normal;
        color: $color-black;
        text-align: center;
        text-transform: uppercase;

        &--value {
            border-color: $color-black;
        }

        &:last-of-type {
            margin-right: 0;
        }

        .text {
            align-items: center;
        }
    }

    &--invalid {
        input {
            &:focus ~ * {
                &[data-char=" "] {
                    border-color: $color-error;
                }
            }
        }

        .code-input-control__char {
            border-color: $color-error;
        }
    }

    &--disabled {
        .code-input-control__char {
            color: $color-grey-2;
            border-color: $color-grey-1;
            background-color: $color-grey-bg;
        }
    }
}
