@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.header-background-image {
    --image-height: 0.3;
    @include safe-area-padding-top(calc(var(--device-height) * var(--image-height)), height);
    display: flex;
    align-items: flex-end;
    padding: $spacing-component $spacing-page-gutter;
    color: $color-white;

    .android & {
        transition: opacity 150ms ease-out;
        .text {
            transition: transform 150ms ease-out;
        }
    }
}
