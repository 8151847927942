@import "src/sharedComponents/common/variables";

.info-section {
    &:last-of-type {
        margin-bottom: 0;
    }

    &--update {
        .info-section__header,
        .info-section__details {
            padding-left: $spacing-page-gutter;
            padding-right: $spacing-page-gutter;
        }
    }

    &__header {
        z-index: 2;
        padding: $spacing-component $spacing-page-gutter;
        position: relative;
        transform: translateZ(0);

        &__title {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-component-xs;

            &__error {
                margin-right: 8.5px;

                svg {
                    height: 20px;
                    width: 20px;
                    top: -1px;
                    position: relative;
                }
            }
        }

        &__description {
            padding-top: $spacing-component $spacing-page-gutter;
            color: $color-grey-6;
        }

        &__show-details {
            color: $color-grey-6;
        }
    }

    &__details {
        padding: $spacing-component $spacing-page-gutter;
        padding-top: 0;
        position: relative;
        transform: translateZ(0);

        &.hidden {
            display: none;
        }

        &.show {
            display: block;
        }

        &__top-border {
            border-top: 1px solid $color-grey-1;
            margin: $spacing-component;
            margin-top: 0;
        }

        .divider {
            margin-top: 0;
        }
    }

    .show-on-error {
        display: none;
    }

    &.info-error {
        border: 1px solid $color-error;
        color: $color-error;

        .show-on-error {
            display: inherit;
        }
    }

    &.emphasize {
        background: $color-grey-0;
    }

    &.collapsible-animation {
        position: relative;
    }

    .collapsible-shield {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        background: white;
        height: calc(var(--collapsible-size) + 20px);
        right: -20px;
        bottom: -12px;
        left: -20px;
        z-index: 1;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        transition: transform 300ms ease-in-out;

        &__border {
            margin: -17px 20px 0 20px;
            background: white;
            position: relative;
            height: 25px;
            box-shadow: 0 4px 12px $color-shadow;
            border-radius: $border-radius-normal;

            &.reversed {
                margin: 0 20px -15px 20px;
                height: var(--collapsible-size);
            }
        }
    }
}

.collapsible-animating-down {
    position: relative;
    z-index: 2;
    background: $color-white;
    transform: translate3d(0, calc(-1 * var(--collapsible-size)), 0);
}

.collapsible-animating-up {
    position: relative;
    z-index: 2;
    background: $color-white;
    transform: translate3d(0, var(--collapsible-size), 0);
}

.collapsible-animation {
    overflow: visible;

    .collapsible-shield {
        display: flex;
    }

    &.closing .collapsible-shield {
        transform: translate3d(0, calc(-1 * var(--collapsible-size)), 0);
    }

    &.opening .collapsible-shield {
        transform: translate3d(0, var(--collapsible-size), 0);
    }
}

.collapsible-parent-animation {
    > div {
        transition: transform 300ms ease-in-out;
        position: relative;
        z-index: 2;

        &:last-child {
            .collapsible-shield__border.reverse {
                display: none;
            }
        }
    }
}

.collapsible-parent-animation-down {
    transform: translateY(calc(-1 * var(--changeable-collapsible-size)));
    transition: transform 300ms ease;
}
