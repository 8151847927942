@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal .GroupTabsWizard-modal--overlay.slide-in-modal--overlay.ReactModal__Overlay--after-open {
    transform: translateZ(10px);

    .sso-complete & {
        // temporarily position this above the SignInWizard while
        // we're animating after SignInWizard has completed
        z-index: 200;
        transform: translateZ(200px);
        background: transparent;
    }
}

.group-tab-wizard {
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;
    display: flex;
    flex-direction: column;
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".ManageMemberships-modal__Body--open");
    @include hide-when-keyboard-open(all, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SecuredPaymentWizard-modal__Body--open");

    .scroll-element {
        @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));
        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }
    }

    &__title {
        padding: 0 $spacing-page-gutter $spacing-header;
    }

    &__description {
        color: $color-grey-8;
    }

    .verify-page__title {
        padding-top: 0;
    }

    &-transition {
        &--push {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                transform: translateZ(10px);
                z-index: 1;

                .animated-child {
                    transform: translateX(100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
        }
        &--pop {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                transform: translateZ(10px);

                .animated-child {
                    transform: translateX(-100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
        }

        &-edit {
            &--push {
                &-enter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    z-index: 1;
                    transform: translate3d(0, 100vh, 10px);
                    background-color: $color-white;
                    &-active {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
            &--pop {
                &-exit {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    z-index: 1;
                    background-color: $color-white;

                    &-active {
                        transform: translate3d(0, 100vh, 10px);
                        transition: transform 250ms ease;
                    }
                }
            }
        }
    }
}
