@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.no-connection-shield {
    position: fixed;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background: white;
    opacity: 0.95;
    animation: no-connectivity-fade 350ms ease-in;
    text-align: center;
    padding: 0 20px;
    @include safe-area-padding-bottom(50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $color-grey-9;

    &__content {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 $spacing-page-gutter;

        @include svg-color($color-grey-3);
        svg {
            display: block;
            margin-bottom: 40px;
            height: 60px;
            width: 60px;
            path {
                stroke-width: 1;
            }
        }

        .text:last-child {
            color: $color-grey-5;
            margin-top: 8px;
        }
    }

    &__footer {
        .text:last-child {
            color: $color-grey-5;
            margin-top: 4px;
        }
    }
}

@keyframes no-connectivity-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.95;
    }
}
