@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.home-page {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include safe-area-padding-top(0px);

    display: flex;
    flex-direction: column;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        &__profile {
            padding: 10px;
            margin-right: -10px;
        }
    }

    &__content {
        padding: 48px 20px 20px;

        &--with-slider {
            padding-top: 8px;
        }

        .text {
            color: $color-text-inverse;
        }
    }

    .scrollable-info {
        width: 100%;
        margin-bottom: 24px;
        position: relative;

        &__section {
            box-shadow: 0 2px 8px $color-shadow;
            height: 65px;
            display: grid;
            align-items: center;
            grid-template-columns: 60px auto 50px;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                "icon header arrow"
                "icon description arrow";

            background-color: $color-white;
            border-radius: 8px;

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                grid-area: icon;
                @include svg-color($color-grey-7);

                &__red-dot {
                    width: fit-content;
                    position: relative;

                    &::before {
                        content: "";
                        width: 7.5px;
                        height: 7.5px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(30%, -70%);
                        border-radius: $border-radius-circle;
                        background-color: $color-white;
                    }
                    &::after {
                        content: "";
                        width: 5px;
                        height: 5px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(30%, -70%);
                        border-radius: $border-radius-circle;
                        background-color: $color-business;
                    }
                }
            }

            &__header {
                display: flex;
                height: 100%;
                align-items: flex-end;

                .text {
                    color: $color-text;
                }
            }

            &__description {
                display: flex;
                height: 100%;

                .text {
                    color: $color-grey-6;
                }
            }

            &__arrow {
                justify-self: center;
                grid-area: arrow;
                svg {
                    fill: $color-grey-2;
                    display: block;
                }
            }
        }

        &.is-ready {
            background-color: $color-secondary;

            .scrollable-info__section__icon {
                svg {
                    path:not(.background) {
                        fill: $color-text-inverse;
                    }

                    path.background {
                        fill: $color-secondary;
                    }
                }
            }

            .scrollable-info__section__header {
                .text {
                    color: $color-text-inverse;
                }
            }

            .scrollable-info__section__description {
                .text {
                    color: $color-text-inverse;
                }
            }

            .scrollable-info__section__arrow {
                svg {
                    fill: $color-text-inverse;
                }
            }
        }
    }

    &.focus .scrollable-info {
        z-index: 1;
    }
}
