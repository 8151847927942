@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.secured-payment-wizard {
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;
    display: flex;
    flex-direction: column;
    
    .animated-child {
        flex: 1;
    }

    &__success {
        background: $color-grey-9;
        justify-content: center;
        text-align: center;
        padding: $spacing-page-gutter;
        align-items: center;
        
        svg {
            width: 60px;
            height: 60px;
            margin-bottom: $spacing-component;

            circle {
                fill: $color-white;
            }

            path {
                fill: $color-grey-9;
            }
        }
        
        .text {
            padding: 0 $spacing-component-xs;
            color: $color-white;
        }
    }
}

.SecuredPaymentWizard-modal {
    .wizard {
        &-transition {
            &-next {
                &-enter {
                    .animated-child {
                        background: $color-grey-9;
                    }
                }
            }
        }
    }
}
