@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.wizard {
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;
    display: flex;
    flex-direction: column;

    .scroll-element {
        @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));
        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }
    }

    &__step {
        padding: 0 $spacing-page-gutter;
        color: $color-grey-8;
    }

    &-transition {
        &-next {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                transform: translateZ(10px);
                z-index: 1;

                .animated-child {
                    background-color: $color-white;
                }

                &.wizard-transition-slide-in {
                    .animated-child {
                        transform: translateX(100vw);
                    }
                }

                &.wizard-transition-slide-up {
                    transform: translate3d(0, 100vh, 10px);
                }

                &.wizard-transition-fade-in {
                    .animated-child {
                        opacity: 0;
                    }
                }

                &.wizard-transition-none {
                    position: static;
                    transform: initial;
                    z-index: 0;
                }

                &-active {
                    &.wizard-transition-slide-in {
                        .animated-child {
                            transform: initial;
                            transition: transform 250ms ease;
                        }
                    }

                    &.wizard-transition-fade-in {
                        .animated-child {
                            opacity: 1;
                            transition: opacity 250ms ease 100ms;
                        }
                    }

                    &.wizard-transition-slide-up {
                        transform: translateZ(10px);
                        transition: transform 250ms ease;
                    }
                }
            }

            &-exit {
                &.wizard-transition-fade-in {
                    .animated-child {
                        opacity: 1;
                    }
                }

                &.wizard-transition-slide-up {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    transform: translateZ(10px);
                    z-index: 1;
                }

                &-active {
                    &.wizard-transition-fade-in {
                        .animated-child {
                            opacity: 0;
                            transition: opacity 100ms ease;
                        }
                    }

                    &.wizard-transition-slide-up {
                        transform: translate3d(0, 100vh, 10px);
                        transition: transform 250ms ease;
                    }
                }
            }
        }
        &-prev {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                transform: translateZ(10px);

                .animated-child {
                    background-color: $color-white;
                }

                &.wizard-transition-slide-in {
                    .animated-child {
                        transform: translateX(-100vw);
                    }
                }

                &.wizard-transition-slide-up {
                    .animated-child {
                        transform: translateY(100vh);
                    }
                }

                &.wizard-transition-fade-in {
                    .animated-child {
                        opacity: 0;
                    }
                }

                &-active {
                    &.wizard-transition-slide-in,
                    &.wizard-transition-slide-up {
                        .animated-child {
                            transform: initial;
                            transition: transform 250ms ease;
                        }
                    }

                    &.wizard-transition-fade-in {
                        .animated-child {
                            opacity: 1;
                            transition: opacity 250ms ease 100ms;
                        }
                    }
                }
            }

            &-exit {
                &.wizard-transition-fade-in {
                    .animated-child {
                        opacity: 1;
                    }
                }

                &-active {
                    &.wizard-transition-fade-in {
                        .animated-child {
                            opacity: 0;
                            transition: opacity 100ms ease;
                        }
                    }
                }
            }
        }
    }
}

.Wizard-modal {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;
}

.ReactModalPortal .Wizard-modal__Overlay {
    overflow-x: hidden;
    &.ReactModal__Overlay {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        min-height: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &--after-open {
            transform: translateZ(10px);
        }

        &--after-open:not(.ReactModal__Overlay--before-close) {
            &.prevent-after-open-animation {
                animation: none !important; // Need this so it beats the animation class (.slide-up etc)
            }
        }

        .incorrect-document-height & {
            bottom: var(--document-height-diff);
        }
    }

    &.slide-from-right {
        &.ReactModal__Overlay {
            opacity: 1;
            @include animate-modal(slide-from-right);
        }
    }

    &.slide-from-left {
        &.ReactModal__Overlay {
            opacity: 1;
            @include animate-modal(slide-from-left);
        }
    }

    &.slide-up {
        &.ReactModal__Overlay {
            opacity: 1;
            @include animate-modal(slide-up);
        }
    }

    &.slide-down {
        &.ReactModal__Overlay {
            opacity: 1;
            @include animate-modal(slide-down);
        }
    }

    &.fade-in {
        &.ReactModal__Overlay {
            @include animate-modal(fade-in);
        }
    }

    &.no-animation {
        &.ReactModal__Overlay {
            &--after-open {
                animation: none;
            }
            &--before-close {
                animation: none;
            }
        }
    }
}
