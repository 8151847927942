@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.call-to-action-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-white;
    padding: $spacing-component $spacing-page-gutter;
    text-align: center;

    &__icon {
        margin-bottom: $double-spacing-component;

        @include svg-color($color-grey-5);
        svg {
            width: 48px;
            height: 48px;
        }
    }

    &__title {
        color: $color-grey-5;
    }

    &__subtitle {
        color: $color-grey-4;
    }

    &__button {
        margin-top: $spacing-component-small;
    }
}
