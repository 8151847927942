@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.view-offer-modal--overlay {
    z-index: 100 !important;
    &.ReactModal__Overlay--after-open {
        transform: translateZ(10px);
    }
}

.view-offer-page {
    width: 100%;
    height: 100%;
    position: absolute;

    &__bottom-section {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $spacing-component;
        height: min-content;
        box-shadow: 4px 4px 20px $color-shadow;
        background-color: $color-white;
        @include safe-area-padding-bottom($spacing-component);
    }
}
