@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/styles/mixins/mixins";
@import "src/sharedComponents/common/mixins";

@mixin animate-in($idx: 1, $initial-delay: 1s) {
    animation: statusText 200ms ease-out backwards;
    animation-delay: 200ms * ($idx - 1) + $initial-delay;
}

.join-table {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color-kale;
    @include hide-when-keyboard-open(all, ".ReactModal__Body--open");
    @include hide-when-keyboard-open(all, ".AccountDetails-modal__Body--open");
    @include hide-when-keyboard-open(all, ".Marketing-modal__Body--open");

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
        height: calc(100% - var(--document-height-diff));
    }

    &.with-hero {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--background-image);
        background-size: cover;
        background-position: bottom;
    }

    &.home-page-business-background {
        .home-page {
            background-color: $color-kale;
        }
    }

    &.FindingQrCode:not(.camera-not-ready) {
        background: transparent;
    }

    &.dark-background {
        background-color: $color-grey-9;
    }

    @include safe-area-padding-top(0px);

    &__top {
        display: flex;
        flex-direction: column;
        flex: 1;
        &__header {
            padding: 30px 0 50px;
            background: linear-gradient(180deg, #ffffff 64.59%, rgba(255, 255, 255, 0) 103.45%);
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 20px;
        @include safe-area-padding-bottom(0px);

        &__button {
            transform: translateZ(0);

            &:last-of-type {
                margin-bottom: 20px;
            }

            &.inverse {
                color: $color-business;
                background-color: transparent;
            }

            &.cancel {
                border: 1px solid $color-business;
            }

            .with-hero & {
                --button-color: #{$color-black};
            }

            &.secondary {
                --button-color: #{$color-white};
            }
        }
    }

    &.gradient {
        background: linear-gradient(to bottom right, #b7c1d5, #919fba) !important;
    }

    .locations-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-top: constant(safe-area-inset-top); //for iOS 11.1
        margin-top: env(safe-area-inset-top); //for iOS 11.2

        img {
            width: 26px;
            height: 26px;
        }
    }

    @mixin join-table-step($text-height: 0, $offset: 0) {
        position: absolute;
        width: 100%;
        height: 245px + $text-height + $offset;
        left: 0;
        top: calc(35% - #{$offset * 1px});
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-weight: $font-weight-medium;
        font-size: get-vw(18px);
        line-height: 24px;
        text-align: center;
    }

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 100ms;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 100ms ease-out;
            }
        }
    }

    .fade-buttons {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in 100ms;
                position: absolute;
                width: calc(100% - 40px);
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 100ms ease-out;
                position: absolute;
                width: calc(100% - 40px);
            }
        }
    }

    .connecting {
        @include join-table-step(25, 61);
        justify-content: center;
        height: 100%;
        position: fixed;
        top: 0;

        &__header {
            position: fixed;
            top: 0;
            @include safe-area-padding-top(24px);
            svg,
            path {
                max-height: 100px;
                width: 180px;
                height: 100%;
            }
            @include svg-color($color-white);
        }
        &__container {
            .status-text {
                color: $color-text-inverse;
                display: flex;
                justify-content: center;
            }
            .animated-svg {
                position: relative;
                left: -10px;
                margin: -45px auto !important;
            }
        }

        &__footer {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            @include safe-area-padding-bottom(32px);
        }

        .branded-venue-content-container {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;

            @include svg-color($color-text-inverse);

            .venue-logo-container {
                --image-height: 0.3;
                @include safe-area-padding-top(calc(var(--device-height) * var(--image-height)), height);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: $spacing-component $spacing-page-gutter;

                svg {
                    max-width: 100%;
                    max-height: 40%;
                    min-width: 180px;
                    height: 100%;
                }
            }
        }
    }

    .status-text {
        @include animate-in();
    }

    .request-permissions {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 20;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.75), 80%, rgba(0, 0, 0, 1));
        @include safe-area-padding-top(0px);

        &__icons {
            display: flex;
            width: 60px;
            justify-content: space-between;
            margin: 36px 24px 0;

            span {
                background-color: $color-primary;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }

        h2 {
            font-weight: $font-weight-semibold;
            font-size: get-vw($font-size-xl);
            line-height: 29px;
            color: white;
            text-align: left;
            margin: 20px 24px 0;
            width: 290px;
        }

        p {
            margin: 24px;
            width: 240px;
            font-weight: $font-weight-medium;
            font-size: get-vw($font-size-sm);
            line-height: 17px;
            color: white;
        }

        button {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 14px;
            width: calc(100% - 28px);
            background: transparent;
            border: 3px solid white;
            border-radius: 5px;
        }

        .permission {
            margin: 58px 30px 0;
            display: flex;

            &-icon {
                padding-top: 8px;
                width: 46px;
            }

            &-info {
                width: 244px;

                h3 {
                    font-weight: $font-weight-semibold;
                    font-size: get-vw(18px);
                    line-height: 32px;
                    color: $color-business;
                }

                h4 {
                    font-weight: $font-weight-semibold;
                    font-size: get-vw($font-size-sm);
                    line-height: 25px;
                    color: $color-business;
                }

                p,
                a {
                    font-weight: normal;
                    font-size: get-vw(11px);
                    line-height: 16px;
                    color: #7c7c7c;
                    margin: 0;
                }
            }

            &-privacy {
                flex: 1;
                display: flex;
                align-items: center;

                .permission {
                    margin-top: 0;

                    p {
                        font-size: get-vw(10px);
                        line-height: 15px;
                    }
                }
            }
        }

        &-page {
            background: #fff;
            display: flex;
            flex-direction: column;
            padding-bottom: 83px;

            h2 {
                color: $color-business;
            }

            button {
                background: $color-business;
                border-color: $color-business;
            }
        }
    }
}

.shield {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

@keyframes statusText {
    from {
        transform: translateY(20%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#reader {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-business;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // Don't want to see the join table page behind with the overscroll
    @include when-keyboard-open(all, ".ReactModal__Body--open") {
        background-color: $color-white;
    }
    @include when-keyboard-open(all, ".Marketing-modal__Body--open") {
        background-color: $color-white;
    }
    @include when-keyboard-open(all, ".AccountDetails-modal__Body--open") {
        background-color: $color-white;
    }
}

.splash-screen {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    @include safe-area-padding-bottom(0px);
    @include safe-area-padding-top(0px);

    &.dark-background {
        background-color: $color-grey-9;
    }
}
