@import "src/sharedComponents/common/variables";
@import "src/styles/variables/variables";

.feedback-list {
    transition: max-height 200ms ease;

    &.expanded {
        max-height: var(--tags-height);
    }

    &__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin-top: $spacing-component;
        min-height: var(--tags-height);
        overflow: hidden;
    }

    &__button {
        display: inline-block;
        width: auto;
        margin: 0 8px 8px 0;
        padding: 0 12px;
        min-height: 40px;
        height: auto;
        font-size: get-vw($font-size-xs);

        &.mode-outline {
            border: 1px solid $color-grey-1;
            color: $color-grey-8;
        }
    }
}
