@import "../../common/variables";

.expandable-list {
    transition: max-height 200ms ease;
    overflow: hidden;

    &.expanding {
        max-height: var(--list-height);
    }

    &.expanded {
        max-height: initial;
        overflow: initial;
    }

    &__list {
        min-height: var(--list-height);
        overflow: hidden;
        list-style: none;
        display: flex;
        flex-direction: column;

        .expandable-list.expanded & {
            min-height: initial;
            overflow: initial;
        }
    }

    &__show-more {
        color: $color-grey-5;
        padding-top: $spacing-component-small;
        padding-left: $spacing-page-gutter;
        cursor: pointer;
    }
}
