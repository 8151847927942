@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.venue-cause {
    .prompt__icon {
        @include svg-color($color-business);
    }

    &__options {
        display: flex;
        margin-top: $spacing-component-xxs;
        flex-wrap: wrap;
        
        > .chip {
            margin-right: $spacing-component-xs;
            padding: 0;
            padding-top: $spacing-component-xxs
        }
    }
}