@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.profile-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    @include hide-scroll-bar;

    .scroll-element {
        @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));

        .incorrect-document-height & {
            @include safe-area-padding-bottom(
                calc(var(--document-height-diff) + #{$button-height + (2 * $spacing-page-gutter)})
            );
        }

        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }

        &.no-submit {
            @include safe-area-padding-bottom($spacing-page-gutter);

            .incorrect-document-height & {
                @include safe-area-padding-bottom(calc(var(--document-height-diff) + #{$spacing-page-gutter}));
            }

            @include when-keyboard-open {
                padding-bottom: $spacing-page-gutter !important;
            }
        }

        .party-wrapper & {
            @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter) + $bottom-nav-bar);

            .incorrect-document-height & {
                @include safe-area-padding-bottom(
                    calc(var(--document-height-diff) + #{$button-height + (2 * $spacing-page-gutter) + $bottom-nav-bar})
                );
            }

            &.no-submit {
                @include safe-area-padding-bottom($spacing-page-gutter + $bottom-nav-bar);

                .incorrect-document-height & {
                    @include safe-area-padding-bottom(
                        calc(var(--document-height-diff) + #{$spacing-page-gutter + $bottom-nav-bar})
                    );
                }
            }
        }
    }

    .profile-page__title {
        padding-top: $spacing-header;
    }

    .profile-page__submit {
        .account-details__submit__wrapper,
        .edit-food-preferences__submit__wrapper {
            .party-wrapper & {
                @include safe-area-padding-bottom($spacing-page-gutter + $bottom-nav-bar);

                .incorrect-document-height & {
                    @include safe-area-padding-bottom(
                        calc(var(--document-height-diff) + #{$spacing-page-gutter + $bottom-nav-bar})
                    );
                }

                @include when-keyboard-open {
                    padding-bottom: $spacing-page-gutter !important;
                    box-shadow: none;
                }
            }
        }
    }
}
