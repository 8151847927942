@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal .ReactModal__Overlay.cart-view-preview-modal--overlay {
    overflow: scroll;
    @include hide-scroll-bar;

    &.ReactModal__Overlay {
        background-color: transparent;
        pointer-events: all;
        transform: translateZ(10px);
        animation: shield-color 450ms ease-in-out;
        opacity: 1;
        .ios & {
            animation-name: ios-shield-color;
        }

        .cart-view-preview {
            transform: translate3d(0, 100vh, 10px);
            animation: preview-bounce 450ms ease-out;
        }

        &--after-open {
            background-color: add-opacity($color-grey-9, 0.8);
            .cart-view-preview {
                transform: translateY(45vh);
            }
        }

        &--before-close {
            opacity: 1;
            transition: background-color 250ms ease-in-out;
            background-color: transparent;
            .cart-view-preview {
                transform: translateY(100vh);
                transform: translateY(unquote("max(100vh, 100%)"));
                transition: transform 250ms ease-in-out;
            }
        }
    }

    @keyframes shield-color {
        0% {
            background-color: add-opacity($color-grey-9, 0.1);
        }
        50% {
            background-color: add-opacity($color-grey-9, 0.75);
        }
        100% {
            background-color: add-opacity($color-grey-9, 0.8);
        }
    }

    @keyframes ios-shield-color {
        0% {
            background-color: add-opacity($color-grey-9, 0.1);
        }
        50% {
            background-color: add-opacity($color-grey-9, 0.52);
        }
        100% {
            background-color: add-opacity($color-grey-9, 0.56);
        }
    }

    @keyframes preview-bounce {
        0% {
            transform: translateY(100vh);
        }
        50% {
            transform: translateY(40vh);
        }
        100% {
            transform: translateY(45vh);
        }
    }

    .cart-view-preview-modal {
        min-height: 100%;
        .cart-view-preview {
            position: relative;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            width: 100vw;
            min-height: calc(100% + 10px);
            padding-top: 16px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            .close-circle {
                position: absolute;
                padding: 46px 22px;
                right: 0;
                @include safe-area-padding-top(0px, top);

                svg {
                    width: 14px;
                    height: 100%;
                }

                @include svg-color($color-black);
            }
        }
    }
}
