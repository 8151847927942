@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.taxes-detail {
    &__total {
        display: flex;
        justify-content: space-between;
        color: $color-grey-7;
        margin-bottom: $spacing-component-small;
        align-items: center;

        &__title {
            display: flex;

            svg {
                margin-left: $spacing-component-xxs;
                width: 16px;
                height: 16px;
            }
        }
    }

    &__data {
        max-height: 0;
        transition: max-height 200ms ease;
        overflow: hidden;
        background: $color-grey-bg;
        margin: $spacing-component-small -#{$spacing-page-gutter} 0;
        padding: 0 $spacing-page-gutter;

        &.show-taxes {
            max-height: var(--taxes-height);
            margin-bottom: $spacing-component-small;
        }
    }

    &__items {
        min-height: var(--taxes-height);
        padding: $spacing-component-small 0;
    }

    &__item-row {
        display: flex;
        justify-content: space-between;
        margin-top: $spacing-component-small;
        color: $color-grey-5;

        &:first-of-type {
            margin-top: 0;
        }
    }
}
