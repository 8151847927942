@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.cart-view {
    min-height: 100%;

    .scroll-root & {
        overflow-y: scroll;
        @include hide-scroll-bar;
    }

    &__header {
        padding: $spacing-header $spacing-page-gutter;

        &__subtitle {
            color: $color-grey-8;
        }

        &__section {
            display: flex;
            justify-content: space-between;

        }

        &__takeaway-link {
            color: $color-primary;
        }
    }

    &__content {
        flex: 1;
        background-color: $color-white;
        @include safe-area-padding-bottom($spacing-page-gutter + $button-height + $spacing-header);

        &.no-items {
            background: $color-white;
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &__items {
            background: $color-white;

            &__header {
                padding: $spacing-component-small 0;
                margin: 0 $spacing-page-gutter $spacing-component-xs / 2;
                background-color: $color-grey-bg;
                color: $color-grey-4;
                text-align: center;
                border-radius: $border-radius-normal;
            }

            .force-hide {
                animation: slideItemsUpForce 0.1s ease-in forwards;
                overflow: hidden;
            }
        }

        &__total {
            color: $color-grey-7;
            display: flex;
            justify-content: space-between;
            padding: $spacing-component-small $spacing-page-gutter;

            &__price-wrapper {
                display: inline-flex;
                flex-direction: row-reverse;
            }

            &__base-price {
                color: $color-grey-3;
                margin-right: 4px;
            }
        }

        &__disclosure {
            color: $color-grey-5;
            padding: 0 $spacing-page-gutter;
        }

        &__button {
            margin-top: $spacing-header;
            padding-bottom: $spacing-header;

            svg {
                max-height: 19px;
                margin-right: $spacing-component-xxs;
            }
        }

        .membership-cta {
            margin: 0 20px 16px 20px;
        }

        .payment-item {
            margin-bottom: 0;
            padding: 0 20px;
        }
    }
}
@keyframes slideItemsUpForce {
    0% {
        // default height is the expect, "FOOD" title height
        max-height: var(--order-item-height, 42px);
    }
    100% {
        max-height: 0;
        padding: 0;
        margin-bottom: 0;
    }
}
