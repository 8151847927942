@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.payment-method {
    min-height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .text {
        color: $color-grey-8;
    }

    &.selected {
        background: $color-grey-bg;
    }

    &__left {
        margin-right: $spacing-component;
        svg {
            display: block;
        }
    }

    &__center {
        flex: 1;
        color: inherit;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__right {
        margin-left: $spacing-component;
        display: flex;
        align-items: center;
        svg {
            margin-right: $spacing-component-xs;
            display: block;
        }
    }

    &__add-card {
        overflow: hidden;
        position: absolute;
        left: -9999px;
        opacity: 0;

        &-inner {
            margin: $double-spacing-component $spacing-component;
            transform: translateY(-100%);
            transition: transform 0.3s ease-in-out;
        }

        &.show {
            position: static;
            opacity: 1;
            animation: card-entry-show 0.3s ease-in-out forwards;

            .payment-method__add-card-inner {
                transform: translateY(0);
            }
        }

        &.hide {
            animation: card-entry-hide 0.3s ease-in-out forwards;

            .payment-method__add-card-inner {
                transform: translateY(-100%);
            }
        }

        &__fields {
            padding: $double-spacing-component $spacing-page-gutter;
            background: $color-grey-bg;
            border-radius: $border-radius-normal;

            svg {
                display: inline-block;
                margin-right: $spacing-component-xs;
                flex-shrink: 0;
            }
        }

        &__field {
            height: calc((#{$font-category} * #{$line-height-default}) + #{$spacing-component});
            border-bottom: 1px solid $color-grey-1;

            .payment-method__add-spreedly-card & {
                padding-top: $spacing-component-xs;
            }

            iframe {
                width: 100% !important;
                height: 100% !important;
            }

            input {
                font-size: $font-category;
                font-weight: $font-weight-normal;
                font-family: sans-serif;
                color: $color-grey-8;
                width: 100%;
                padding: 0;
                margin: 0;
                border: 0;
                background-color: transparent;
                box-sizing: border-box;

                &.invalid {
                    color: $color-error;
                }

                .payment-method__add-tyro-card & {
                    height: calc((#{$font-category} * #{$line-height-default}) + #{$spacing-component});
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .payment-method__add-spreedly-card & {
                    height: 1.2em;
                    line-height: 1.2em;
                }
            }

            .payment-method__add-stripe-card & {
                padding-top: $spacing-component-xs;

                > div {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            &-expiry {
                display: flex;
            }

            &-expiry-month {
                width: 30px;
            }

            &-expiry-year {
                width: 60px;
            }
        }

        &__field-wrapper {
            &.readonly {
                .payment-method__add-card__field {
                    padding-top: $spacing-component-xs;

                    &.payment-method__add-card__field-expiry {
                        display: block;
                    }

                    .text {
                        line-height: $font-category;
                    }
                }

                .text {
                    color: $color-grey-4;
                }
            }
        }

        &__date-separator {
            height: calc((#{$font-category} * #{$line-height-default}) + #{$spacing-component});
            line-height: calc((#{$font-category} * #{$line-height-default}) + #{$spacing-component});
            margin: 0 $spacing-component-xxs;
            color: $color-grey-8;
            position: relative;
            top: 1px;

            &.empty {
                color: $color-grey-4;
            }
        }

        &__number {
            margin-bottom: $spacing-component-xxs;
        }

        &__expiry-cvv {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 #{-$spacing-component-small / 2} $spacing-component-xs;

            small {
                flex: 0 0 100%;
            }

            .payment-method__add-card__field-wrapper {
                width: 50%;
                padding: 0 $spacing-component-small / 2;
            }
        }

        &__remember-me {
            margin-bottom: $double-spacing-component;
        }

        &__security {
            color: $color-grey-5;
            display: flex;
            align-items: center;

            &.with-charge {
                align-items: flex-start;
            }
        }

        .button-element {
            margin-top: $spacing-component-small;
        }

        small {
            display: flex;
            align-items: center;
            min-height: $double-spacing-component;
            color: $color-error;
        }
    }
}

@keyframes card-entry-show {
    from {
        max-height: 0;
    }
    to {
        max-height: var(--device-height);
    }
}

@keyframes card-entry-hide {
    from {
        max-height: var(--device-height);
    }
    to {
        max-height: 0;
    }
}
