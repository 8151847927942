@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.promo-code-details {
    display: flex;
    align-items: center;
    padding: $spacing-component-xs $spacing-page-gutter;

    &__icon {
        @include svg-color($color-grey-5);
        height: 20px;
        margin-right: $spacing-component-small;

        > svg {
            width: 20px;
            height: 20px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        color: $color-grey-7;
        flex: 1;

        &--description {
            color: $color-grey-5;
            display: flex;
            align-items: center;

            &.warning {
                color: $color-warning;
                > svg {
                    height: 14px;
                    width: 14px;
                    margin-right: $spacing-component-xs;
                }
            }
        }
    }

    &__remove {
        height: 20px;
        @include svg-color($color-grey-8);

        > svg {
            width: 20px;
            height: 20px;
        }

        &.disabled {
            @include svg-color($color-grey-2);
        }
    }
}
