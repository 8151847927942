@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.tagbuttongroup--container {
    display: flex;
    flex-wrap: wrap;
}

.tagbuttongroup--item {
    margin: 10px 10px 0 0;

    @include svg-circle-color(false);
    .mode-solid {
        @include svg-circle-color(true);
    }
}

.tagbuttongroup--button {
    padding: 0 16px;
    height: 44px;
    min-height: 44px;

    svg {
        width: 20px;
        height: 20px;
    }

    &.selected {
        color: $color-text-inverse;
    }
}
