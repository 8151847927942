@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/styles/mixins/mixins";
@import "src/sharedComponents/styles/animations/animations";

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

html {
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    font-size: 100%;
}

html,
body {
    overscroll-behavior-y: contain;
}

body {
    font-family: "Manrope", sans-serif;
    font-size: get-vw($font-size-base);
    line-height: 1.25;
    background-color: #fff;
    color: #000;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

a {
    text-decoration: underline;
    display: inline-block;
    color: white;
    cursor: pointer;

    &.button {
        border: none;
        text-decoration: none;
    }
}

p {
    &:not(:first-child) {
        margin-top: 20px;
    }
}

h1,
.h1 {
    color: $color-text;
    font-size: get-vw(34px);
    font-weight: $font-weight-medium;
}

h2 {
    color: $color-text;
    margin: 5px;
    font-size: get-vw($font-size-base);
    font-weight: $font-weight-medium;
    text-align: center;
}

h3 {
    color: black;
    font-size: get-vw($font-size-base);
    font-weight: $font-weight-medium;
    line-height: 1.5;
}

h4 {
    font-size: get-vw(22px);
    font-weight: $font-weight-medium;
}

input {
    width: 100%;
    margin-top: 8px;
    border-color: $color-border;
    border-style: solid;
    font-size: get-vw($font-size-base);
    padding: 14px;
    border-width: 1.5px;
}

.input {
    width: 100%;
    margin-top: 10px;
    border-color: $color-border;
    border-style: solid;
    font-size: get-vw($font-size-base);
    padding: 10px 14px;
    border-width: 1.5px;
    height: 50px;
    display: flex;
    align-items: center;

    img {
        width: 35px;
        margin-right: 15px;
    }
}

textarea {
    width: 100%;
    border-color: $color-border;
    border-style: solid;
    font-size: get-vw($font-size-base);
    padding: 14px;
}

button {
    font-family: "Manrope", sans-serif;
    font-weight: $font-weight-medium;
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 50px;
    background-color: $color-primary;
    color: $color-light;
    width: 100%;
    font-size: get-vw(13px);
    border: 0;
    @include flex-center("landscape");
    svg {
        position: relative;
        top: 3px;
        margin-left: 5px;
    }
    &.inverse {
        background-color: transparent;
        color: $color-primary;
    }
    &.secondary-button {
        background-color: transparent;
        color: $color-primary;
    }
}

.action-center-button {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    button {
        padding: 0 25px;
        border: 1px solid white;
        width: initial;
        background: transparent;

        &.fade {
            &-enter {
                opacity: 0;

                &-active {
                    opacity: 1;
                    transition: opacity 200ms ease-in 100ms;
                }
            }

            &-exit {
                opacity: 1;
                &-active {
                    opacity: 0;
                    transition: opacity 200ms ease-out;
                }
            }
        }
    }
}

button,
input,
textarea,
.button,
a,
.Select-arrow-zone {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

figcaption {
    margin-top: 5px;
}

ul {
    li {
        padding-left: 4px;
        margin-left: 20px;
        padding-top: 15px;
    }
}

b,
strong {
    -webkit-font-smoothing: auto;
}

hr {
    border: 0.5px solid $color-inactive;
}

.endless-spin {
    animation: endless-spin 1500ms linear infinite;
}
