@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.package-preview {
    &__modal-title.group-tab-wizard__title {
        padding: $spacing-header $spacing-page-gutter;
    }

    &__availability-message {
        border-radius: $border-radius-normal;
        background: $color-grey-0;
        color: $color-grey-5;
        padding: $spacing-component-xs $spacing-component;
        margin: 0 $spacing-page-gutter $spacing-component;
    }

    footer {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
    }
}
