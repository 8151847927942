@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.tab-members-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: $color-grey-7;
    @include safe-area-padding-bottom($button-height * 2 + (3 * $spacing-page-gutter));
    overflow-y: scroll;
    @include hide-scroll-bar;

    &__title {
        color: $color-black;
        padding: $spacing-header $spacing-page-gutter;

        &--description {
            color: $color-grey-7;
        }
    }

    &__members {
        padding: 0 $spacing-page-gutter;

        &__member {
            display: flex;
            align-items: center;
            padding: $spacing-component-xs 0;
            @include svg-color($color-grey-6);
    
            &__left {
                padding-right: $spacing-component-small;
            }
    
            padding: 0;

            svg {
                width: 24px;
                height: 24px;
            }
            .member-initials {
                flex-shrink: 0;
                margin-right: $spacing-component;
            }
        }

        &__name {
            flex: auto;
            overflow-wrap: break-word;
            margin-right: $spacing-component;
            align-items: center;
            @include overflow-ellipsis-multiline(2);
            @include svg-color($color-grey-7);

            
        }

    }
    
    &__empty {
        margin-top: 3 * $double-spacing-component;

        .button-element.mode-outline {
            --button-color: #{$color-grey-2};
            color: $color-grey-9;
        }
    }

    footer {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        @include when-keyboard-open {
            position: relative;
        }
    }
}
