@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.prompt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-component;
    border-radius: $border-radius-normal;

    &--default {
        background-color: $color-black;
        color: $color-white;

        .prompt__icon {
            @include svg-color($color-white);
        }

        .prompt__title {
            @include svg-color($color-white);
        }
    }

    &--warning {
        background-color: $color-error-light;
        color: $color-error;

        .prompt__icon {
            @include svg-color($color-error);
        }

        .prompt__title {
            @include svg-color($color-error);
        }
    }

    &--light {
        background-color: $color-grey-bg;

        .prompt__icon {
            @include svg-color($color-black);
        }

        .prompt__title {
            @include svg-color($color-black);
        }
    }

    &--themed {
        background-color: $color-primary-light;
        color: $color-primary;

        .prompt__icon {
            @include svg-color($color-primary);
        }

        .prompt__title {
            @include svg-color($color-primary);
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__icon {
        display: flex;

        &--left {
            margin-right: $spacing-component-small;

            svg {
                width: 32px;
                height: 100%;
            }
        }

        &--right {
            margin-left: $spacing-component-small;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;

        &__icon {
            display: flex;

            &--left {
                margin-right: $spacing-component-xs;
            }

            &--right {
                margin-left: $spacing-component-xs;
            }
        }
    }
}
