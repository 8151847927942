@import "src/styles/variables/variables";

.toastifiedMessage {
    display: flex;
    align-items: center;
    margin: 0 10px;
    border-radius: 5px !important;
    margin-top: 8px;
    box-shadow: 0 1px 4px rgba(85, 85, 85, 0.5);
    min-height: 50px !important;
    padding: 0 20px 0 10px !important;
    &:first-child {
        margin-top: 60px;
        margin-top: calc(60px + constant(safe-area-inset-top)); //for iOS 11.1
        margin-top: calc(60px + env(safe-area-inset-top)); //for iOS 11.2
    }
    .Toastify__close-button {
        width: 10px;
        height: 10px;
        align-self: center;
        margin-bottom: 11px;
    }
    &__body {
        display: flex;
        &__icon {
            margin-right: 10px;
            display: flex;
            align-items: center;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            color: #555555;
            &__title {
                font-size: get-vw($font-size-sm);
                font-weight: $font-weight-semibold;
            }
            &__description {
                font-size: get-vw(11px);
                opacity: 0.7;
            }
        }
    }
}
