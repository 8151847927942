@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.tab-overview-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $spacing-component;
    @include safe-area-padding-top($spacing-component, margin-top);
    border-radius: $border-radius-large;

    &.header-background-image {
        height: 160px;
        align-items: center;
        padding: $spacing-component-xxs $spacing-page-gutter;
    }

    .GroupTabOverview-modal & {
        margin-top: $spacing-component;
    }

    .text {
        @include overflow-ellipsis-multiline(2);
        text-align: center;
    }

    &__tab-name {
        margin: $spacing-component-xs 0 $spacing-component-xs;
    }

    &__spend {
        animation: fade-in 200ms ease;
    }

    &__chip {
        color: $color-white;
        border: 1px solid $color-white;
        border-radius: $border-radius-normal;
        padding: $spacing-component-xxs $spacing-component-xs;
    }
}
