.react-tabs__tab--selected {
    font-weight: bold;
}

.react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
}

.react-tabs__tab {
    padding: 10px;
}
