@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModal__OrderSaving.ReactModal__Overlay {
    display: flex;
    align-items: flex-end;
    z-index: 10;

    .order-saving-modal {
        width: 100%;

        @include animate-modal(slide-up, 150ms, ".order-saving-modal__content");
        &--before-close {
            @include animate-modal(down-up, 150ms);
        }

        &__content {
            width: 100%;
            background-color: $color-white;
            padding: $double-spacing-component $spacing-page-gutter;
            @include safe-area-padding-bottom($double-spacing-component);
            border-radius: $border-radius-normal $border-radius-normal 0px 0px;
        }

        &__title {
            padding-bottom: $spacing-header;
        }

        &__primary-action {
            margin-bottom: $spacing-component;
        }

        &__close {
            margin-top: -$spacing-page-gutter;
            margin-left: -$spacing-page-gutter;
            margin-right: -$spacing-page-gutter;
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: $double-spacing-component;
            color: $color-grey-7;

            &__image {
                margin-bottom: $spacing-page-gutter;
                width: 90px;
                height: 60px;
            }

            &__title {
                white-space: pre;
                color: $color-grey-9;
                margin-bottom: $spacing-component-xxs;
            }

            &__icon {
                display: flex;
                margin-bottom: $spacing-component;
                svg {
                    width: 58px;
                    height: 58px;
                }
            }

            &__generic-icon {
                display: flex;
                margin-bottom: $spacing-component;
                svg {
                    width: 40px;
                    height: 40px;
                }
                background-color: $color-primary;
                border-radius: $border-radius-normal;
                padding: $spacing-component-xs $double-spacing-component;
                @include svg-color(var(--theme-button-text-color));
            }

            &__savings-text {
                text-align: center;
            }

            &__below-minimum {
                text-align: center;
                margin-top: $spacing-component-small;
            }
        }

        &__button {
            margin-top: $spacing-header;
        }

        .payment-detail {
            padding: $spacing-component-small 0;
        }
    }
}
