@import "../../common/mixins";
@import "../../common/variables";

$textarea-size-md: 54px;
$textarea-size-lg: 66px;

.textarea-control {
    display: flex;
    flex: 1;
    position: relative;
    padding: 0 $spacing-component-small;
    border: 1px solid $color-grey-2;
    border-radius: $border-radius-normal;
    cursor: text;

    .label--floating {
        top: 7px;
    }

    &--md {
        min-height: $textarea-size-md;

        textarea,
        .textarea-control__textarea::after {
            font-size: $font-category;
        }

        label {
            top: calc((#{$textarea-size-md} - ((#{$line-height-default} * 1em) + 2px)) / 2);
        }
    }

    &--lg {
        min-height: $textarea-size-lg;

        textarea,
        .textarea-control__textarea::after {
            font-size: $font-msg-title;
        }

        label {
            top: calc((#{$textarea-size-lg} - ((#{$line-height-default} * 1em) + 2px)) / 2);
        }
    }

    &__textarea {
        width: 100%;
        display: grid;
        overflow-x: auto;
        padding: 26px 0 0 0;

        &::after {
            content: attr(data-replicated-value) " ";
            white-space: pre-wrap;
            visibility: hidden;
        }

        textarea {
            width: 100%;
            flex: 1 1 0%;
            color: $color-grey-9;
            border: 0;
            margin: 0;
            padding: 0;
            resize: none;
            overflow: hidden;
        }

        textarea::placeholder {
            opacity: 0;
            transition: all 0.1s ease-in-out;
            color: $color-grey-5;
        }

        textarea:focus,
        textarea:not(:placeholder-shown) {
            opacity: 1;
        }

        textarea:focus::placeholder {
            opacity: 1;
        }

        textarea,
        &::after {
            grid-area: 1 / 1 / 2 / 2;
            padding-bottom: 6px;
            font-weight: $font-weight-medium;
            font-family: $font-family-main;
        }
    }

    &::before {
        box-sizing: border-box;
        content: "";
        display: block;
        position: absolute;
        pointer-events: none;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: inherit;
    }

    &:focus-within::before {
        border: 1px solid $color-black;
    }

    &:hover {
        border-color: $color-black;
    }

    label {
        position: absolute;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        line-height: $line-height-default;
        font-size: $font-category;
        color: $color-grey-7;
        cursor: text;
    }

    &--invalid {
        border: 1px solid $color-error;

        &:focus-within::before {
            border: 1px solid $color-error;
        }

        &:hover {
            border-color: $color-error;
        }

        label {
            color: $color-error;
        }
    }

    &--disabled {
        border: 1px solid $color-grey-1;

        &:hover {
            border-color: $color-grey-1;
        }

        label {
            color: $color-grey-3;
        }

        textarea {
            color: $color-grey-3;
            background: $color-white;
        }
    }
}
