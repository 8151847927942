@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.engage-offer {
    position: relative;
    &__discount {
        display: flex;
        align-items: center;
        padding: $spacing-component-xs $spacing-page-gutter;

        .loading-text {
            width: 50%;
            margin-left: $spacing-component;
        }

        &__icon {
            display: flex;
            @include svg-color($color-grey-5);
            svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
            }

            &.highlight {
                @include svg-color($color-primary);
            }
        }

        &__text {
            color: $color-grey-7;
            margin: 0 $spacing-component;
            flex: 1;
            &__subtitle {
                color: $color-grey-5;
            }
            &__toggle {
                color: $color-primary;
            }
        }

        &__loading-spinner {
            width: 58px;
            height: 58px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 1px);
            transition: opacity 100ms ease-in-out;
            border-radius: $border-radius-circle;
            background-color: $color-white;
            box-shadow: 4px 4px 20px $color-shadow;
            padding: $spacing-component-xxs;
        }
    }
    &__price {
        color: $color-grey-7;
        padding: $spacing-component-small $spacing-page-gutter;
        display: flex;
        flex-direction: column;
        gap: $spacing-component-small;

        &__text {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
