@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.simpleNavHeader__height {
    @include safe-area-padding-top($simple-nav-header-height, min-height);
    width: 100%;

    // Hide the spacer when the keyboard is open
    &:not(.simpleNavHeader__wrapper) {
        @include hide-when-keyboard-open;
    }
}

.simpleNavHeader__wrapper {
    display: flex;
    background-color: white;
    color: black;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    justify-content: space-between;
    padding-right: 20px;
    @include safe-area-padding-top(0px);
    transform: translateZ(1px);

    @include when-keyboard-open {
        position: relative;
    }

    &.with-right {
        padding-right: 0;
    }

    &__left {
        display: flex;
        justify-content: flex-start;
        padding-right: 5px;
        min-height: 50%;
        &--shifted {
            width: 100%;
        }
        &--space {
            padding-left: 20px;
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        position: relative;
        height: 100%;

        &--shifted {
            width: 0;
        }
    }

    &--transparent {
        background-color: transparent;
    }

    &.no-background {
        background-color: transparent;
    }

    &.has-back-button {
        .simpleNavHeader__wrapper__left {
            .profile-icon__wrapper {
                margin-left: 10px;
            }
        }
    }
    &.with-border {
        border-bottom: 1px solid $color-grey-0;
    }
    &.circle-close {
        background: transparent;
        .button--back {
            &__wrapper {
                padding-bottom: 0;
                padding-top: 12px;
                padding-right: 12px;
            }
            border-radius: 50%;
            background: $color-white;
            height: 39px;
            width: 39px;
            box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.17);
        }
    }
}

.simpleNavHeader__title {
    display: flex;
    align-items: center;
}
