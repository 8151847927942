@import "src/styles/variables/variables";
@import "src/styles/mixins/mixins";
@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.nav-bar-height {
    @include safe-area-padding-bottom($bottom-nav-bar, height);
    // Decided to hide the navbar when the keyboard is open as it doesn't
    // display after the content if it's set to position static
    @include hide-when-keyboard-open;
}

.nav-bar {
    background-color: $color-background;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translateZ(1px);

    &__elements {
        @include flex-center("landscape");
        background-color: $color-background;
        padding: 0 15vw;
    }

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
    }

    &__wrapper {
        height: 100%;
        width: 100%;
        background: white;
    }

    &__shield {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include safe-area-padding-bottom(0px);
        background-color: transparent;
    }

    &__element {
        @include flex-center("portrait");
        height: 100%;
        flex: 1;
        @include safe-area-padding-bottom(0);
        justify-content: flex-start;
        padding-top: 9px;

        &.active.onTouch {
            background: linear-gradient(#f2f2f2, $color-light-gray);
        }

        &__icon {
            @include flex-center("portrait");
            position: relative;
            min-width: 22px;
            width: fit-content;
            height: 22px;

            @include svg-color($color-grey-4);
            .nav-bar__element.active & {
                @include svg-color($color-grey-9);
            }

            span {
                content: "";
                width: 12px;
                height: 12px;
                position: absolute;
                right: -6px;
                top: -4px;
                border-radius: 50%;
                background-color: $color-background;

                &::after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: inherit;
                    background-color: $color-chilli;
                }

                &.red-badge {
                    right: -2px;
                }
            }
        }

        .active & {
            &__text {
                color: #2e3338;
            }
        }

        &__text {
            padding-top: $spacing-component-xxs;
            font-size: 11px;
            line-height: 1.4;
            font-weight: $font-weight-medium;
            color: $color-gray;
            text-align: center;
        }
    }
}
