@import "src/sharedComponents/common/variables";

// The default #Cardinal-Modal style is transform: translate(-50%, -50%), z-index: 999999
#Cardinal-Modal {
    z-index: 1100; // Make it so this appears above regular modal messages including progress
    transform: translate3d(-50%, -50%, 1100px);
}

#Cardinal-Overlay {
    display: none;
}

// Styling for the Tyro 3DS modal has been copied from the Braintree version for continuity
.tyro-threedsecure {
    height: 400px;
    width: calc(100% - 10px);
    position: fixed;
    z-index: 1099;
    top: 50%;
    left: 50%;
    border: 0;
    border-radius: 2px;
    background: #fff;
    transform: translate3d(-50%, -50%, 1100px);
    box-shadow: 0 6px 22px -3px #000;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.open {
        opacity: 1;
    }

    > div {
        height: 100% !important;
    }

    iframe {
        border: 0;
    }

    @media screen and (max-width: 425px) {
        max-width: 410px;
        padding: 0.5em;
    }

    @media screen and (max-width: 375px) {
        max-width: 365px;
        padding: 0.5em;
    }

    @media screen and (max-width: 320px) {
        max-width: 310px;
        padding: 0.5em;
    }
}

.stripe-threedsecure {
    transform: translateZ(1100px);
    border-top: calc(0px + constant(safe-area-inset-top)) solid transparent; //for iOS 11.1
    border-top: calc(0px + env(safe-area-inset-top)) solid transparent; //for iOS 11.2
    border-bottom: calc(0px + constant(safe-area-inset-bottom)) solid transparent; //for iOS 11.1
    border-bottom: calc(0px + env(safe-area-inset-bottom)) solid transparent; //for iOS 11.2
}
