@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.loading-offer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $spacing-component;

    .loading-text {
        margin-bottom: $spacing-component-small;
    }

    &__stamp-card {
        margin-bottom: $spacing-component;
    }

    &__offer-component {
        .loading-text {
            width: 100%;
            height: 160px;
        }
    }
}
