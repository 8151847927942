@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/styles/variables/variables";

.members-status-list {
    &__members {
        display: flex;
        flex-direction: row;
        padding-bottom: $spacing-component;
        overflow: hidden;
        overflow-x: scroll;
        @include hide-scroll-bar();
        padding-top: $spacing-component-xxxs;
        
        &__member {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: $spacing-component-small;
            max-width: 56px;

            &:first-child {
                margin-left: $spacing-page-gutter;
            }
            
            &:last-child {
                margin-right: $spacing-page-gutter;
            }

            &__name.text {
                margin-top: $spacing-component-xxs;
                word-break: break-word;
                text-align: center;
                @include overflow-ellipsis-multiline(1);
                color: $color-grey-7;
                
                .browsing & {
                    color: $color-grey-3;
                }
            };

            &__status {
                color: $color-grey-5;

                .ready & {
                    color: $color-success;
                }
            }
        }
    }
}
