@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.no-offer-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        padding: 0;
        @include hide-scroll-bar;
    }
}
