@import "../../common/variables";

.text {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $line-height-default;
    color: inherit;
    display: inline-flex;

    &.title {
        font-weight: $font-weight-extrabold;

        &-20 {
            font-size: $font-modifier-title;
        }
        &-24 {
            font-size: $font-msg-title;
        }
        &-28 {
            font-size: $font-page-title;
        }
        &-32 {
            font-size: $font-product-title;
        }
        &-36 {
            font-size: $font-pwa-title;
        }
        &-48 {
            font-size: $font-home-title;
        }
    }
    &.g {
        &-10 {
            font-size: $font-initials;
        }
        &-12 {
            font-size: $font-descriptor;
        }
        &-14 {
            font-size: $font-caption;
        }
        &-16 {
            font-size: $font-category;
        }
        &-18 {
            font-size: $font-content;
        }
    }

    &.mode {
        &--bold {
            font-weight: $font-weight-semibold;
        }
        &--extra-bold {
            font-weight: $font-weight-extrabold;
        }
        &--block {
            display: block;
        }
        &--medium {
            font-weight: $font-weight-medium;
        }
        &--emphasis {
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
        &--caps {
            text-transform: uppercase;
        }
    }
}
