@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.quick-sell {
    border: 6px solid $color-grey-bg;
    border-left: none;
    border-right: none;
    margin-bottom: $spacing-component;

    transition: opacity 250ms ease-in-out;

    &--disabled {
        opacity: 0.5;
    }

    &__title {
        padding: $spacing-component $spacing-page-gutter;
    }

    &__description {
        color: $color-grey-5;
    }

    &__swiper {
        padding: 0 $spacing-page-gutter $spacing-component;
        overflow: hidden;
    }

    &__item {
        transition-property: opacity, transform, background-color;
        transition-timing-function: ease-in-out;
        transition-duration: 250ms;

        .energy-content {
            height: $spacing-component;
        }

        .hide-product-images & {
            display: flex;
            flex-direction: column;
        }
    }

    &__adder {
        &__container {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            transition: inherit;

            .hide-product-images & {
                height: $button-height-medium;
                background: $color-primary;
                order: 1;
                margin-top: $spacing-component-xs;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: inherit;

            background-color: add-opacity($color-black, 0.3);

            &.show-picker {
                background-color: add-opacity($color-black, 0.6);
            }

            .hide-product-images & {
                background-color: transparent;
            }
        }

        &__add {
            position: absolute;
            transition: inherit;
            display: flex;
            align-items: center;
            .quick-sell--disabled & {
                opacity: 0.5;
            }

            .show-picker & {
                transform: translate3d(100%, 0, 10px);
                opacity: 0;
            }

            .hide-product-images & {
                @include svg-color(var(--theme-button-text-color))
            }
        }

        &__picker {
            display: flex;
            align-items: center;
            opacity: 0;
            overflow: hidden;
            transition: inherit;

            &__quantity {
                width: 32px;
                height: 32px;
                color: $color-white;
                align-items: center;
                justify-content: center;

                .hide-product-images & {
                    color: var(--theme-button-text-color);
                }
            }
            span {
                display: flex;
                transition: inherit;

                .quick-sell--disabled & {
                    opacity: 0.5;
                }

                &:first-child {
                    transform: translate3d(100%, 0, 0);
                }

                &:last-child {
                    transform: translate3d(-100%, 0, 0);
                }

                svg {
                    min-width: 32px;
                    fill: black;

                    .hide-product-images & {
                        circle {
                            fill: var(--theme-button-text-color);
                        }
                        path {
                            stroke: $color-primary;
                        }
                    }
                }
            }
            .show-picker & {
                opacity: 1;

                span {
                    &:first-child {
                        transform: translate3d(0, 0, 0);
                    }

                    &:last-child {
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
        }
    }

    &__thumb {
        max-width: 100%;
        height: auto;
        min-height: 112px;
        max-height: 132px;
        display: flex;
        object-fit: cover;
        overflow: hidden;
    }

    &__name {
        color: $color-grey-8;
        margin-top: $spacing-component-xs;
        @include overflow-ellipsis-multiline(2);

        .hide-product-images & {
            margin-top: 0;
        }
    }

    &__flex-wrapper {
        display: flex;
        align-items: center;
        height: $spacing-component;
    }

    &__price {
        color: $color-grey-6;
        display: flex;
    }

    &__added {
        color: $color-primary;
        margin-left: $spacing-component-xxs;
        opacity: 0;
        transition: inherit;

        &--show {
            opacity: 1;
        }
    }
}
