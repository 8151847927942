@import "../../common/variables";
@import "../../common/mixins";

.form-control {
    &--invalid {
        .form-control__label {
            color: $color-error;
        }

        .form-control__message {
            color: $color-error;
        }
    }

    &--disabled {
        .form-control__label {
            color: $color-grey-3;
        }

        .form-control__message {
            color: $color-grey-3;
        }
    }

    & > *:not(:first-child) {
        margin-top: $spacing-component-xxs;
    }

    &__message {
        color: $color-grey-5;
    }
}
