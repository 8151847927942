@import "src/sharedComponents/common/variables";

.menu-page-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $double-spacing-component $spacing-page-gutter;
    height: $menu-page-footer-height;

    &__title {
        margin-bottom: $spacing-component-small;
        color: $color-grey-5;
    }

    .button-element {
        width: auto;
    }
}
