@import "../../common/mixins";
@import "../../common/variables";

.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    @include safe-area-padding-top($small-banner-height, height);
    padding: 0 $spacing-page-gutter;
    color: $color-text-inverse;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include svg-color($color-white);
    visibility: hidden;
    pointer-events: auto;

    > span {
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
    }

    svg {
        margin-right: $spacing-component-xs;
        height: 18px;
    }

    &--default {
        background-color: $color-grey-8;
    }

    &--warning {
        color: $color-text;
        background-color: $color-warning;
    }

    &--primary {
        color: var(--theme-button-text-color);
        background-color: $color-primary;
        @include svg-color(var(--theme-button-text-color));
    }

    &--error {
        background-color: $color-error;
        color: $color-white;
    }

    &.show {
        visibility: visible;
    }
}
