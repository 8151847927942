@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__TipSelection {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            z-index: 10;
            transform: translateZ(10px);

            .tip-selection-modal {
                @include animate-modal(slide-up, 150ms, ".tip-selection");
            }
        }
    }
}

.tip-selection {
    width: 100%;
    padding: 0 $spacing-page-gutter;
    @include safe-area-padding-bottom($spacing-page-gutter);
    max-height: fit-content;
    background-color: $color-white;
    border-top-left-radius: $border-radius-normal;
    border-top-right-radius: $border-radius-normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 250ms ease-in;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                position: absolute;
                padding: 0 $spacing-page-gutter;
                opacity: 0;
                transition: opacity 250ms ease-out;
            }
        }
    }
}