@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.offer-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $spacing-component;
    height: min-content;
    box-shadow: 4px 4px 20px $color-shadow;
    background-color: $color-white;
    @include safe-area-padding-bottom($spacing-component);

    .link {
        background-color: $color-white;
        color: $color-primary;
        text-decoration: none;
        display: contents;
    }

    &__subtitle {
        color: $color-grey-5;
    }

    &__newsletter-container {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-component;

        .checkbox-element {
            display: flex;
            align-items: center;

            @include svg-color($color-primary-text);
        }
    }

    &__newsletter-text {
        display: flex;
        flex-direction: column;
    }

    &__tappable-text-container {
        display: flex;
        align-items: center;
    }
}
