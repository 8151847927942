@import "src/sharedComponents/common/variables";

.divider {
    margin: $spacing-component $spacing-page-gutter;
    border-bottom: 1px solid $color-grey-0;

    &.small {
        margin: $spacing-component-small $spacing-page-gutter;
    }

    &.none {
        margin: 0 $spacing-page-gutter;
    }

    &.full-width {
        margin-right: 0;
        margin-left: 0;
    }

    &.extra-height {
        border-bottom-width: 9px;
    }

    &.dashed {
        background-image: linear-gradient(to right, $color-grey-6 50%, $color-white 20%);
        background-position: top;
        background-size: 8px 1px;
        background-repeat: repeat-x;
        height: 1px;
        border: none;

        &.extra-height {
            background-size: 8px 9px;
        }
    }

    &.transparent {
        border-color: transparent;
    }

    &[data-caption] {
        position: relative;
        &::after {
            content: attr(data-caption);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $color-white;
            color: $color-grey-5;
            font-size: $font-descriptor;
            font-weight: $font-weight-medium;
            padding: 0 $spacing-title;
        }
    }
}
