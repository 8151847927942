@import "src/sharedComponents/common/variables";

.stampcard-reward-earned {
    &__divider.divider {
        margin-left: -#{$spacing-page-gutter};
        margin-right: -#{$spacing-page-gutter};
    }

    &__stamp-card {
        margin: $spacing-component 0;
        box-shadow: none;
    }

    &__stand-alone {
        margin: $spacing-component 0;
    }
}
