@import "src/sharedComponents/common/variables";

.modifier-checkbox-list-item {
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;

    &__container {
        padding: $spacing-component-small $spacing-page-gutter;
        width: 100%;
    }

    &__checkbox {
        .text {
            flex-wrap: wrap;
        }

        &.checkbox-element {
            width: 100%;
        }

        input[type="checkbox"] {
            right: 0;
            left: unset;
        }

        input[type="checkbox"]:checked + label {
            .modifier__price,
            .energy-content {
                font-weight: $font-weight-normal;
            }
            .modifier-list-item-details__name {
                font-weight: $font-weight-medium;
            }
        }
    }

    &[disabled] {
        color: $color-grey-4;

        label {
            &::before {
                border-color: $color-grey-1;
                background: $color-grey-0;
                right: 0;
                left: unset;
            }
        }
    }

    label {
        line-height: 20px;
        height: auto;
        min-height: 20px;
        padding-left: 0;
        padding-right: calc(20px + #{$spacing-component});

        &::before {
            width: 20px;
            height: 20px;
            top: 50%;
            right: 0;
            left: unset;
            transform: translateY(-50%);
            border-color: $color-grey-2;
        }

        > svg {
            right: 4px;
            left: unset;
            top: initial;
            height: 9px;
            width: 12px;
        }
    }
}
