@import "../../../sharedComponents/common/variables";

.pay-only-notifications {
    padding: $spacing-component $spacing-page-gutter 0;

    .prompt + .prompt {
        margin-top: $spacing-component-small;
    }
}

.pay-only-notification {
    background-color: $color-warning;
    border-radius: $border-radius-medium;

    &-details {
        display: flex;
        flex-direction: column;

        .text + .text {
            margin-top: $spacing-component-small;
        }
    }
}
