@import "src/sharedComponents/common/variables";

$radio-element-size: 20px;

.radio {
    position: relative;
    min-height: $radio-element-size;

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        transition: inherit;

        + .fake-radio {
            position: absolute;
            height: $radio-element-size;
            width: $radio-element-size;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: inherit;
            pointer-events: none;

            &::before {
                position: absolute;
                height: $radio-element-size;
                width: $radio-element-size;
                left: 0;
                top: 0;
                content: "";
                border: 1px solid $color-grey-2;
                border-radius: 50%;
                transition: inherit;
                box-sizing: border-box;
            }

            &::after {
                content: "";
                position: absolute;
                height: calc(#{$radio-element-size} - 6px);
                width: calc(#{$radio-element-size} - 6px);
                left: 3px;
                top: 3px;
                border-radius: 50%;
                background: transparent;
            }
        }

        &:checked {
            + .fake-radio {
                &::after {
                    background: $color-primary;
                }
            }
        }

        &[disabled]:not(:checked) {
            + .fake-radio {
                &::before {
                    background: $color-grey-bg;
                    border: 1px solid $color-grey-0;
                }
                &::after {
                    opacity: 0;
                }
            }
        }

        &[disabled] {
            + .fake-radio {
                &::before {
                    background: $color-grey-bg;
                    border: 1px solid $color-grey-1;
                }
                &::after {
                    background: $color-grey-1;
                }
            }
        }
    }

    &.position-right {
        input[type="checkbox"] {
            + .fake-radio {
                left: unset;
                right: 0;

                &::before {
                    left: unset;
                    right: 0;
                }

                &::after {
                    left: unset;
                    right: 3px;
                }
            }
        }

        .radio__content {
            padding-left: unset;
            padding-right: calc(#{$radio-element-size} + #{$spacing-component});
        }
    }

    &__content {
        user-select: none;
        padding-left: calc(#{$radio-element-size} + #{$spacing-component});
    }

    &__wrapper-action {
        &[disabled] {
            .text {
                color: $color-grey-4;
            }
        }
    }
}
