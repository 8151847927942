@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.order-history-item-card-link {
    width: 100%;
    text-decoration: none;
    color: $color-black;

    &:not(:last-of-type) {
        border-bottom: 0.5px solid $color-grey-1;
    }
}

.no-history-item {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey-3;
    padding: 0 $spacing-page-gutter;
    text-align: center;
}

.history-list {
    margin: 0;
    list-style-type: none;
    @include hide-scroll-bar;
    @include safe-area-padding-bottom(0px);

    .party-wrapper & {
        @include safe-area-padding-bottom($bottom-nav-bar);
    }

    .history-list-item {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

        &__loader {
            @include safe-area-padding-bottom(0px);

            &__svg {
                height: 50px;
                width: 50px;
            }
        }

        .history-card {
            transform: opacity 100ms ease, transform 100ms ease;
            color: $color-grey-8;

            &__descriptors {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-grow: 2;
                height: 108px;

                &.shaded-image {
                    color: $color-text-inverse;
                    background: var(--shade_image);
                    background-size: cover;
                    background-position: center;
                }
            }

            &__name {
                padding: 0 10px;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
            }
        }
    }
}
