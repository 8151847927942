@import "src/sharedComponents/common/variables";

.tab-payment-selector__details {
    padding: 0 $spacing-page-gutter;

    &__title {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-component-small;

        &__action {
            color: $color-primary;
        }
    }
}
