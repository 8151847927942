@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.membership-signup {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;

    &__manage {
        color: $color-primary;
        padding: $spacing-component-small $spacing-page-gutter;
    }

    .scroll-element {
        background-color: $color-white;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        padding-bottom: $spacing-header;
    }

    &__subtitle {
        color: $color-grey-8;

        &__link {
            color: $color-primary;
            text-decoration: none;
        }
    }

    &__submit {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        &__text {
            text-align: center;
            color: $color-grey-4;
        }
        @include when-keyboard-open {
            position: relative;
        }
    }

    &__data-collection-statement {
        .text {
            color: $color-grey-4;
            margin-top: $spacing-component-small;
        }
    }
}
