@import "../../common/variables";

.title-with-action {
    display: flex;
    justify-content: space-between;

    &__action {
        color: $color-primary;
    }
}
