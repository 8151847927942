@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.offer-claimed-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .scroll-element {
        flex: 1;
        overflow-y: auto;
        padding: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: calc(100% - #{$simple-nav-header-height});
        align-items: center;
        background-color: $color-white;
        flex: 1;
        width: 100%;
        position: relative;
        border-top: 1px solid $color-grey-0;
    }

    &__info-container {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $simple-nav-header-height;
    }

    &__text {
        padding: $double-spacing-component $spacing-component;
        margin-top: $double-spacing-component;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title,
        &__subtitle {
            margin-bottom: $spacing-component-small;
        }

        &__subtitle {
            text-align: center;
        }
    }

    &__button {
        padding: $spacing-component;
        width: 100%;
        text-decoration: none;
    }

    &__in-party-footer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $spacing-component;
        height: min-content;
        box-shadow: 4px 4px 20px $color-shadow;
        background-color: $color-white;
        @include safe-area-padding-bottom($spacing-component);

        .button-element {
            &:first-child {
                margin-bottom: $spacing-component-xs;
            }
        }
    }
}
