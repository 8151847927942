@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.my-offers-page {
    position: fixed;
    top: 0;
    padding: 0 $spacing-page-gutter;
    width: 100%;
    @include safe-area-padding-bottom($bottom-nav-bar);
    overflow-y: auto;
    @include use-100vh;
    @include hide-scroll-bar;

    &__header {
        padding: $spacing-header 0;
    }
    &__no-offers {
        padding: $spacing-component 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $color-grey-5;
        @include svg-color($color-grey-4);

        svg {
            width: 48px;
            height: 48px;
            margin-bottom: $double-spacing-component;
        }

        &__subtitle {
            margin-top: $spacing-component-xxs;
            color: $color-grey-4;
            &--opt-in {
                padding-top: $spacing-component;
            }
        }
    }

    &__sign-in {
        .call-to-action-header {
            background-color: $color-primary;
            border-radius: $border-radius-large;
            text-align: start;
            &__title,
            &__subtitle {
                color: $color-text-inverse;
            }
            &__title {
                margin-bottom: $spacing-component-xs;
            }
            &__button {
                margin-top: $spacing-header;
            }
            &__icon {
                display: flex;
                @include svg-color($color-text-inverse);
                svg {
                    width: $large-icon;
                    height: $large-icon;
                }
            }
        }
    }

    &__subscribe-button {
        display: flex;
        justify-content: center;
    }

    &__privacy-policy {
        position: fixed;
        bottom: calc(#{$bottom-nav-bar} + #{$spacing-page-gutter});

        &__link {
            color: $color-primary;
            text-decoration: none;
        }
    }

    &__loading-offer {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__discount-offers,
    &__stamp-card {
        margin-bottom: $spacing-header;
    }

    &__section-title-container {
        margin-bottom: $spacing-component;
    }

    &__section-subtitle {
        color: $color-grey-5;
    }

    &__unclaimed {
        border-radius: $border-radius-medium;
        color: $color-grey-7;
        background-color: $color-grey-0;
        @include svg-color($color-grey-4);
        padding: $spacing-component-xxs $spacing-component-xs;
        margin-left: $spacing-component-xs;
        svg {
            margin-right: $spacing-component-xxxs;
            height: 12px;
            width: 12px;
        }
    }
}
