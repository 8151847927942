@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

$chip-size: 44px;
$chip-size-small: 34px;
$chip-size-xsmall: 26px;

.chip {
    display: inline-flex;
    &--small {
        padding: 5px 0; // tappable area to be 44px (5 + 5 + 34)

        .chip__button {
            min-height: $chip-size-small;
            height: $chip-size-small;
            padding: $spacing-component-xs $spacing-component-small;
        }
    }

    &--xsmall {
        .chip__button {
            min-height: $chip-size-xsmall;
            height: $chip-size-xsmall;
            padding: $spacing-component-xs $spacing-component-small;
            border-radius: $border-radius-normal;       
        }
    }

    &__button {
        border-radius: $top-content-spacing;
        width: auto;
        padding: 13px $spacing-component;
        min-height: $chip-size;
        height: $chip-size;

        &--default {
            &.mode-outline.unthemed {
                border-color: $color-grey-9;
            }
            &.mode-solid.unthemed {
                border-color: $color-grey-9;
                background: $color-grey-9;
                color: $color-text-inverse;
                @include svg-color($color-white);
            }
        }

        &--dark {
            &.mode-outline {
                background: $color-black;
                --button-color: #{$color-black};
                @include svg-color($color-white);

                .text {
                    color: $color-white;
                }

                &.unthemed {
                    border-color: $color-black;
                }
            }

            &.mode-solid.unthemed {
                background: $color-white;
                --button-color: #{$color-white};
                @include svg-color($color-black);
                color: $color-text;
            }
        }

        &--grey {
            &.mode-outline {
                --button-color: #{$color-text};
                border-color: $color-grey-0;
                background: $color-grey-0;

                &.unthemed {
                    border-color: $color-grey-0;
                }
            }

            &.mode-solid.unthemed {
                border-color: $color-grey-9;
                background: $color-grey-9;
                color: $color-text-inverse;
                @include svg-color($color-white);
            }
        }
        
        &--disabled {
            &.mode-solid {
                background: $color-grey-bg;
                --button-color: #{$color-grey-bg};
                color: $color-grey-3;
                @include svg-color($color-grey-1);
            }

            &.mode-solid.unthemed {
                color: $color-white;
            }

            &.mode-outline.unthemed {
                @include svg-color($color-grey-1);
            }
        }

        .text {
            top: 0;
        }

    }

    svg {
        width: 13px;
        height: 13px;
        margin-right: $spacing-component-xs;
    }

}
