@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.icon-with-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background: $color-primary;
    border-radius: 50%;
    margin-right: $spacing-component-xxs;
    @include svg-color(var(--theme-button-text-color));

    svg {
        width: 10px;
    }

    &.membership-indicator {
        svg {
            padding-top: 1px;
        }
    }
}
