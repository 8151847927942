@import "src/sharedComponents/common/variables";

.list-item-background {
    opacity: 0;
    background-color: $color-grey-bg;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
}
