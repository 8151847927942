@import "../../common/mixins";
@import "../../common/variables";

$checkbox-element-size: 20px;

.checkbox-element {
    position: relative;
    transform: translate3d(0, 0, 0);
    height: 100%;

    input {
        position: absolute;
        top: 50%;
        left: 0;
        height: $checkbox-element-size;
        width: $checkbox-element-size;
        opacity: 0;
        margin: 0;
        padding: 0;
        transform: translateY(-50%);
    }

    label {
        position: relative;
        transform: translate3d(0, 0, 0);
        min-height: $checkbox-element-size;
        padding-left: $checkbox-element-size + $spacing-component;
        display: flex;
        align-items: center;
        color: inherit;

        > svg {
            position: absolute;
            left: calc((#{$checkbox-element-size} - 12px) / 2);
            width: 12px;
            z-index: 1;
            display: none;
        }

        &::before {
            position: absolute;
            height: $checkbox-element-size;
            width: $checkbox-element-size;
            left: 0;
            top: 50%;
            content: "";
            border: 1px solid rgba(0, 0, 0, 0.25);
            background: $color-white;
            border-radius: 2px;
            transition: background-color 100ms ease-in;
            box-sizing: border-box;
            transform: translateY(-50%);
        }

        &[disabled] {
            &::before {
                background: $color-grey-bg;
                border-color: $color-grey-0;
            }

            .text {
                color: $color-grey-4;
            }
        }
    }

    input[type="checkbox"]:checked + label {
        svg {
            display: block;
        }

        &::before {
            border-color: $color-primary;
            background: $color-primary;
        }
        .text {
            font-weight: $font-weight-semibold;
        }

        &[disabled] {
            &::before {
                background: $color-grey-1;
                border: $color-grey-1;
            }

            .text {
                font-weight: $font-weight-normal;
                color: $color-grey-4;
            }
        }
    }
}
