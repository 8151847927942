@import "src/sharedComponents/common/variables";

.button--back__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    padding-right: 12px;
    padding-left: 20px;
}

.button--back {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    -webkit-font-smoothing: auto;
    outline: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    color: #000;
    display: flex;
    justify-content: center;
    height: 100%;
    width: auto;
    padding: 0;
    &--close {
        svg {
            width: 15px;
            height: 15px;
        }
    }

    &:active {
        opacity: 0.7;
    }

    svg {
        position: relative;
        margin-left: 0;
        font-size: 21px;
        width: 13px;
        height: 12px;
        top: 0;
    }
}
