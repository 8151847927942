@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.nested-modifiers {
    &__transition {
        &-enter {
            transform: translate3d(100%, 0, 10px);

            &-active {
                transform: translateZ(10px); // same as the menuItemPage header (.menu-page__header)
                transition: transform 250ms ease-in;
            }
            &-done {
                transform: translateZ(10px); // same as the menuItemPage header (.menu-page__header)
            }
        }

        &-exit {
            &-active {
                transform: translate3d(100%, 0, 10px);
                transition: transform 250ms ease-in;
            }
        }
    }

    &__page {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        z-index: 2; // same as the menuItemPage header (.menu-page__header)

        &__scroll {
            overflow: hidden;
            overflow-y: auto;
            flex: 2;

            @include hide-scroll-bar;
        }

        footer {
            padding: $spacing-page-gutter;
            @include safe-area-padding-bottom($spacing-page-gutter);
        }
    }
}
