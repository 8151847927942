@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.rotated-page {
    position: fixed;
    background: $color-kale;
    width: 100%;
    height: 100%;
    z-index: 2000;
    transform: translateZ(2000px);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        display: block;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        color: $color-text-inverse;
    }

    &__title {
        line-height: 36px;
    }

    .text:not(.rotated-page__title) {
        margin-top: 8px;
        line-height: 17px;
    }
}
