@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.modifier-indicator {
    &__container {
        transition: inherit;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: $spacing-component-xxxs;
        color: $color-primary;
    }

    &__member-price {
        display: flex;
        align-items: center;
        margin-right: $spacing-component-xs;
    }
}
