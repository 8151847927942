@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.waiting-for-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: stretch;
    gap: $spacing-component-xs;

    &__member {
        width: 56px;
        display: flex;
        flex-direction: column;
        gap: $spacing-component-xxs;

        .member-indicator {
            border: none;
            background: #5f5f5f;
            color: $color-white;
            @include svg-color($color-white);
        }

        span {
            overflow-wrap: break-word;
            @include overflow-ellipsis-multiline(1);
        }
    }

    &__member-counter {
        .member-indicator {
            border: none;
        }
    }
}
