@import "src/sharedComponents/common/variables";

.custom-tip {
    width: 100%;
    position: relative;

    .simpleNavHeader {
        &__height {
            top: 0;
            min-height: $simple-nav-header-height;

            .keyboard-open &:not(.simpleNavHeader__wrapper) {
                display: block;
                position: static;
            }
        }
        &__wrapper {
            position: absolute;
            margin: 0 -#{$spacing-page-gutter};
            padding-top: 0;
            width: calc(100% + (2 * #{$spacing-page-gutter}));
        }
    }

    &__content {
        .form-control {
            margin-bottom: $spacing-header;
            padding: 0 $spacing-page-gutter;
            text-align: center;

            .input-control {
                &__input {
                    border: 0;

                    &:focus-within::before {
                        border: 0;
                    }
                }

                input {
                    font-size: $font-product-title;
                    font-weight: $font-weight-extrabold;
                    text-align: center;
                }

                &--invalid {
                    input {
                        color: $color-error;
                    }
                }
            }

            &__message {
                font-weight: $font-weight-semibold;
            }
        }

        &--warning {
            .form-control__message {
                color: $color-warning;
            }
        }
    }

    &__buttons {
        display: flex;
        margin: $double-spacing-component 0;
        align-items: center;
        justify-content: center;

        &__button {
            width: 100px;
            padding: $spacing-component-xs $spacing-component-small;
            height: 34px;
            min-height: 34px;
            margin: $spacing-component-xxs;
            border-radius: $top-content-spacing;

            &.mode-outline {
                --button-color: #{$color-grey-8};
                border-color: $color-grey-1;
            }
        }
    }
}
