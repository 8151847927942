@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.line-items {
    display: flex;
    flex-direction: column;

  .line-item {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:not(:first-child) {
      padding-top: $spacing-component-xs;
    }
  }
}

