@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/styles/animations/animations";

.GroupTabOverview-modal {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    .tab-overview-header {
        margin-top: $spacing-component;
    }
}

.group-tab-overview-modal {
    height: 100%;
    overflow-y: scroll;
    @include hide-scroll-bar;
    display: flex;
    flex-direction: column;
    .tab-overview {
        position: unset;
        height: 100%;
        overflow-y: scroll;
        @include hide-scroll-bar;
        display: flex;
        flex-direction: column;
    }
}

.tab-overview {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include safe-area-padding-bottom($bottom-nav-bar, bottom);

    &__body {
        overflow-y: auto;
        padding-bottom: 40px;
    }

    &__button-row,
    &__settings__title,
    &__footer-actions {
        padding: 0 $spacing-page-gutter;
    }

    &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $spacing-component-xs;

        &__button {
            @include svg-color($color-black);
            padding: $spacing-component-small $spacing-page-gutter;

            &.secondary {
                margin-top: 0;
            }

            .text {
                color: $color-grey-9;
            }
            
            svg {
                height: 24px;
                width: 24px;
            }

            &.mode-outline {
                --button-color: #{$color-grey-2};
            }
        }
    }

    &__footer-actions {
        &__button.mode-outline {
            &:not(.disabled) {
                --button-color: #{$color-grey-2};
                .text {
                    color: $color-grey-9;
                }
            }
        }

        &__disclaimer {
            padding: $spacing-component 0;
            color: $color-grey-5;
            text-align: center;
        }
    }
}
