/*
  React Modal global overrides
*/
@import "../../common/variables";
@import "../../common/mixins";

.ReactModalPortal {
    --animation-duration-ms: 200ms;
}

.ReactModal__Body--open {
    &:not(.scroll-root):not(.pay-only-party) {
        .party-wrapper,
        #root {
            position: fixed;
            overflow: hidden;
            top: 0;
            bottom: 0;
            width: 100vw;
            @include safe-area-padding-bottom($bottom-nav-bar);
        }
    }
    &.scroll-root {
        #root {
            position: fixed;
            overflow: hidden;
            top: 0;
            bottom: 0;
            width: 100vw;
            @include safe-area-padding-bottom($bottom-nav-bar);
        }
    }
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
    }

    &.second-layer {
        z-index: 2;
    }

    @include animate-modal(fade-in, var(--animation-duration-ms));

    &.allow-shrink-view-on-keyboard-open {
        min-height: 0;
    }

    &.keyboard-unfixed {
        @include when-keyboard-open {
            position: relative;
            box-shadow: none;
        }
    }
}

.ReactModal__Content {
    flex: 1;

    @include animate-modal(fade-in, var(--animation-duration-ms));

    &__Body {
        &--open {
            overflow: hidden;
        }
    }

    &__Overlay {
        background-color: transparent;
        z-index: 1;
    }

    padding-top: calc(20px + constant(safe-area-inset-top)); //for iOS 11.1
    padding-top: calc(20px + env(safe-area-inset-top)); //for iOS 11.2
}

.ReactModalPortal {
    .slide-in-modal,
    .slide-from-right-modal {
        min-height: 100%;
        background: white;
        box-shadow: 0 4px 20px -16px #000;

        .location-container {
            display: inline;
        }
    }

    .slide-in-modal--overlay,
    .slide-down-modal--overlay,
    .slide-from-right-modal--overlay {
        &.ReactModal__Overlay {
            background: transparent;
            pointer-events: all;

            .slide-in,
            .slide-from-right {
                background: $color-white;
                height: 100%;
            }
        }
    }

    .slide-in-modal--overlay {
        &.ReactModal__Overlay {
            @include animate-modal(slide-up);
        }
    }

    .slide-down-modal--overlay {
        &.ReactModal__Overlay {
            @include animate-modal(slide-down);
        }
    }

    .slide-from-right-modal--overlay {
        &.ReactModal__Overlay {
            @include animate-modal(slide-from-right);
        }
    }
}

.background-blur .ReactModalPortal .ReactModal__Overlay {
    filter: blur(20px);
}
