@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.menuitem-filter__modal__overlay {
    background-color: rgba(0, 0, 0, 0.55);
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translateZ(1px);

    .ReactModal__Content {
        @include animate-modal(slide-down);
    }
}

.menuitem-filter__modal {
    background-color: #fff;
    flex: 1;
    margin: 0;
    padding: 20px;
    max-height: 80%;
    display: flex;

    @media screen and (min-width: 600px) {
        padding: 20px;
    }

    .location-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.filtermodal__item__label {
    color: $color-black;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 5px;
}

.filtermodal__title {
    font-weight: $font-weight-semibold;
}

.filtermodal__header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.filtermodal__header--left {
    flex: 1;
    display: flex;
    align-items: center;
}

.filtermodal__header--right {
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex-flow: row-reverse;
}

.filtermodal__content--wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.filtermodal__filters {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    @include hide-scroll-bar();
}

.filtermodal__filter-label {
    color: $color-black;
    margin-left: 10px;
    font-size: 15px;
}

.filtermodal__filter-icon {
    width: 16px;
}

.filtermodal__close-icon {
    color: $color-black;
    width: 15px;
    height: 15px;
    max-width: 25px;
    cursor: pointer;
    content: url(../../../assets/icons/close.svg);
}

.filtermodal__reset {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $color-gray;
    text-decoration: underline;
    font-size: get-vw(13px);
    margin-top: 20px;
    @include safe-area-padding-bottom(0px);
}

.filtermodal__reset-icon {
    cursor: pointer;
}

.input-range__label--min,
.input-range__label--max {
    display: none;
}

.input-range__label--max {
    right: 8px;
}

.input-range__label--min {
    left: 6px;
}

.input-range__track--active {
    background-color: $colorRed;
    height: 13px;
    top: -3px;
    border-radius: 0;
}

.input-range__slider {
    opacity: 0;
    padding: 30px 0;
}

.input-range__track,
.input-range__slider-container {
    transition-duration: 0.15s;
}

.input-range__slider-container .input-range__slider {
    padding-left: 50vw;
    padding-right: 70px;
    transform: translate(-50vw, -50%);
    border-radius: 0;
}

.input-range__slider-container + .input-range__slider-container .input-range__slider {
    padding-left: 70px;
    padding-right: 50vw;
    transform: translate(-30px, -50%);
}

.input-range__label {
    color: $color-black;
    top: 30px;
}

.input-range__label--value {
    top: 7px;
}

.input-range__track--background {
    height: 6px;
}

.filtermodal__apply {
    margin-top: 20px;
}

.header-container {
    display: flex;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;
}

.filterTagsRow ~ .filterTagsRow {
    margin-top: 18px;
}

.filtermodal__price {
    margin-top: 25px;
    margin-bottom: 45px;

    .filtermodal__item__label {
        margin-top: 1px;
        margin-bottom: 25px;
    }
}

.dropdown-wrapper {
    max-width: 48%;
    flex-basis: 48%;

    &:not(:last-child) {
        margin-bottom: $spacing-component-small;
    }

    .filtermodal__item__label {
        font-weight: $font-weight-semibold !important;
    }
}

.filtermodal__horizontalRule {
    margin-top: 30px;

    hr {
        height: 2px;
    }
}

.input-range__label--min,
.input-range__label--max {
    span {
        color: $color-gray !important;
        font-family: "Manrope", sans-serif;
        font-size: 15px;
    }
}

.input-range__label--value {
    color: $colorRed !important;
    font-family: "Manrope", sans-serif;
    font-size: 17px;
}

.input-range__slider-container {
    /* left input-range__label */
    &:nth-of-type(1) .input-range__label--value {
        .input-range__label-container {
            left: 0;
        }
    }

    /* right input-range__label */
    &:nth-of-type(2) .input-range__label--value {
        .input-range__label-container {
            left: auto;
            right: 100%;
        }
    }
}
