@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.CartView-modal {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    @include hide-scroll-bar;
    overflow-y: auto;

    @include hide-when-keyboard-open(all, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".CreateVerifiedAccountWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SecuredPaymentWizard-modal__Body--open");

    &--overlay {
        overflow-y: hidden;

        &.slide-in-modal--overlay.ReactModal__Overlay--after-open {
            transform: translateZ(10px);
        }

        .PaymentWizard-modal__Body--open & {
            &.slide-in-modal--overlay.ReactModal__Overlay--before-close {
                visibility: hidden; // Silently close the cart modal when closing after completing payment wizard
            }
        }
    }

    &__invalid {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        background-color: $color-warning;
        color: $color-white;
        padding: $spacing-component;
        @include safe-area-padding-top($spacing-page-gutter);
        --animation-z: 1px;
        animation: slide-down 200ms ease;
    }

    .swiper-wrapper {
        z-index: 0;
    }

    &__footer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        padding: $spacing-page-gutter;
        background: $color-white;
        @include safe-area-padding-bottom($spacing-page-gutter);
        transform: translateZ(1px);

        .incorrect-document-height & {
            bottom: calc(var(--document-height-diff) + #{$bottom-nav-bar});
        }

        .tooltip.bottom {
            bottom: calc(100% + #{$spacing-component-xs});
        }
    }

    .toast.show {
        z-index: 1;
    }
}
