@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.wait-time-badge {
    display: flex;
    align-items: center;
    position: absolute;
    left: 4px;
    bottom: 4px;
    border-radius: $border-radius-small;
    background-color: $color-grey-9;
    color: $color-white;
    padding: $spacing-component-xxs 6px;
    white-space: nowrap;

    svg {
        margin-right: $spacing-component-xxs;
        width: 12px;
        height: 12px;
    }
    @include svg-color($color-white);

    &.subtle {
        @include svg-color($color-grey-7);
        background-color: $color-grey-0;
        color: $color-grey-7;
    }
}
