@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.toast {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-primary;
    color: $color-white;
    color: var(--theme-button-text-color);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include safe-area-padding-top(0px);
    @include safe-area-padding-top(44px, height);
    @include svg-color(var(--theme-button-text-color));
    transform: translate3d(0, -100%, 0);

    &.warning {
        background-color: $color-warning;
    }

    &.show {
        transition: transform 150ms ease-in;
        transform: translate3d(0, 0, 0);
    }

    &.hide {
        transition: transform 100ms ease-out;
        transform: translate3d(0, -100%, 0);
    }

    svg {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: $spacing-component-xs;
    }

    &__close {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 44px;
        padding: 0 $spacing-page-gutter;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
