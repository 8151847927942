@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.successful-marketing-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    @include safe-area-padding-bottom($spacing-page-gutter);

    .scroll-element {
        height: calc(100% - #{$button-height} - #{$spacing-component});
    }

    &__footer {
        padding: $spacing-component;
    }
}
