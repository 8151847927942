@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.edit-food-preferences {
    height: 100%;
    position: relative;
    
    &__content {
        margin-top: $spacing-component-small;
        padding: $spacing-page-gutter;
    }
    
    &__description {
        color: $color-grey-8;
        margin-bottom: $spacing-header;
    }

    .tagbuttongroup--item {
        @include svg-circle-color(false);
        .brand-color {
            svg.multi-color {
                path {
                    fill: $color-white;
                }
            }
        }
        .mode-solid {
            @include svg-circle-color(true);
            &.brand-color {
                svg.multi-color {
                    circle {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &__submit {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        &__wrapper {
            padding: $spacing-page-gutter;
            @include safe-area-padding-bottom($spacing-page-gutter);
            background-color: $color-white;
        }
    }
}
