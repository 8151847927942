@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/styles/variables/variables";

.start-or-join-flex-tab {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include safe-area-padding-top(0px);
    display: flex;
    background-color: $color-background;
    
    main {
        margin-bottom: calc(#{$button-height} * 2);
        padding-bottom: $spacing-component;
    }
    
    &__title {
        color: $color-black;
        padding: $spacing-header $spacing-page-gutter;
        text-align: center;
    }

    &__row {
        padding: 0 $spacing-page-gutter $spacing-header;
        display: grid;
        grid-template-areas:
            "icon title"
            "icon description";
        grid-template-columns: 48px auto;
        align-items: center;


        &:first-of-type {
            padding-top: $spacing-component;
        }

        &__icon {
            grid-area: icon;
        }

        &__title {
            grid-area: title;
            color: $color-grey-8;
        }

        &__description {
            grid-area: description;
            color: $color-grey-4;
        }
    }

    footer {
        padding: $spacing-page-gutter;
        text-align: center;
        @include safe-area-padding-bottom($spacing-component);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        
        button {
            &:last-child {
                margin-top: $spacing-component;
            }
        }
    }
}
