@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.category-card {
    height: auto;

    .img-div {
        margin-bottom: 8px;
        position: relative;
        img {
            border-radius: 8px;
            width: 100%;
            height: 132px;
            display: flex;
            object-fit: cover;
        }
    }

    .text-div {
        color: $color-grey-8;
    }

    &--specials {
        .text-div {
            color: $color-primary;
            @include svg-color($color-primary);

            svg {
                margin-right: 5px;
                position: relative;
                top: 2px;
                flex-shrink: 0;
            }
        }
    }

    .hide-product-images & {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wait-time-badge {
            position: static;
            margin-left: $spacing-component-xs;
            display: inline-flex;
        }
    }
}
