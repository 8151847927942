@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/styles/animations/animations";
@import "src/sharedComponents/common/mixins";

@mixin menu-transition {
    pointer-events: none;
    animation-timing-function: ease-in-out;
    animation-duration: 250ms;
    animation-name: none;
    animation-fill-mode: forwards;
}

.menu-page-tabs {
    .simpleNavHeader__wrapper {
        .header-tabs svg path {
            stroke-width: 4;
        }
    }
    &__page-wrapper {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @include hide-scroll-bar;
    }

    .with-view-cart & {
        padding-bottom: $view-cart-button-height;
    }

    .with-ready-indicator & {
        padding-bottom: $spacing-page-gutter;
    }

    .with-view-cart.with-ready-indicator & {
        padding-bottom: calc(#{$view-cart-button-height} + #{$spacing-page-gutter});
    }

    &.slide-left-enter,
    &.slide-left-exit {
        .menu-page {
            position: fixed;
            .menu-page-footer {
                // hide page footer whilst we animate in the footer within animating category list
                display: none;
            }
            .category-list {
                display: none;
            }
            .animating-category-list {
                display: flex;
                flex-direction: column;
                overflow: hidden;
            }
            section {
                overflow: hidden;
                height: 100%;
                .animating-category-list {
                    max-height: calc(100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height});
                    max-height: calc(
                        100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height} - constant(safe-area-inset-bottom)
                    );
                    max-height: calc(
                        100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height} - env(safe-area-inset-bottom)
                    );
                    overflow-y: scroll;
                    @include hide-scroll-bar;

                    .menu-page-footer {
                        margin-bottom: $double-spacing-component;
                        display: flex;
                    }
                }
            }
        }
        &.with-banner {
            .menu-page {
                section {
                    .animating-category-list {
                        max-height: calc(
                            100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height} - #{$small-banner-height}
                        );
                        max-height: calc(
                            100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height} - #{$small-banner-height} -
                                constant(safe-area-inset-bottom)
                        );
                        max-height: calc(
                            100% - #{$tabs-height} - #{$bottom-nav-bar} + #{$simple-nav-header-height} - #{$small-banner-height} -
                                env(safe-area-inset-bottom)
                        );
                    }
                }
            }
        }
    }

    &.with-banner {
        .simpleNavHeader__wrapper {
            padding: 0;
        }
        .simpleNavHeader__height {
            min-height: $simple-nav-header-height;
            @include safe-area-padding-top($small-banner-height, margin-top);
        }

        .PUSH,
        .POP {
            > .slide-exit {
                section {
                    top: calc(#{$tabs-height} + #{$simple-nav-header-height} + #{$small-banner-height});
                    top: calc(
                        #{$tabs-height} + #{$simple-nav-header-height} + #{$small-banner-height} + constant(safe-area-inset-top)
                    );
                    top: calc(
                        #{$tabs-height} + #{$simple-nav-header-height} + #{$small-banner-height} + env(safe-area-inset-top)
                    );

                    .with-view-cart & {
                        bottom: $view-cart-button-height;
                    }

                    .with-ready-indicator & {
                        bottom: $spacing-page-gutter;
                    }

                    .with-view-cart.with-ready-indicator & {
                        bottom: calc(#{$view-cart-button-height} + #{$spacing-page-gutter});
                    }

                    &.empty-tab {
                        top: $simple-nav-header-height + #{$small-banner-height};
                        top: calc(
                            #{$simple-nav-header-height} + #{$small-banner-height} + constant(safe-area-inset-top)
                        );
                        top: calc(#{$simple-nav-header-height} + #{$small-banner-height} + env(safe-area-inset-top));
                    }
                }
            }
        }
    }

    .PUSH,
    .POP {
        > .slide {
            &-enter {
                section {
                    overflow: hidden;
                    height: 100%;
                }
                &-active {
                    section,
                    .menu-page__items-header {
                        @include menu-transition;
                    }
                }
            }
            &-exit {
                &-active {
                    section,
                    .menu-page__items-header {
                        @include menu-transition;
                    }
                }
                &-done {
                    section,
                    .menu-page__items-header {
                        opacity: 0;
                    }
                }
                section {
                    position: fixed;
                    top: calc(#{$tabs-height} + #{$simple-nav-header-height});
                    top: calc(#{$tabs-height} + #{$simple-nav-header-height} + constant(safe-area-inset-top));
                    top: calc(#{$tabs-height} + #{$simple-nav-header-height} + env(safe-area-inset-top));
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: hidden;
                    @include hide-scroll-bar;

                    &.with-footer {
                        @include safe-area-padding-bottom($bottom-nav-bar);
                    }

                    .with-view-cart & {
                        bottom: $view-cart-button-height;
                    }

                    .with-ready-indicator & {
                        bottom: $spacing-page-gutter;
                    }

                    .with-view-cart.with-ready-indicator & {
                        bottom: calc(#{$view-cart-button-height} + #{$spacing-page-gutter});
                    }

                    &.empty-tab {
                        top: $simple-nav-header-height;
                        top: calc(#{$simple-nav-header-height} + constant(safe-area-inset-top));
                        top: calc(#{$simple-nav-header-height} + env(safe-area-inset-top));
                    }
                }
            }
        }
    }

    .PUSH {
        > .slide {
            &-enter {
                &-active {
                    section,
                    .menu-page__items-header {
                        animation-name: slide-from-right;
                    }
                }
            }
            &-exit {
                .menu-page__items-header {
                    height: $tabs-height;
                    overflow: hidden;
                }

                &-active {
                    section,
                    .menu-page__items-header {
                        animation-name: slide-from-left-reverse;
                    }
                }
            }
        }
    }

    .POP {
        > .slide {
            &-enter {
                &-active {
                    section,
                    .menu-page__items-header {
                        animation-name: slide-from-left;
                    }
                }
            }
            &-exit {
                .menu-page__items-header {
                    height: $tabs-height;
                    overflow: hidden;
                }
                &-active {
                    section,
                    .menu-page__items-header {
                        animation-name: slide-from-right-reverse;
                    }
                }
            }
        }
    }
}
