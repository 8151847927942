@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
.payment-item {
    &--member-discount,
    &--promotion-discount,
    &--offer-discount {
        color: $color-primary;
        @include svg-color($color-primary);
    }

    &--promotion-discount,
    &--offer-discount {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    &--promocode {
        color: $color-primary;
        @include svg-color($color-primary);
        svg {
            width: 15px;
            height: 15px;
        }
    }
}
