@import "src/styles/mixins/mixins";
@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.profile-drawer {
    display: block;
    height: 100%;
    background: $color-white;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;

    overflow-x: hidden;

    .incorrect-document-height & {
        bottom: var(--document-height-diff);
    }

    // Move whole modal when keyboard open
    @include when-keyboard-open {
        position: relative;
        box-shadow: none;
    }

    // Hide for login, survey and marketing modals when keyboard is open
    // as we'll see it behind because of overscroll
    @include hide-when-keyboard-open(all, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SecuredPaymentModal-modal__Body--open");
    @include hide-when-keyboard-open(all, ".GroupTabsWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".Marketing-modal__Body--open");
    @include hide-when-keyboard-open(all, ".AccountDetails-modal__Body--open");

    .account__container__content {
        @include safe-area-padding-bottom($bottom-nav-bar);

        .incorrect-document-height & {
            @include safe-area-padding-bottom(calc(var(--document-height-diff) + #{$bottom-nav-bar}));
        }
    }
}

.profile-drawer-modal {
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    .party-wrapper & {
        height: calc(100% - 60px);
    }

    .wrapper-for-fixed-nav {
        @include safe-area-padding-top($simple-nav-header-height);
    }
}
.wrapper-for-no-nav {
    @include safe-area-padding-top(0px);
}

.ReactModalPortal .ReactModal__ProfileDrawer {
    overflow-x: hidden;
    &.ReactModal__Overlay {
        z-index: 9;
        opacity: 1 !important;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        min-height: 0;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include animate-modal(slide-from-right);

        .incorrect-document-height & {
            bottom: var(--document-height-diff);
        }
    }
}
