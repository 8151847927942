@import "src/sharedComponents/common/variables";

.google-pay-button {
    height: $button-height;
    border-radius: $border-radius-normal;
    background: $color-black;
    width: 100%;
    overflow: hidden;

    .gpay-button.black.checkout {
        border: 0;

        &.hover {
            background-color: $color-black;
        }
    }

    &--custom {
        position: relative;

        .gpay-button-fill {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transform: translateZ(1px);
        }
    }
}

#stripe-payment-request-button {
    border-radius: $border-radius-normal;
    overflow: hidden;
}
