@import "../../common/variables";

.counter {
    position: relative;
    width: 20px;
    height: 20px;

    &.small {
        width: 16px;
        height: 16px;
    }

    &__circle {
        &.mode {
            &-outline {
                background: $color-grey-bg;
                border: 1px solid var(--button-color);
            }

            &-border {
                background: $color-white;
                border-color: $color-grey-5;
            }
        }
        border: 1px solid $color-white;
        box-sizing: border-box;
        background: $color-chilli;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        transition: transform 150ms ease-in-out;

        &.large-number {
            transform: scale(1.1);
        }

        .counter.small & {
            border-width: 1.5px;
        }
    }

    &__value {
        overflow: hidden;
        border-radius: 50%;
        height: 100%;
        width: 100%;

        &__number {
            position: relative;
            color: $color-white;  
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 11px;
            opacity: 1;

            &.extra-large-number {
                font-size: 8px;
            }

            .counter.small & {
                font-size: 10px;
            }

            &.mode {
                &-outline {
                    .text {
                        color: $color-grey-5;
                    }
                }

                &-border {
                    .text {
                        color: $color-grey-5;
                    }
                }
            }

            .text {
                line-height: 0;
            }
        }
    }
}
