@import "../../common/mixins";
@import "../../common/variables";

.loading-text {
    color: transparent;
    background-color: $color-grey-0;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 18px;
    border-radius: $border-radius-xs;

    &:after {
        position: absolute;
        left: 0;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        overflow: hidden;
        content: "/";
        background-image: linear-gradient(
            110deg,
            rgba(255, 255, 255, 0) 15%,
            $color-grey-1 40%,
            $color-grey-1 60%,
            rgba(255, 255, 255, 0) 85%
        );
        animation: text-loader-indicator 2.5s ease infinite;
    }
}

@keyframes text-loader-indicator {
    0% {
        transform: translateX(-250%);
    }
    100% {
        transform: translateX(100%);
    }
}
