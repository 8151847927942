@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.another-round-carousel {
    padding: $spacing-component 0;

    &__header {
        padding: 0 $spacing-page-gutter;

        span:nth-child(2) {
            color: $color-grey-7;
        }
    }

    &__items {
        margin-top: $spacing-component-xs;
        padding: 0 $spacing-page-gutter;
        display: flex;
        overflow: auto;
        gap: $spacing-component-xs;

        @include hide-scroll-bar;
    }
}
