@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

div.CreateVerifiedAccountWizard-modal--overlay.slide-in-modal--overlay.ReactModal__Overlay--after-open {
    transform: translateZ(10px);
    z-index: 10;
}

.create-verified-account-wizard {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    @include hide-scroll-bar();
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");

    .scroll-element {
        @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));
        @include when-keyboard-open {
            padding-bottom: 0 !important;
        }
    }
    
    &__spacer {
        height: $spacing-header;
    }

    &__step {
        padding: 0 $spacing-page-gutter;
        padding-top: $spacing-header;
        color: $color-grey-8;
    }

    &__signin {
        text-align: right;
        color: $color-grey-5;
        padding: $spacing-component;

        &__action {
            color: $color-primary;
        }
    }

    .verify-page__title {
        padding-top: 0;
    }

    &-transition {
        &--push {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                transform: translateZ(10px);
                z-index: 1;

                .animated-child {
                    transform: translateX(100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
        }
        &--pop {
            &-enter {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                transform: translateZ(10px);

                .animated-child {
                    transform: translateX(-100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: initial;
                        transition: transform 250ms ease;
                    }
                }
            }
        }
    }
}
