@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__VersionMessage {
            z-index: 101;
            transform: translate3d(0, 0, 101px);
            background-color: rgba(#333333, 0.8);
            opacity: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ReactModal__Content {
                transform: none;
                opacity: 1;

                &.version-message-modal-content {
                    padding: 0;
                    flex: 0 0;
                    width: 100%;
                }
            }
        }

        &.ReactModal__account-action {
            z-index: 9;
            .account-action-modal {
                height: 100%;
                display: flex;
                align-items: flex-end;
            }
        }
    }
}

.account {
    div &__container {
        background-color: $color-white;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &__content {
            flex: 1;
            width: 100%;
            min-height: 100%;
            overflow-y: auto;
            padding: 24px;
            padding-top: 0;
            @include hide-scroll-bar;
        }
    }

    .profile-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 0;
        color: $color-grey-9;

        &__container {
            padding: 16px 0;
            border-bottom: 0.5px solid $color-grey-0;
        }

        &__name{
            word-break: break-all;
        }
    }
    .leave-table {
        padding: 0;
        padding-top: 15px;
        align-items: flex-start;
        border-bottom: none;

        &__text {
            color: $color-business;
        }
        &__description {
            color: $color-grey-5;
            display: block;
            white-space: pre-wrap;
        }
        @include svg-color($color-business);
    }

    &__item-title {
        text-transform: uppercase;
        line-height: 150%;
        color: $color-grey-4;
        margin-top: 32px;
        margin-left: -3px;
    }
    
    div &__remove {
        min-height: 50px;
        border: none;
        position: relative;

        &:first-child {
            margin-top: 30px;
        }

        &:last-child {
            margin-bottom: 20px;
        }

        .text {
            color: inherit;
        }
        @include svg-color(currentColor);

        &.disabled {
            color: $color-grey-2;
        }
        &--text-wrapper {
            position: relative;
        }
        .fade {
            position: relative;
            &-enter {
                opacity: 0;

                &-active {
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: opacity 200ms ease-in 100ms;
                }
            }

            &-exit {
                opacity: 1;
                &-active {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                    transition: opacity 200ms ease-out;
                }
            }
        }
    }

    .confirm-leave-party {
        color: $color-business;

        @include svg-color($color-business);
    }

    &__item-row {
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: $color-grey-8;
        text-decoration: none;
        padding: 15px 0;
        border-bottom: 0.5px solid $color-grey-0;

        flex-wrap: nowrap !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.disabled {
            opacity: 0.5;
        }

        svg:first-of-type {
            min-width: 15px;
            width: 18px;
            height: 22px;
            margin-right: 15px;
        }
        @include svg-color($color-grey-8);

        &--text {
            color: $color-grey-8;
            flex-grow: 2;
        }
    }

    &__credit-label {
        margin-top: 8px;
        display: flex;
        align-items: center;
        flex: 1 0 auto;

        background: $color-business;
        color: $color-text-inverse;
        padding: 4px 16px;
        border-radius: 16px;

        &__icon {
            min-width: 15px;
            width: 15px;
            height: 22px;
            margin-right: 8px;
            align-items: center;
            display: flex;

            img {
                width: 100%;
            }
        }
    }

    &__alert-modal {
        &--message {
            background: $color-white;
            margin: 40px;
            border-radius: 4px;
            padding: 6px;
        }

        &--content {
            padding: 20px;
            padding-bottom: 25px;
            color: $color-grey-8;
            text-align: center;
        }

        &--title {
            padding-bottom: 6px;
        }

        &--description {
            padding-bottom: 6px;
        }

        &--action {
            align-self: flex-end;
            border-top: 1px solid $color-grey-0;
            color: $color-grey-6;
            --button-color: #{$color-grey-6};
        }
    }

    &__signup {
        &--container {
            background-color: $color-business;
            color: $color-text-inverse;
            padding: 24px;
            @include safe-area-padding-bottom(24px);
            text-align: center;
            position: fixed;
            bottom: 0;
            z-index: 1;
            transform: translateZ(1px);

            .party-wrapper & {
                @include safe-area-padding-bottom($bottom-nav-bar);
            }

            &.hidden {
                visibility: hidden;
                position: initial;
            }
        }

        &--description {
            padding-bottom: 25px;
        }
    }

    &__action-modal {
        &--shield {
            z-index: -1;
            @include overlay;
        }

        &--message {
            width: 100%;
            background: white;
            @include safe-area-padding-bottom(0px);
        }
    }
    &__login-existing {
        text-align: right;
        color: $color-grey-5;
        padding: $spacing-component;

        &--standalone {
            padding: $spacing-component $spacing-page-gutter;
            margin: 0 -#{$spacing-page-gutter};
            border-bottom: 1px solid $color-grey-0;
        }
        &__action {
            color: $color-business;
        }
    }
    &__verify {
        background-color: $color-business-light;
        padding: $spacing-component (2 * $spacing-page-gutter);
        text-align: center;
        margin: 0 -#{$spacing-page-gutter};
        &__title {
            padding-bottom: $spacing-component;
        }
    }
    &__verified {
        color: $color-grey-5;
        padding-top: $spacing-component-xs;
        color: $color-grey-9;

        svg {
            margin-right: $spacing-component-xs;
        }
    }
}
