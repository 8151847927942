@import "src/styles/variables/variables";

.variants {
    display: flex;
    justify-content: stretch;
    padding: 0 20px;
}

.variants-dropdown__container {
    flex: 1.2;
    margin-bottom: 10px;

    + .variants-dropdown__container {
        margin-left: 30px;
    }

    .Select {
        margin-top: 0;
        border-radius: 5px;

        .Select-option {
            &.is-selected,
            &.is-focused {
                background: $color-primary-light;
            }
        }
    }

    .required-option {
        border: 3px solid $color-error;
    }

    .Select-control {
        border: solid #ededed 6px;
        padding: 10px;
    }

    .is-open .Select-control {
        border-bottom: none;
    }

    .Select.is-focused:not(.is-open) > .Select-control {
        border: solid #ababab 6px;
    }

    .Select-value {
        padding-top: 10px;
        padding-left: 20px !important;
    }

    .Select-value-label {
        font-weight: 500;
        font-size: 19px;
    }

    .Select-arrow-zone {
        position: relative;
    }

    .Select-arrow {
        background: url(../../../assets/icons/downarrow-option.svg) center no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: transform 0.2s;
        border: 0;
    }

    .Select.is-open > .Select-control .Select-arrow {
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
    }

    .Select-placeholder {
        padding-left: 20px;
    }
}

.quantityinput {
    width: 38px;
    height: 38px;
    border-radius: 27px;
    border: solid $color-primary 3px;
    text-align: center;
    font-family: "Manrope", sans-serif;
    font-size: 21px;
    color: $color-primary;
    font-weight: 500;
    margin: 0 6px;
    padding: 3px;

    &::-webkit-inner-spin-button {
        appearance: none;
    }

    &::-webkit-outer-spin-button {
        appearance: none;
    }
}

.quantityinput--container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 7px;
    margin-right: 1px;
}

.quantityinput--decrement,
.quantityinput--increment {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $color-background;
    color: #000;
    font-family: "Manrope", sans-serif;
    padding: 0;
    img {
        width: 100%;
        height: 100%;
    }
}
