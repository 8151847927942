@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.get-receipt {
    width: 100%;

    &__marketing {
        padding-top: $spacing-component-xs;
        color: $color-grey-9;

        &__description {
            color: $color-grey-5;

            &.text {
                font-weight: 400 !important;
            }

            &__link {
                color: $color-primary;
                text-decoration: none;
            }
        }
    }
}
