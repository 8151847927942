@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.drinkcard {
    display: flex;
    flex-direction: row;
    color: $color-black;
    max-height: 100%;

    > .l {
        padding-right: 8px;
        position: relative;
    }

    > .r {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    &__thumb {
        display: block;
        width: 90px;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
        max-height: 100%;

        @include respond-to(mobile) {
            margin: auto;
        }
    }

    &__thumb-wrapper {
        height: 100%;
        position: relative;
    }

    &__item-counter {
        position: absolute;
        top: $spacing-component-xxs;
        right: $spacing-component-xxs;
    }

    &__attributes {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    .rating {
        margin: ($sizePadding / 2.5) 0 0 $sizePadding;
    }

    .dietaryinfo {
        align-self: flex-start;
    }

    .price,
    .tag,
    .dietaryinfo {
        padding-top: $sizePadding / 2;
    }

    .price,
    .dietaryinfo {
        @include respond-to(mobile) {
            order: 2;
        }
    }

    .tag {
        @include respond-to(mobile) {
            order: 1;
            flex: 0 0 100%;
        }
    }
}
