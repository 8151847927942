@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.menuitemcard-image__header {
    width: 100%;
    object-fit: cover;
    max-width: 800px;
    max-height: calc(var(--device-height) * 0.45);
    height: calc(var(--device-height) * 0.45);
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.menuitemcard {
    overflow-y: scroll;
    @include hide-scroll-bar;
    padding-top: calc(var(--device-height) * 0.45 - 20px);
}

.menuitemcard-scroll {
    background: $color-white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
    transform: translateZ(1px);
    min-height: calc(100vh / 2.5); // prevent gap between scroll area and shield on iOS scroll

    padding-bottom: calc(
        #{$button-height} + #{$double-spacing-component} + #{$spacing-component-xs}
    ); // Reserve space for menu page footer

    .menu-page__menu-item-container--with-banner & {
        padding-bottom: calc(
            #{$button-height} + #{$double-spacing-component} + #{$spacing-component-xs} + #{$big-banner-height}
        );
    }

    @include when-keyboard-open {
        min-height: unset;
    }
}

.menuitemcard-content {
    padding: 24px 20px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-grey-0;

    &__member-pricing {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        padding: $spacing-component-xxs $spacing-component-xs;
        border-radius: $border-radius-small;
        align-items: center;
        background-color: $color-primary;
        color: var(--theme-button-text-color);
        @include svg-color(var(--theme-button-text-color));

        svg {
            margin-right: $spacing-component-xxs;
        }
    }
}

.menuitemcard-content__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__name {
        word-break: break-word;
        transform: translateZ(0);
        color: $color-grey-8;

        &--varietal {
            color: $color-grey-8;
        }
    }

    &__attributes {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__description--details {
        color: $color-grey-5;
        margin-bottom: 10px;
    }

    &__tags {
        .tag {
            margin-top: $spacing-component;

            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 10px;
                border: 1px solid $color-primary;
                border-radius: 10px;
                margin-right: 10px;

                svg {
                    margin-top: -1px;
                }

                @include svg-color($color-primary);
            }
        }
    }

    &__allergen {
        margin-top: $spacing-component;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $color-grey-9;
        }

        svg {
            height: 16px;
            width: 16px;
            margin-left: $spacing-component-xxs;
        }
    }
}

.menuitemcard-variants {
    &__header {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        padding-left: 20px;
        padding-right: 20px;

        .r {
            text-align: right;
            color: $color-grey-3;
            margin-left: 10px;

            &--error {
                color: $color-error;
            }
        }
    }
}

.menuitemcard-note {
    padding: 20px 20px 0;

    &__heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        position: relative;
        color: $color-grey-9;

        .menuitemcard-modifiers & {
            margin-bottom: 0;
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;
            transition: opacity 0.2s ease-in-out 0.3s;

            &__arrow {
                background: url(../../../assets/icons/downarrow.svg) center no-repeat;
                width: 15px;
                height: 15px;
                transform: rotate(0);
                transition: transform 0.2s;
                border: 0;

                &--open {
                    transform: rotate(180deg);
                }
            }
        }

        &--no-close {
            .menuitemcard-note__heading__icon {
                opacity: 0;
            }
        }
    }

    &__content {
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        &__inner {
            transform: translateY(-100%);
            transition: transform 0.2s ease-in-out;
            padding-bottom: 8px;
        }

        &--show {
            max-height: var(--menuitemcard-note-content-height, 100px);

            .menuitemcard-note__content__inner {
                transform: translateY(0);
            }
        }

        form {
            position: relative;
        }

        &__label {
            color: $color-grey-4;
            letter-spacing: 0.1em;
            position: absolute;
            top: 50%;
            right: 5%;
            transform: translate(0, -50%);
            opacity: 0;

            &--show {
                opacity: 1;
            }

            &--limit {
                color: $color-error;
            }
        }

        &__error {
            padding-top: 10px;
            color: $color-error;
            line-height: 1.2;
        }
    }
}

.menuitemcard-content__description {
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
        max-height: none;
    }

    &--text {
        @include overflow-ellipsis-multiline(2);
        color: $color-grey-5;
    }

    &--more {
        color: $color-grey-8;
        @media screen and (min-width: 600px) {
            display: none;
        }
    }

    &--expanded {
        & .menuitemcard-content__description--text {
            /* autoprefixer: off */
            display: block;
            height: 100%;
        }

        & .menuitemcard-content__description--more {
            display: none;
        }
    }
}

.menuitemcard-content__attributes {
    margin-top: 24px;
    padding: 0px 0px 25px 0px;
    height: 37px;
    display: flex;
    align-items: center;

    div {
        float: left;
    }

    .pricelist {
        &__item {
            padding-right: 10px;
        }

        .price__label {
            color: $color-primary;
        }
    }

    .tag {
        &__item {
            padding-right: 10px;
        }
    }
}

.menuitemcard {
    &.unavailable {
        .menuitemcard-image {
            opacity: 0.5;
        }
    }

    &__remove {
        padding-top: calc(#{$spacing-page-gutter} * 2);

        &__button.button-element {
            @include svg-color($color-error);
            margin-bottom: $spacing-component;

            svg {
                margin-right: $spacing-component-small;
            }

            &:not(.disabled) {
                color: $color-error;
            }
        }
    }
}

.menuitemcard-modifiers {
    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 300ms ease-in;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                opacity: 0;
                transition: opacity 300ms ease-out;
            }
        }
    }
}

.menu-page {
    .menuitemcard {
        .fixed-notification:last-child {
            margin-bottom: 20px;
        }
    }
}

.ReactModalPortal {
    .ReactModal__Overlay {
        &.menuitem-modifiers__modal__overlay {
            @include safe-area-padding-top(0px);

            .ReactModal__Content {
                &.menuitem-modifiers__modal {
                    flex: none;
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    padding: 0;
                    @include safe-area-padding-top(0px);
                }
            }
        }
    }
}
