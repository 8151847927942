@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";
@import "src/styles/variables/_variables";

.membership-cta {
    background-color: $color-primary;
    border-radius: $border-radius-normal;
    padding: $spacing-component;
    color: $color-white;
    color: var(--theme-button-text-color);
    display: flex;
    align-items: center;
    @include svg-color(var(--theme-button-text-color));

    &.membership-cta--inverse {
        background-color: $color-white;
        color: $color-primary;
        @include svg-color($color-primary);
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            background: $color-primary;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0.07;
        }
    }

    &__icon {
        align-self: center;
    }

    &__text {
        padding-right: $spacing-component;

        .text + .text {
            margin-top: $spacing-component-xs;
        }
    }

    svg, img {
        margin-right: $spacing-component;
        display: block;
    }

    svg {
        height: 24px;
        width: 24px;
    }

    img {
        height: 48px;
        width: 48px;
    }

    &__privacy-policy{
        margin-top: $spacing-title;
        &__link {
            color: $color-clickable;
            text-decoration: none;
        }
    }
}
