@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.limited-menu-package-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-0;
    padding: $spacing-component $spacing-page-gutter;

    &__description {
        color: $color-grey-4;
    }

    &__left {
        display: flex;
        flex-direction: column;
        color: $color-grey-8;
    }

    &__right {
        margin-left: 16px;
        @include svg-color($color-grey-2);
    }
}
