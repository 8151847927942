.menuitemcard-video {
    background-image: var(--video-background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 800px;
    height: calc(var(--device-height) * 0.45);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &__header {
        object-fit: cover;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        &.fade-in {
            opacity: 1;
        }
    }
}