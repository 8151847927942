@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.collect-order-modal--overlay {
            z-index: 100;
            transform: translateZ(100px);
            background: transparent;
            align-items: flex-end;
            display: flex;
        }
    }
}

.collect-order-modal {
    background: $color-white;
    color: $color-grey-9;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    padding: 0 16px;
    box-shadow: 0px -4px 8px #{$color-shadow};
    border-radius: 8px 8px 0px 0px;
    max-height: calc(99% - #{$simple-nav-header-height});
    max-height: calc(99% - #{$simple-nav-header-height} - constant(safe-area-inset-bottom));
    max-height: calc(99% - #{$simple-nav-header-height} - env(safe-area-inset-bottom));

    .shield {
        z-index: -1;
        opacity: 0;
        background: rgba(0, 0, 0, 0.8);
        transition: opacity 150ms 250ms ease;
    }

    .collect-order {
        &__scroll {
            @include hide-scroll-bar;
            overflow-y: auto;
            padding-top: 36px;
            @include safe-area-padding-bottom(24px);
        }
        &__fade-scroll {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 36px;
            border-radius: 8px 8px 0px 0px;
            background: linear-gradient(
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 30%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        &__title {
            margin-bottom: 4px;
            margin-right: 21px;
        }
        &__subtitle {
            color: $color-grey-6;
            margin-right: 21px;
        }
        &__separator {
            border: 1px solid $color-grey-0;
            margin-top: 24px;
            margin-bottom: 16px;

            .dark-mode & {
                border-color: transparent;
            }
        }

        &__header {
            background-color: $color-white;
            color: $color-grey-9;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &--title {
                opacity: 0.4;
                color: $color-grey-9;
                text-transform: uppercase;
            }

            &--code {
                padding-bottom: 24px;
                text-align: center;
                text-transform: uppercase;
            }

            &--meta {
                display: flex;
                justify-content: center;
                width: 100%;

                &--container {
                    display: flex;
                    align-items: center;
                    margin: 0 15px;

                    &--icon {
                        display: flex;
                        margin-right: 19px;

                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }
                    &--details {
                        display: flex;
                        flex-direction: column;

                        &--value {
                            color: $color-grey-9;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        &__location-details {
            margin-top: $spacing-component
        }

        &__collection-instructions {
            margin-top: $spacing-component;

            &--description {
                color: $color-grey-5;
            }
        } 

        &__items {
            flex: 1;
            padding-bottom: $button-height + (2 * $spacing-page-gutter);

            .history-item__section {
                box-shadow: initial;
                margin-bottom: 0;

                .order-items {
                    padding: 0;
                }
            }
        }
    }

    footer {
        padding: $spacing-page-gutter;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: $button-height + (2 * $spacing-page-gutter);
        background-color: $color-white;
        @include safe-area-padding-bottom(0px);
    }
}
