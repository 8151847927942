@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.get-help-button {
    a {
        height: 100%;
        width: 100%;
        @include flex-center;
        color: inherit;
        text-decoration: none;
    }
}
