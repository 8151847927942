$sizePadding: 10px;
$sizePaddingMobile: 14px;
$sizeIcon: 12px;
$sizeIconMedium: 20px;
$sizeIconBig: 22px;

$borderRadius: 12px;

/* Colours */
$color-business: #7b8daf;
$color-primary: var(--theme-primary-color);
$color-primary-light: #e7ebf0;
$color-primary-text: var(--theme-primary-text-color);
$color-background: #ffffff;
$color-shadow: rgba(79, 86, 96, 0.15);
$color-text: #000000;
$color-lighter-text: #333333;
$color-text-inverse: #ffffff;
$color-text-inactive: #a4a6ad;
$color-subtext: #bdbdbd;
$color-black: #17181a;
$color-light: #ffffff;
$color-light-gray: #f8f9f9;
$color-label-gray: #8f929a;
$color-gray: #747f8d;
$color-border: #e0e0e0;
$color-success: #247736;
$color-inactive: #e3e5e8;
$color-error: #d94029;
$colorRed: var(--theme-primary-color);
$colorBorder: #ededed;
$color-button: var(--theme-primary-color);
$color-button-inactive: #ababb0;
$beacon-background-color: #b7c1d5;
$color-clickable: #7b8daf;
$color-green: #4cd964;
$dark-mode: #2b2c37;
$color-darkest-gray: #2b2c37;
$color-dark-gray: #4f5660;
$color-error-banner: #fa541c;

$reference-width-map: (
    iphonexs: 375,
);

@function get-vw($font-size, $reference-width: iphonexs, $apply-fudge: true) {
    $vw-context: (map-get($reference-width-map, $reference-width) * 0.01) * 1px;
    @if $apply-fudge {
        @return (($font-size + 1) * (15 / 14) / $vw-context) * 1vw;
    } @else {
        @return (($font-size + 1) / $vw-context) * 1vw;
    }
}

/* Typography */
$font-size-base: 16px !default;
$font-size-xl: 23px !default;
$font-size-lg: 19px !default;
$font-size-sm: 13px !default;
$font-size-xs: 11px !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

/* Layout */
$view-padding-x: 20px;

$orientations: (
    landscape: row,
    portrait: column,
);
