@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.refresh-button {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 300ms ease-in-out;
        pointer-events: none;

        &.show {
            opacity: 1;
            pointer-events: initial;
        }
    }

    &__button {
        width: fit-content;
        min-height: inherit;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        border-radius: $border-radius-normal;
        padding: $spacing-component-xs;
        align-items: center;
        justify-content: center;
        display: flex;
        border: none;

        svg {
            top: 0;
            margin-left: 0;
            margin-right: $spacing-component-xs;
        }

        &.light {
            background-color: $color-grey-bg;
            color: $color-grey-5;
            @include svg-color($color-grey-5);
        }

        &.dark {
            background-color: $color-black;
            color: $color-white;
            @include svg-color($color-white);
        }
    }
}
