@import "../../../sharedComponents/common/variables";
@import "../../../sharedComponents/common/mixins";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__MembershipScanBarcode {
            background: transparent;
            transform: translateZ(11px);
            z-index: 11;
        }
    }
}

.membership-scan-barcode {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    #reader {
        background: transparent;
    }

    &__icon {
        display: flex;
    }

    &__bottom {
        padding: 0 $spacing-page-gutter;
        @include safe-area-padding-bottom(0px);

        &__button {
            transform: translateZ(0);
            margin-bottom: $spacing-page-gutter;
            --button-color: #{$color-white};
        }
    }

    .fade {
        &-enter {
            opacity: 0;
            &-active {
                opacity: 1;
                transition: opacity 200ms ease-in;
            }
        }
    }
}

.scanning-barcode {
    #root,
    .ReactModal__ManageMemberships,
    .PaymentWizard-modal--overlay,
    .GroupTabsWizard-modal--overlay,
    .membership-signup-modal {
        display: none;
    }
}
