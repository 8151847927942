@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.pay-only-party {
    .payment-methods__title {
        padding: $spacing-component-small $spacing-page-gutter;
    }

    .payment-method.selected:not(.change) {
        background: $color-white;
    }

    .payment-methods__submit {
        padding: 0;
        @include safe-area-padding-bottom(0px);

        &__container {
            padding: $spacing-component-small $spacing-page-gutter;
            border-top: 1px solid $color-grey-0;

            .no-connectivity-banner + & {
                border: 0;
            }
        }
    }
}

.PayOnlyStateWizard-modal__Body--open {
    .PayOnlyPaymentWizard-modal__Overlay {
        --animation-z: 0px !important;
    }
}

.ReactModalPortal .Wizard-modal__Overlay.PayOnlyPaymentWizard-modal__Overlay.ReactModal__Overlay {
    &--after-open {
        .sso-complete & {
            // temporarily position this above the SignInWizard while
            // we're animating after SignInWizard has completed
            z-index: 101;
            transform: translateZ(101px);
        }
    }

    .pay-only-header__height {
        @include safe-area-padding-top($simple-nav-header-height);
    }
}
