@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.member-price-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background: $color-primary;
    border-radius: 50%;
    margin-right: $spacing-component-xxs;
    @include svg-color(var(--theme-button-text-color));

    svg {
        width: 10px;
        padding-top: 1px;
    }
}
