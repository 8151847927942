/* Inconsolata Regular */
@font-face {
    font-family: "Inconsolata";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./inconsolata/InconsolataRegular.woff2") format("woff2"),
        url("./inconsolata/InconsolataRegular.woff") format("woff"),
        url("./inconsolata/InconsolataRegular.ttf") format("truetype");
}
/* Inconsolata Bold  */
@font-face {
    font-family: "Inconsolata";
    font-style: normal;
    font-weight: 600;
    src: local(""), url("./inconsolata/InconsolataBold.woff2") format("woff2"),
        url("./inconsolata/InconsolataBold.woff") format("woff"),
        url("./inconsolata/InconsolataBold.ttf") format("truetype");
}
