@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.ReactModalPortal {
    .ReactModal__Overlay {
        &.ReactModal__HeadCountSelection {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            z-index: 10;
            transform: translateZ(10px);

            .order-head-count-modal {
                @include animate-modal(slide-up, 150ms, ".order-head-count");
            }
        }
    }
}

.order-head-count {
    width: 100%;
    padding: $spacing-header $spacing-page-gutter;
    @include safe-area-padding-bottom($spacing-page-gutter);
    max-height: fit-content;
    background-color: $color-white;
    border-top-left-radius: $border-radius-normal;
    border-top-right-radius: $border-radius-normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__title {
        margin: $spacing-component 0 $spacing-component-xs;
    }

    &__subtitle {
        text-align: center;
    }

    .fade {
        &-enter {
            opacity: 0;

            &-active {
                opacity: 1;
                transition: opacity 250ms ease-in;
            }
        }

        &-exit {
            opacity: 1;

            &-active {
                position: absolute;
                padding: 0 $spacing-page-gutter;
                opacity: 0;
                transition: opacity 250ms ease-out;
            }
        }
    }

    .quantity-selector {
        margin: $spacing-component 0 48px;
    }
}