@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.group-tab-history-tile {
    padding: 16px 20px;
    width: 100%;
    height: 76px;

    &__first-line {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-component-xxs;
    }
}
