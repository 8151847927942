@import "variables/variables";
@import "mixins/mixins";
@import "src/sharedComponents/styles/animations/animations";
@import "utilities/utilities";
// Reset and base files
// --------------------
@import "base/base";
// Layouts
// -------
@import "layout/layout";
// Objects
// ----------
@import "objects/typography";
// Components
// ----------
@import "src/sharedComponents/styles/components/react-modal";
@import "components/react-tabs";
@import "components/foodcard";
@import "components/drinkcard";

@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

:root {
    --color-business: #{$color-business};
    --color-text: #{$color-text};
    --color-text-inverse: #{$color-text-inverse};
    --theme-primary-color: #{$color-business};
    --theme-primary-text-color: #{$color-text-inverse};
    --theme-button-text-color: var(--color-text-inverse);
}

html,
body {
    height: 100%;
    color: $color-text;
    @include hide-scroll-bar;
    --document-height-initial: 0px;
    --document-height-diff: 0px;

    &.disable-body-scroll {
        overflow: hidden;
    }
}

body {
    -webkit-text-size-adjust: none;

    &.scroll-root {
        @include use-100vh(height);

        .MenuItemModalPortal {
            @include use-100vh(max-height);
            overflow-y: scroll;
            @include hide-scroll-bar;
        }

        &.show-banner,
        &.hide-banner {
            .nav-bar {
                bottom: calc(100vh - var(--device-height));
            }
        }
    }

    &.show-banner {
        --animation-element-height: -#{$small-banner-height};
        animation: move-to-position 150ms ease-in-out;

        .nav-bar {
            bottom: calc(100% - var(--device-height));
            --animation-element-height: #{$small-banner-height};
            animation: move-to-position 150ms ease-in-out;
        }
    }

    &.hide-banner {
        --animation-element-height: -#{$small-banner-height};
        animation: move-to-position-reverse 150ms ease-in-out;

        .nav-bar {
            bottom: calc(100% - var(--device-height));
            --animation-element-height: #{$small-banner-height};
            animation: move-to-position-reverse 150ms ease-in-out;
        }
    }
}
#rotate {
    height: 100%;
}

#root {
    display: block;

    .scroll-root & {
        @include use-100vh(height);
        overflow-y: scroll;
        @include hide-scroll-bar;
    }

    :not(.native) .keyboard-open.scroll-root & {
        height: 100%;
    }

    @include hide-when-keyboard-open(scrollRoot, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".SecuredPaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".GroupTabsWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".CreateVerifiedAccountWizard-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".Marketing-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".AccountDetails-modal__Body--open");
    @include hide-when-keyboard-open(scrollRoot, ".SignUpMemberships-modal__Body--open");

    .background-blur & {
        filter: blur(20px);
    }
}

#rotate {
    display: none;
}

.mobile {
    // we use aspect ratio (instead of orientation) to differentiate between landscape and keyboard open (on some devices)
    @media screen and (min-aspect-ratio: 15/9) {
        #root {
            display: none;
        }

        .ReactModal__Overlay {
            display: none;
        }

        #rotate {
            display: block;
        }
    }
}

.party-wrapper {
    display: flex;
    flex-direction: column;
    @include overlay;
    @include hide-when-keyboard-open(all, ".PaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SecuredPaymentWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".GroupTabsWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SignInWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".CreateVerifiedAccountWizard-modal__Body--open");
    @include hide-when-keyboard-open(all, ".ManageMemberships-modal__Body--open");
    @include hide-when-keyboard-open(all, ".SignUpMemberships-modal__Body--open");
    @include when-keyboard-open(all, ".PayOnlyPaymentWizard-modal__Body--open") {
        min-height: initial;
    }

    &.blur {
        filter: blur(3px);
    }

    &__page {
        flex: 1;
        .scroll-root & {
            position: relative;
        }
    }

    .animate-visibility-in,
    .animate-visibility-out {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
    }

    &-transition {
        &-enter {
            opacity: 0;
            &-active {
                opacity: 1;
                transition: opacity 150ms ease-in;
            }
        }

        &-exit {
            opacity: 0;
            &-done {
                opacity: 0;
            }
        }
    }
}

.cta {
    @include clear;
    padding: $sizePadding 30px;
    border: 0;
    border-radius: 5px;
    outline: 0;
    background-color: $colorRed;
    color: $color-light;
    font-weight: 500;
    font-size: get-vw($font-size-base);
    cursor: pointer;
}

.foodlisting,
.drinklisting {
    @include clear;
    list-style-type: none;
}

.pricelist {
    @include clear;
    list-style-type: none;
    font-size: 0;

    &__item {
        padding: 0 16px 0 0;
        display: inline-block;
        margin: 0;

        @include respond-to(iPhone-small) {
            padding: 0 10px 0 0;
        }
    }
}

.price {
    display: inline-block;
    font-size: get-vw(18px);
    font-weight: 500;
    line-height: 1;

    @include respond-to(mobile) {
        font-size: get-vw(18px);
    }

    &--decimals {
        font-size: get-vw($font-size-xs);

        @include respond-to(mobile) {
            font-size: get-vw($font-size-sm);
        }
    }

    &-per-person__pp {
        margin: 0 12px 0 3px;
        font-size: get-vw(17px);
        font-weight: 500;
        line-height: 1;
    }
}

.tag {
    @include clear;
    list-style-type: none;
    font-size: 0;
    display: flex;
    flex-wrap: nowrap;

    &__item {
        padding: 0 16px 0 0;
        float: left;
        display: flex;
        align-items: center;
        font-size: get-vw(11px);
        font-weight: bold;
        color: $colorRed;

        @include respond-to(mobile) {
            font-size: get-vw($font-size-xs);
        }

        img,
        svg {
            width: $sizeIcon;
            height: $sizeIcon;
            margin-right: 5px;
        }
    }
}

.dietaryinfo {
    @include clear;
    list-style-type: none;
    font-size: 0;
    display: flex;
    flex-wrap: nowrap;

    &__item {
        padding: 0 ($sizePadding / 2.5) 0 0;
        display: inline-block;

        svg {
            width: 16px;
            height: 16px;
        }
        @include svg-color($color-black);
    }
}

.rating {
    display: flex;
    align-items: center;

    &__icon {
        width: $sizeIcon;
        height: $sizeIcon;
        margin-right: 8px;
    }

    &__text {
        line-height: 1;
        font-size: get-vw(15px);
        font-weight: 500;
        color: $color-gray;
    }
}

.menuitemcard-link {
    text-decoration: none;
    display: block;
    color: initial;
    width: 100%;

    &:active {
        opacity: 0.7;
    }
}

.scrollable-page {
    overflow: scroll;
}

// Text

.grey {
    a {
        text-decoration: none;
        color: $color-business;
    }
}

.grey--disabled {
    opacity: 0.5;
}

.button {
    width: 100%;
    background: $color-button;
    color: $color-text-inverse;
    border: 0;
    border-radius: 4px;
    font-family: "Manrope", sans-serif;
    font-size: get-vw(13px);
    padding: 16px 0;
    cursor: pointer;
    font-weight: $font-weight-medium;
    line-height: 1.6em;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;

    &--light {
        background-color: #fff !important;
        color: $color-button !important;
        border: 1px solid $color-button !important;
        padding: 15px 0;
    }

    &[disabled] {
        filter: opacity(70%);
    }

    &:active {
        opacity: 0.7;
    }

    svg {
        position: relative;
        top: 3px;
    }
}

.medium-button {
    padding: 10px;
    height: 83px;
    display: flex;
    justify-content: center;
}

.Select {
    width: 100%;
    overflow: hidden;

    .Select-value-label {
        font-size: get-vw($font-size-base);
        color: $color-gray;
        font-weight: $font-weight-medium;
    }

    &.Select--single {
        > .Select-control {
            border: 0 !important;
            box-shadow: none !important;
            height: 42px;
            padding: 0;

            .Select-value {
                padding: 0;
                top: 50%;
                height: 42px;
                margin-top: 4px;
                transform: translateY(-50%);
                font-family: "Manrope", sans-serif;
                margin-right: 24px;
                padding-left: 16px;

                .Select-value-label {
                    font-size: $font-category;
                }
            }
        }
    }

    .Select-menu-outer {
        border: 3px solid $color-border;
        border-top: 0;
        width: calc(100% + 6px);
        left: -3px;
        position: relative;
        border-bottom: 0;
    }

    .Select-option {
        padding: 15px 30px;
        font-family: "Manrope", sans-serif;
        font-size: get-vw(15px);
        border-top: 1px solid $color-border;
    }

    span.Select-arrow-zone {
        padding-right: 20px;
        width: 70px;
        text-align: right;

        img {
            width: 12px;
        }
    }
}

.tagline {
    font-size: get-vw(13px);
    font-weight: 500;
    margin-top: 10px;

    svg {
        width: 13px;
        position: relative;
        top: 1px;
        margin-right: 7px;
    }
}

.gradient {
    color: white;
    @include gradient();
}

.bg-white {
    background-color: white;
}

.error {
    position: relative;
    color: $color-error;
}

header {
    .left,
    .right {
        flex: 1;
        position: relative;
        height: 100%;
    }

    .left {
        display: flex;
        align-items: center;
    }

    h1 {
        font-size: get-vw(15px);
        color: black;
    }
}

.location-color {
    background-color: $color-primary;

    &.inactive {
        background-color: #f7f7f7;
        color: black;
    }
}

.location-color-text {
    color: $color-primary;

    svg {
        g {
            stroke: $color-primary;
        }
    }
}

.border-bottom {
    border-bottom: 2px solid $color-border;
}

.border-top {
    border-top: 2px solid $color-border;
}

.border-items {
    border-bottom: 2px solid $color-border;

    &:last-of-type {
        border-bottom: none;
    }
}
.no-border-items {
    margin-bottom: 15px;
}

.success-icon {
    border: 3px solid $color-text;
    color: $color-text;
    border-radius: 50%;
    font-weight: bold;
    font-size: get-vw(25px);
    height: 44px;
    width: 44px;
    line-height: 2;
    display: inline-block;
}

.pointer-cursor {
    cursor: pointer;
}

.swiper-wrapper {
    align-items: flex-start;
}

.bottom-shadow:after {
    content: "";
    position: absolute;
    width: 100%;
    z-index: -1;
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.1);
}

.top-shadow {
    box-shadow: 0px -1px 4px rgba(227, 229, 232, 0.5);

    @include when-keyboard-open {
        box-shadow: none;
    }
}

.list-row {
    img {
        margin-right: 17px;
    }

    color: black;
    text-align: left;

    div {
        flex-grow: 2;
        font-weight: 500;
        font-size: get-vw(12px);
        line-height: get-vw(17px);
    }

    svg {
        &:first-of-type {
            width: 22px;
            height: 22px;
            margin-right: 15px;
        }
    }
}

a.list-row {
    text-decoration: none;
}

.fixed-at-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    @include safe-area-padding-top(0px);
    background: white;
    height: 0;

    &.no-background {
        background: transparent;
    }

    &.gradient-background {
        background: linear-gradient(0, transparent, black);
    }
}

.fixed-at-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
}

.wrapper-for-fixed-nav {
    @include safe-area-padding-top($simple-nav-header-height);
}

.error {
    color: $color-error;

    label {
        color: $color-error;
    }

    input {
        border-color: $color-error;
    }
}

.auth-error {
    color: $color-error;

    label {
        color: $color-error;
    }

    input {
        border-color: $color-error !important;
    }
}

.error-wrapper {
    color: $color-error;
    height: 66px;
    padding: 10px 0 10px 33px;
    display: flex;
    align-items: center;
    position: relative;
    // top: -43px;
    // left: 0;
    // right: 0;
    line-height: 1.5;
    font-size: get-vw($font-size-sm);

    img {
        position: absolute;
        top: 34%;
        left: 0;
    }
}

.success {
    input {
        border-color: $color-success;
    }
}

.relative {
    position: relative;
}

.emoji {
    position: relative;
    top: 1px;
    margin: 0 7px;
    display: inline-block;
}
