@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.package-menu {
    animation: fade-in 200ms ease;

    &-category {
        padding: $double-spacing-component $spacing-page-gutter $spacing-component;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &__name {
            flex: 2;
        }

        &__item-count {
            width: 24px;
            height: 24px;
            background: $color-grey-bg;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-small;
            margin: 0 $spacing-component 0 $double-spacing-component;
        }

        &__icon {
            height: 24px;
            display: flex;
            align-items: center;

            svg {
                transition: transform 200ms ease;
                transform: rotate(90deg);
            }
        }

        &__data {
            padding: 0 $spacing-page-gutter;
            max-height: 0;
            transition: max-height 200ms ease;
            overflow: hidden;
            border-bottom: 1px solid $color-grey-0;
        }

        &.show-category {
            svg {
                transform: rotate(-90deg);
            }

            & ~ .package-menu-category__data {
                max-height: var(--category-height);
            }
        }
    }
}

.package-menu-items {
    min-height: var(--category-height);
    padding-bottom: 1px; // to fix the missing margin at the bottom of the items
}

.package-menu-item-row {
    display: flex;
    align-items: center;
    padding: $spacing-component-small;
    padding-right: 0;
    margin-bottom: $spacing-component;

    &__name {
        @include overflow-ellipsis-multiline(2);
    }

    &__variants {
        @include overflow-ellipsis-multiline(2);
        color: $color-grey-5;
    }

    &__image {
        width: 80px;
        min-width: 80px;
        margin-right: $spacing-component;

        img {
            min-height: 55px;
            border-radius: $border-radius-small;
        }

        .drinkcard__thumb {
            width: 45px;
        }
    }
}
