@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.SignInWizard-modal__Body--open {
    overflow: hidden;
}

div.verify-wizard {
    &--overlay.ReactModal__Overlay--after-open {
        .ReactModalPortal & {
            z-index: 110;
            transform: translateZ(110px);
            .ManageMemberships-modal__Body--open.sso-complete & {
                display: none;
            }
        }
    }

    &__content {
        overflow-y: scroll;
        @include hide-scroll-bar();
        height: 100%;
    }

    &-transition {
        &--push {
            &-enter {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                transform: translateZ(10px);
                z-index: 1;

                .animated-child {
                    height: calc(100% - #{$simple-nav-header-height});
                    height: calc(100% - (#{$simple-nav-header-height} + constant(safe-area-inset-top))); //for iOS 11.1
                    height: calc(100% - (#{$simple-nav-header-height} + env(safe-area-inset-top))); //for iOS 11.2
                    transform: translateX(100vw);
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transition: transform 250ms ease;
                        transform: initial;
                    }
                }
            }
            &-exit {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: 1;
                transform: translateZ(10px);

                .animated-child {
                    height: calc(100% - #{$simple-nav-header-height});
                    height: calc(100% - (#{$simple-nav-header-height} + constant(safe-area-inset-top))); //for iOS 11.1
                    height: calc(100% - (#{$simple-nav-header-height} + env(safe-area-inset-top))); //for iOS 11.2
                    transform: initial;
                    background-color: $color-white;
                }

                &-active {
                    .animated-child {
                        transform: translateX(100vw);
                        transition: transform 250ms ease;
                    }
                }
            }
        }
    }

    .account-details {
        height: calc(100% - #{$simple-nav-header-height});
        height: calc(100% - (#{$simple-nav-header-height} + constant(safe-area-inset-top))); //for iOS 11.1
        height: calc(100% - (#{$simple-nav-header-height} + env(safe-area-inset-top))); //for iOS 11.2

        &__submit {
            box-shadow: none;
        }

        .scroll-element {
            @include safe-area-padding-bottom($button-height + (2 * $spacing-page-gutter));

            .incorrect-document-height & {
                @include safe-area-padding-bottom(calc(var(--document-height-diff) + #{$button-height + (2 * $spacing-page-gutter)}));
            }

            @include when-keyboard-open {
                padding-bottom: 0 !important;
            }
        }
    }
}
