@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.ready-members-indicator {
    background-color: $color-primary;
    text-align: center;
    position: fixed;
    left: $spacing-page-gutter;
    right: $spacing-page-gutter;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    @include safe-area-padding-bottom(#{$spacing-component-xs}, bottom);
    transition-property: transform, opacity;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    border-radius: $border-radius-normal;
    transform: translateY(0%);
    height: $button-height-small;
    opacity: 0;

    >.text {
        color: var(--theme-button-text-color);
    }

    &.active {
        transform: translateY(-100%);
        opacity: 1;
    }
}