@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";
@import "src/styles/variables/variables";

.lobby {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include safe-area-padding-top(0px);
    display: flex;
    background-color: $color-background;
    
    main {
        margin-bottom: calc(#{$button-height} * 2);
        padding-bottom: $spacing-component;
        text-align: center;
        padding: $spacing-header $spacing-page-gutter 0;
    }
    
    &__title {
        color: $color-black;
        padding: $spacing-header $spacing-page-gutter;
    }
    
    &__description {
        color: $color-grey-7;
        margin-top: $double-spacing-component;
    }

    &__ordering-unavailable {
        color: $color-warning;
        padding-bottom: $spacing-component;
    }

    footer {
        padding: $spacing-page-gutter;
        text-align: center;
        @include safe-area-padding-bottom($spacing-component);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        
        button {
            &:last-child {
                margin-top: $spacing-component;
            }
        }
    }
}
