@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.group-tab-name {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;

    .form-control {
        padding: 0 $spacing-page-gutter;
    }

    &__disclaimer {
        padding: $spacing-page-gutter;
    }

    footer {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        @include when-keyboard-open {
            position: relative;
        }
    }
}
