@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.order-items {
    flex: 1;
    padding: $spacing-component $spacing-page-gutter;
    display: grid;
    grid-template-columns: fit-content(90%) minmax(0, 1fr) fit-content(90%);
    grid-column-gap: $spacing-component;
    align-items: flex-start;
    background-color: $color-white;
    transition: background-color 0.1s ease-in;
    color: $color-grey-7;

    &.active {
        background-color: $color-grey-bg;
    }
}

.order-item {
    word-break: break-word;

    &__botton {
        display: contents;
    }

    &__quantity {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-grey-bg;
        border-radius: $border-radius-xs;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: $spacing-component-xxs;

        &__primary {
            min-height: 20px;
            align-items: center;
            padding-top: $spacing-component-xxxs;
        }

        &__secondary {
            color: $color-grey-6;

            .order-item__error & {
                color: $color-error;
            }
        }
    }

    &__quantity-selector {
        width: 72px;
        padding-top: $spacing-component-xxxs;
    }

    &__right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: $spacing-component-xxs;

        .wait-time-badge {
            position: inherit;
            left: 0;
            bottom: 0;
        }
    }

    &__error {
        color: $color-error;
    }

    &__remove {
        grid-column: 1 / span 3;
        margin: 0 $spacing-component-small;
        padding-top: $double-spacing-component;
        display: flex;
        justify-content: center;

        &__content {
            padding: $spacing-component-xs $spacing-component;
            color: $color-error;
            background-color: $color-error-light;
            border-radius: $border-radius-normal;
        }

        svg {
            margin-right: $spacing-component-small;
        }

        @include svg-color($color-error);
    }

    &-transition {
        &-exit {
            &-active {
                .order-item,
                .order-item__remove {
                    opacity: 0;
                    transition: opacity 200ms ease;
                }
            }
        }
    }

    &__swipe-to-delete {
        margin-bottom: $spacing-component-xxs;

        .js-delete {
            background: $color-chilli;
        }

        .js-delete,
        .js-content {
            z-index: auto !important;
        }

        .js-transition-delete-right,
        .js-transition-delete-left,
        .js-transition-cancel {
            transition-duration: 0.2s;
        }
    }

    &__swipe-remove {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 0;
        right: -100%;
        padding-left: 18px;
        color: $color-white;

        svg {
            display: block;
            margin-right: 10px;
        }
    }

    &--deleted {
        animation: slideItemsUp 0.1s ease-in forwards;
    }
}

@keyframes slideItemsUp {
    0% {
        min-height: var(--order-item-height);
    }
    100% {
        min-height: 0;
    }
}

// using conic gradient as there is a bug with display content on ios before 12
@supports not (background: conic-gradient(from 0.25turn at 50% 30%, #f69d3c, 10deg, #3f87a6, 350deg, #ebf8e1)) {
    .ios {
        .order-items {
            display: block;
        }
        .order-item__botton .order-item__botton {
            display: grid;
            grid-template-columns: fit-content(90%) minmax(0, 1fr) fit-content(90%);
            grid-column-gap: $spacing-component;
            align-items: center;
        }
    }
}
