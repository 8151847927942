@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.group-tab-limit {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;

    .form-control {
        padding: 0 $spacing-page-gutter;
    }

    .scroll-element {
        margin-bottom: $spacing-page-gutter;
    }

    &__hidden-input {
        position: absolute;
        z-index: -1;
        margin-top: $spacing-component;
        padding-left: $spacing-page-gutter;
        font-size: $font-content;
    }

    &__disclaimer {
        padding: $spacing-page-gutter;
        color: $color-grey-5;
    }

    &__button-placeholder {
        visibility: hidden;
        margin: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter, margin-bottom);
    }

    &__amount-tags {
        display: flex;
        flex-wrap: wrap;
        padding: $spacing-component-xs $spacing-page-gutter $spacing-component-xs;
        margin-bottom: $spacing-header;

        &__tag {
            border-radius: $border-radius-xxl;
            padding: $spacing-component-xs $spacing-component-xxs;

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    footer {
        padding: $spacing-page-gutter;
        @include safe-area-padding-bottom($spacing-page-gutter);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
        @include when-keyboard-open {
            position: relative;
        }
    }

    &__action-button {
        white-space: pre;
    }
}
