@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.group-tab-menu-cta {
    background-color: $color-primary;
    border-radius: $border-radius-normal;
    padding: $spacing-page-gutter;
    color: $color-white;
    color: var(--theme-button-text-color);
    display: flex;
    align-items: center;
    @include svg-color(var(--theme-button-text-color));

    &__text {
        padding-right: $spacing-component;
    }

    svg {
        margin-right: $spacing-component;
        height: 100%;
        width: 30px;
        display: block;
    }
}
