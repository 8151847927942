@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.menu-search-button {
    cursor: pointer;
    opacity: 0;
    min-width: 22px;
    min-height: 30px;

    &__show {
        opacity: 1;
    }

    @include svg-color($color-black);

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
    }
}
