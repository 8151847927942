@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.unsupported-page {
    background: $color-kale;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    
    &--location-info {
        background: $color-black;
    }

    &__content {
        padding: 0 28px 0 20px;
        color: $color-text-inverse;
        height: calc(100vh - 106px);

        .unsupported-page {
            display: flex;
            flex-direction: column;

            &__section {
                display: flex;
                flex-direction: column;
            }

            &--location-info {
                &__section {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .text {
                        text-align: center;
                    }
                }

                &__logo {
                    height: 20%;
                    max-height: 168px;
                    display: flex;
                    margin-bottom: $spacing-page-gutter;
                    @include svg-color($color-white);
                    svg {
                        height: 100%;
                        width: 100%;
                    }
                }

                &__qr-code {
                    border: 8px solid $color-white;
                    margin-bottom: $spacing-page-gutter;
                }
                
            }

            &__browser-icons {
                display: flex;
                margin-bottom: $spacing-component-xs;
        
                svg {
                    display: block;
                    + svg {
                        margin-left: $spacing-component-xs;
                    }
                }
            }
        }
        
    }   

}
