@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.menu-item-footer {
    display: flex;
    padding: $spacing-component-small 0;
    align-items: center;
    background-color: $color-white;
    z-index: 1;
    transform: translateZ(0);
    @include safe-area-padding-bottom($spacing-page-gutter);

    .ios.web & {
        // Add extra space as safari bottom navbar safe click area 50px
        padding-bottom: calc(#{$spacing-page-gutter} + (constant(safe-area-inset-bottom) * 1.5)); //for iOS 11.1
        padding-bottom: calc(#{$spacing-page-gutter} + (env(safe-area-inset-bottom) * 1.5)); //for iOS 11.2
    }

    &__quantity-selector {
        padding-left: $spacing-component;
    }

    &__action {
        padding: 0 $spacing-page-gutter;
        width: 100%;

        &--error.button-element {
            background: $color-error;
            border-color: $color-error;
        }

        &__remove {
            display: flex;

            svg {
                margin-right: $spacing-component-small;
            }
        }
    }
}
