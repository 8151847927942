@import "src/styles/variables/variables";
@import "src/sharedComponents/common/variables";

.takeaway-schedule {
    &__container {
        height: $button-height;
        display: flex;
        background-color: $color-grey-bg;
        margin: 0 $spacing-page-gutter;
        padding: $spacing-component-xxs;
        border: 1px solid $color-grey-0;
        border-radius: $border-radius-normal;
    }

    &__button {
        height: 100%;
        margin-left: 2px;
        transition: background-color 150ms ease-in ;
        padding: 0 $spacing-component-xs;

        &:first-of-type {
            margin-right: 2px;
            margin-left: 0px;
        }

        &.inactive {
            color: $color-grey-9;
        }
        
    }
}