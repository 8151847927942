@import "src/sharedComponents/common/variables";
@import "src/sharedComponents/common/mixins";

.offer-tile {
    display: flex;
    max-width: 100%;
    border: 1px solid $color-grey-2;
    position: relative;
    border-radius: $border-radius-normal;
    overflow: hidden;
    height: $card-height;
    background-color: $color-white;

    &__mode {
        &--default {
            margin-bottom: $spacing-page-gutter;
        }
    }

    &__image-section {
        aspect-ratio: 1 /1;
        position: relative;
        max-width: 50%;
    }

    &__image {
        border-top-left-radius: $border-radius-normal;
        border-bottom-left-radius: $border-radius-normal;
        object-fit: cover;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    &__fallback-image {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        height: 100%;

        svg {
            width: 75%;
            height: 100%;
        }
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: $spacing-component-small;
        justify-content: space-between;
        overflow: hidden;
    }

    &__title {
        color: $color-grey-9;
        @include overflow-ellipsis-multiline(2);
    }

    &__subtitle {
        color: $color-grey-7;
        @include overflow-ellipsis-multiline(3);
    }

    &__buttons {
        display: flex;
        gap: $spacing-component-xs;
        flex-wrap: wrap;

        button.button-element {           
            border-color: $color-grey-2;
        }
    }
}
