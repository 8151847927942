@import "src/sharedComponents/common/mixins";
@import "src/sharedComponents/common/variables";

.history-item-modal {
    height: 100%;
    @include hide-scroll-bar;
}

.ReactModalPortal {
    .ReactModal__Overlay {
        &.history-item--overlay {
            z-index: 100;
            transform: translateZ(100px);
        }
    }
}

.history-item__section {
    &.refund-state {
        display: flex;
        align-items: center;
        color: $color-grey-8;
        padding: $spacing-component-small;

        svg {
            height: 18px;
            width: 18px;
            flex-shrink: 0;
            margin-right: $spacing-component-small;
        }

        &.refunded {
            svg {
                circle {
                    fill: $color-success;
                }
            }
        }

        &.failed {
            @include svg-color($color-grey-8);
        }
        &.pending {
            @include svg-color($color-grey-6);
        }
    }

    .order-items {
        padding: 0;
        margin: $spacing-component 0;
    }
}

.history-item__order-status__progress-container {
    svg {
        circle {
            fill: $color-primary;
        }
    }
}
