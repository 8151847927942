$colorLight: #fff;
$colorDark: #000;
$colorGrey: #7c7c7c;
$colorRed: #dd1e2c;
$colorBorder: #ededed;
$colorLightGray: #f6f6f6;

.filtermodal__option {
    font-family: "Manrope", sans-serif;
    font-size: 21px;
    padding: 17px;
    color: $colorDark;
}

.droplist-option-active {
    background-color: #cecece;
}

.Select.filtermodal--dropdownlist--select {
    border: 6px solid $colorBorder;

    .Select-control {
        height: 42px;
        padding: 0;
    }

    &.is-open .Select-control {
        border-color: #ededed;
    }

    &.is-focused:not(.is-open) > .Select-control {
        border-color: #ededed;
        box-shadow: none;
        padding: 10px 0;
    }

    .Select-input {
        height: auto;
    }

    .Select-input input,
    .Select-placeholder {
        font-family: "Manrope", sans-serif;
        font-size: 21px;
        padding: 17px;
        color: red;
    }

    .Select-input input {
        padding-left: 7px;
    }

    .Select-value {
        height: 42px;
        margin-top: 4px;
        padding: 0 13px;
        font-size: 16px;
    }

    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
        font-family: "Manrope", sans-serif;
        font-size: 16px;
        padding: 15px;
        color: $colorDark;
    }

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
        margin-top: 15px;
        font-size: 16px;
    }

    .Select .Select-arrow-zone {
        padding-right: 20px;
    }
}
